import React, { useState } from 'react';
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Text,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Divider,
} from '@chakra-ui/react';
import { format, endOfMonth, endOfYear, min, endOfDay } from 'date-fns';
import DatePicker from 'react-datepicker';

import { ChevronDownIcon } from '@chakra-ui/icons';

interface DateRangeMenuProps {
  setFilter: (dates: [Date, Date]) => void;
  dashboardDates: {
    startDate: Date;
    endDate: Date;
  };
  showYear?: boolean;
}

function DateRangeMenu({
  setFilter,
  dashboardDates,
  showYear = false,
}: DateRangeMenuProps): JSX.Element {
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();

  const onChangeDays = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end ? min([new Date(), endOfDay(end)]) : end);
  };

  const onChangeMonths = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end ? min([new Date(), endOfMonth(end)]) : end);
  };

  const onChangeYears = (dates: [Date | null, Date | null]) => {
    const [start] = dates;
    setStartDate(start);
    if (start) {
      setEndDate(min([new Date(), endOfYear(start)]));
    }
  };

  const updateDates = () => {
    if (startDate && endDate) {
      setFilter([startDate, endDate]);
    }
  };

  return (
    <Menu placement="bottom-end" matchWidth>
      {({ onClose }) => (
        <>
          <MenuButton minWidth="max-content" paddingX={3}>
            <Flex justifyContent="space-between" alignItems="center">
              <>
                <Flex>
                  <Text color="brand.gray1" paddingX={2}>
                    {`${format(dashboardDates.startDate, 'MMM d')} - ${format(
                      dashboardDates.endDate,
                      'MMM d, yyyy'
                    )}`}
                  </Text>
                </Flex>
                <ChevronDownIcon color="brand.gray1" />
              </>
            </Flex>
          </MenuButton>

          <MenuList minWidth="max-content">
            <Tabs isLazy variant="secondarySelect" height="100%" width="100%">
              <TabList
                flexWrap="wrap"
                justifyContent="space-between"
                paddingX={9}
              >
                <Tab> Day</Tab>
                <Tab>Month</Tab>
                {showYear && <Tab>Year</Tab>}
              </TabList>
              <Divider />
              <TabPanels>
                <TabPanel>
                  <DatePicker
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                    minDate={new Date('01/01/2022')}
                    maxDate={new Date()}
                    onChange={onChangeDays}
                  />
                </TabPanel>
                <TabPanel>
                  <DatePicker
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                    minDate={new Date('01/01/2022')}
                    maxDate={new Date()}
                    onChange={onChangeMonths}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    focusSelectedMonth
                  />
                </TabPanel>
                <TabPanel>
                  <DatePicker
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                    minDate={new Date('01/01/2022')}
                    maxDate={new Date()}
                    onChange={onChangeYears}
                    selected={startDate}
                    dateFormat="yyyy"
                    showYearPicker
                    yearItemNumber={1}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
            <Flex direction="row" paddingX={5} paddingBottom={2} marginX="px">
              <Button
                colorScheme="blue"
                backgroundColor="brand.blue2"
                paddingX={6}
                size="sm"
                isDisabled={!startDate || !endDate}
                onClick={() => {
                  updateDates();
                  onClose();
                }}
              >
                Done
              </Button>
              <Button
                variant="ghost"
                marginLeft={4}
                paddingX={3}
                onClick={onClose}
                color="brand.blue2"
                size="sm"
              >
                Cancel
              </Button>
            </Flex>
          </MenuList>
        </>
      )}
    </Menu>
  );
}

DateRangeMenu.defaultProps = {
  showYear: false,
};

export default DateRangeMenu;
