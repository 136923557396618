export default {
	firebase: {
		apiKey: "AIzaSyDKrr2u64EjXf2M-yB2-xsTUOD15G8EsJE",
		authDomain: "uptrust-reminders---stage.firebaseapp.com",
		databaseURL: "https://uptrust-reminders---stage-default-rtdb.firebaseio.com",
		projectId: "uptrust-reminders---stage",
		storageBucket: "uptrust-reminders---stage.appspot.com",
		messagingSenderId: "176538360081",
		appId: "1:176538360081:web:ae4f93e2342551b2c494b0",
		measurementId: "G-WBGKSLCS2X"
	},
	api: {
		url: 'https://api-stage.uptrust.co',
		wss: 'wss://api-stage.uptrust.co/graphql',
	},
	launchDarkly: {
		clientSideId: '60bff45259fdb80caa470581',
	},
	datadogRUM: {
		applicationId: '6418ed95-72a0-4858-8e0a-16f99f052947',
		clientToken: 'pubd13d3055d1fd52f71e25cf93d9e3cb63',
		site: 'datadoghq.com',
		service: 'neo-staff-web',
		env: 'tage',
		sampleRate: 100,
		version: import.meta.env.VITE_PUBLIC_VERSION,
		allowedTracingOrigins: ['https://api-stage.uptrust.co/'],
	},
};
