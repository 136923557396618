import React, { useEffect, useState } from 'react';

import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  Spinner,
  Text,
  Tooltip,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';
import transformConnection from 'sharedHelpers/transformConnection';
import { sentenceCase, titleCase } from 'sharedHelpers/textFormat';
import getLocalizedText from 'sharedHelpers/getLocalizedText';

import PhoneInput from 'react-phone-number-input/react-hook-form-input';

import FormControlInput from 'sharedComponents/FormControlInput';
import StaffMultiSelector from 'sharedComponents/StaffMultiSelector';
import {
  useGetOrganizationStaffQuery,
  useGetClientByCmsIdLazyQuery,
} from 'generated/graphql';
import useSaveClient from 'sharedHooks/useSaveClient';
import useShouldUpdateClientStaff from 'sharedHooks/useShouldUpdateClientStaff';
import useCohorts from 'sharedHooks/useCohorts';
import useDebounce from 'sharedHooks/useDebounce';
import Error from 'sharedIcons/Error';
import useIdentifyUser from 'sharedHooks/useIdentifyUser';
import Gender from '../constants/Genders';
import States from '../constants/States';
import Languages from '../constants/Languages';
import RiskLevels from '../constants/RiskLevels';
import theme from '../theme';
import type { AssignableStaff } from '../types/clientTypes';
import type { ClientFormFields } from '../types/formTypes';
import { allClientsListVar } from '../graphql/apolloCache';

export interface EditClientFormProps {
  formData: ClientFormFields;
  formId: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  OnSubmitData?: (clientData: ClientFormFields) => void;
  onUpdateClient?: (clientData: any, newPrimaryStaffId: string) => void;
}

// TODO move to theme, apply same styles to all forms
const styles = {
  box: {
    marginBottom: 4,
  },
  formControl: {
    marginBottom: 4,
    marginRight: 5,
    paddingRight: 2,
  },
  input: {
    fieldSize: 'md',
  },
  formLabel: {
    fontSize: 'smaller',
    fontWeight: 'normal',
  },
  checkbox: {
    paddingRight: 10,
    paddingBottom: 5,
  },
};

function EditClientsForm(props: EditClientFormProps): JSX.Element {
  const {
    formData,
    formId,
    setLoading,
    onClose,
    onUpdateClient,
    OnSubmitData,
  } = props;
  const {
    staffMemberLabelFlag,
    showRiskLevelFlag,
    showCohortFlag,
    showCmsIdDuringClientCreate,
    enableMspcFlag: enableMSPCFlag,
    showCmsIdFlag: showCMSIdFlag,
  } = useFlags();

  const {
    clientUpdatedPhone,
    clientUpdatedAddress,
    clientUpdatedEmployer,
  } = formData;
  const clientUpdateTooltipNotice =
    'This field is no longer editable because it was updated by the client';

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<ClientFormFields>({
    defaultValues: formData,
  });

  const userInfo = useIdentifyUser();
  const prefixCmsId = `${userInfo.organization.uid}-`;
  const validationForCmsIdField = formData.id
    ? showCMSIdFlag
    : showCmsIdDuringClientCreate && showCMSIdFlag;

  const { fields, append } = useFieldArray({
    control,
    name: 'contacts',
    keyName: 'id',
  });

  const { data: staff, loading: staffLoading } = useGetOrganizationStaffQuery();

  const [maxStaffPerClientValue, setMaxStaffPerClientValue] = useState<number>(
    4
  );

  const { cohortLabel, cohorts } = useCohorts(showCohortFlag);

  const cohortsList = cohorts.length
    ? cohorts.map((cohort) => ({
        id: cohort.id,
        name: getLocalizedText(cohort.name),
      }))
    : [];

  useEffect(() => {
    if (cohortsList.length) {
      setValue('cohortId', formData.cohortId);
    }
  }, [cohortsList]);

  useEffect(() => {
    if (staff && !staffLoading) {
      const maxStaffPerClient =
        'organization' in staff.getUser
          ? staff.getUser.organization.maxStaffPerClient
          : 4;
      setMaxStaffPerClientValue(maxStaffPerClient);
    }
  }, [staff]);

  const staffList =
    staff && 'organization' in staff.getUser
      ? transformConnection(staff.getUser.organization.staff)
          .filter(
            (staffMember): staffMember is AssignableStaff =>
              !!staffMember && !!staffMember.lawyer
          )
          .sort((a: AssignableStaff, b: AssignableStaff) =>
            a.name > b.name ? 1 : -1
          )
      : [];

  const [save, saveState] = useSaveClient();

  const watchPhone = watch('noPhone');
  const nameValidator = { maxLength: 80 };
  const phoneNumberValidators = {
    minLength: {
      value: 10,
      message: 'Please enter a valid phone number',
    },
    maxLength: {
      value: 12,
      message: 'Please enter a valid phone number',
    },
  };
  const addressLine1Validator = {
    maxLength: {
      value: 120,
      message: 'Street address can be no more than 120 characters',
    },
  };
  const addressLine2Validator = {
    maxLength: {
      value: 120,
      message: 'Apartment or unit number must be no more than 120 characters',
    },
  };
  const cityValidator = {
    maxLength: {
      value: 50,
      message: 'City must be no more than 50 characters',
    },
  };
  const zipCodeValidator = {
    minLength: {
      value: 5,
      message: 'Zip code must be 5 digits',
    },
    maxLength: {
      value: 5,
      message: 'Zip code must be 5 digits',
    },
  };
  useEffect(() => {
    if (saveState?.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    if (saveState?.clientError) {
      // show an error saying the client didn't save
      console.log('client error');
    }
    if (saveState?.contactError) {
      // show an error saying the client saved but the contacts didn't
      console.log('contact error');
    }
    if (saveState?.complete && !saveState?.clientError) {
      onClose();
    }
  }, [saveState]);

  const shouldUpdateClientStaff = useShouldUpdateClientStaff({
    clientId: formData.id ?? '',
    staffIds: formData.staffIds ?? [],
  });

  const addPrefixIntoCmsId = (
    cmsId: string | undefined
  ): string | undefined => {
    let newCmsID = cmsId;
    if (validationForCmsIdField && cmsId) {
      if (cmsId?.includes(prefixCmsId)) {
        newCmsID = cmsId?.replace(prefixCmsId, '');
      }
      newCmsID = prefixCmsId + newCmsID;
    }
    return newCmsID;
  };

  const clientIdMessage = (
    isValid: boolean,
    currentCmsId: string | null | undefined
  ): string => {
    if (!isValid) {
      return currentCmsId
        ? 'Client ID already exists'
        : 'Client ID is required';
    }
    return 'Valid Client ID';
  };

  const [isCmsIdValid, setIsCmsIdValid] = useState(true);
  const cmsId = watch('cmsId');
  const debouncedCmsId = useDebounce(cmsId);

  const [
    getClientByCmsId,
    { data: cmsIdData, loading: cmsIdLoading, error: cmsIdError },
  ] = useGetClientByCmsIdLazyQuery();

  const clients = useReactiveVar(allClientsListVar);

  useEffect(() => {
    const cmsIdWithPrefix = addPrefixIntoCmsId(debouncedCmsId || undefined);
    const foundClient = clients.find(
      (client) => client.id !== formData.id && client.cmsId === cmsIdWithPrefix
    );

    if (foundClient || (!formData.id && !debouncedCmsId)) {
      setIsCmsIdValid(false);
      return;
    }

    if (cmsIdWithPrefix && cmsIdWithPrefix !== formData.cmsId) {
      getClientByCmsId({ variables: { cmsId: cmsIdWithPrefix } });
      setIsCmsIdValid(!cmsIdData?.getClientByCmsId);
      return;
    }
    setIsCmsIdValid(true);
  }, [cmsIdData, debouncedCmsId]);

  const onSubmit = async (formValues: ClientFormFields) => {
    const clientData =
      formData.phone !== formValues.phone && formData.id
        ? { ...formValues, needsPing: true }
        : formValues;

    clientData.cmsId = addPrefixIntoCmsId(clientData.cmsId || undefined);

    if (!enableMSPCFlag && formData.staff?.id !== formValues.staff?.id) {
      clientData.staffIds = formValues.staff?.id ? [formValues.staff.id] : [];
    }

    if (enableMSPCFlag && formValues.staffIds?.length === 0) {
      throw new TypeError('At least one staffId is required');
      return;
    }
    if (enableMSPCFlag && formData.id) {
      const shouldDisplayUpdateConfirmation = shouldUpdateClientStaff(
        formValues.staffIds
      );
      if (shouldDisplayUpdateConfirmation) {
        // formValues.staffIds[0] should never be undefined because the form
        // includes a validation check for staffIds before running this code
        // AND we check for an empty array and throw an error above.
        // setting this default empty string is for typescript evaluation
        const newPrimaryStaffId = formValues.staffIds
          ? formValues.staffIds[0]
          : '';
        if (typeof onUpdateClient === 'function') {
          onUpdateClient(clientData, newPrimaryStaffId);
          return;
        }
      }
    }
    if (OnSubmitData) OnSubmitData(clientData);
    save(clientData);
  };

  useEffect(() => {
    if (formData.id && formData.cmsId && validationForCmsIdField)
      setValue('cmsId', formData.cmsId?.replace(prefixCmsId, ''));
  }, [prefixCmsId]);

  return (
    <form
      data-testid="editClientForm"
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Flex flexDirection="column">
        <Box
          backgroundColor="brand.gray8"
          paddingX={8}
          paddingY={4}
          borderRadius="8px"
          marginBottom={styles.box.marginBottom}
        >
          <Text color={theme.colors.brand.gray2} marginBottom={2}>
            CLIENT INFORMATION
          </Text>
          <Flex>
            <FormControl
              id="add-client-first-name"
              paddingRight={styles.formControl.paddingRight}
              marginBottom={styles.formControl.marginBottom}
              isRequired
            >
              <FormLabel
                fontSize={styles.formLabel.fontSize}
                fontWeight={styles.formLabel.fontWeight}
              >
                First Name
              </FormLabel>
              <Input
                {...register('firstName', nameValidator)} // eslint-disable-line react/jsx-props-no-spreading
                size={styles.input.fieldSize}
                backgroundColor="white"
                placeholder="First Name"
              />
            </FormControl>
            <FormControl
              id="add-client-last-name"
              isRequired
              marginBottom={styles.formControl.marginBottom}
            >
              <FormLabel
                fontSize={styles.formLabel.fontSize}
                fontWeight={styles.formLabel.fontWeight}
              >
                Last Name
              </FormLabel>
              <Input
                {...register('lastName', nameValidator)} // eslint-disable-line react/jsx-props-no-spreading
                size={styles.input.fieldSize}
                backgroundColor="white"
                placeholder="Last Name"
              />
            </FormControl>
          </Flex>
          <Flex>
            <Tooltip
              hasArrow
              isDisabled={!clientUpdatedPhone}
              aria-label={clientUpdateTooltipNotice}
              placement="bottom"
              label={clientUpdateTooltipNotice}
              background="brand.gray1"
              arrowShadowColor="brand.gray1"
              arrowSize={10}
              fontSize="xs"
            >
              <FormControl
                id="add-client-phone-number"
                isInvalid={!!errors.phone}
                isDisabled={clientUpdatedPhone}
                paddingRight={styles.formControl.paddingRight}
                marginBottom={styles.formControl.marginBottom}
                isRequired={!watchPhone}
              >
                <FormLabel
                  fontSize={styles.formLabel.fontSize}
                  fontWeight={styles.formLabel.fontWeight}
                >
                  Phone Number
                </FormLabel>
                <PhoneInput
                  control={control}
                  inputComponent={Input}
                  country="US"
                  size={styles.input.fieldSize}
                  rules={{ required: !watchPhone, ...phoneNumberValidators }}
                  backgroundColor="white"
                  placeholder="888-555-9999"
                  name="phone"
                />
                <FormErrorMessage color="brand.orange1">
                  <Error /> {errors.phone?.message}
                </FormErrorMessage>
              </FormControl>
            </Tooltip>
            <FormControl
              id="add-client-language"
              marginBottom={styles.formControl.marginBottom}
            >
              <FormLabel
                fontSize={styles.formLabel.fontSize}
                fontWeight={styles.formLabel.fontWeight}
              >
                Language
              </FormLabel>
              <Select
                {...register('language')} // eslint-disable-line react/jsx-props-no-spreading
                placeholder="Select a language"
                backgroundColor="white"
                isDisabled={false}
              >
                {Object.entries(Languages).map(([value, languageData]) => (
                  <option key={value} value={value}>
                    {languageData.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Flex>
          <Tooltip
            hasArrow
            isDisabled={!clientUpdatedAddress}
            aria-label={clientUpdateTooltipNotice}
            placement="bottom"
            label={clientUpdateTooltipNotice}
            background="brand.gray1"
            arrowShadowColor="brand.gray1"
            arrowSize={10}
            fontSize="xs"
          >
            <Box>
              <FormControl
                id="add-client-addressLine1"
                marginBottom={styles.formControl.marginBottom}
                isDisabled={clientUpdatedAddress}
                isInvalid={!!errors.address?.addressLine1}
              >
                <FormLabel
                  fontSize={styles.formLabel.fontSize}
                  fontWeight={styles.formLabel.fontWeight}
                >
                  Street Address
                </FormLabel>
                <Input
                  {...register('address.addressLine1', addressLine1Validator)} // eslint-disable-line react/jsx-props-no-spreading
                  type="string"
                  size={styles.input.fieldSize}
                  backgroundColor="brand.white"
                  placeholder="1234 Main Street"
                />
              </FormControl>
              <FormControl
                id="add-client-addressLine2"
                marginBottom={styles.formControl.marginBottom}
                isDisabled={clientUpdatedAddress}
                isInvalid={!!errors.address?.addressLine2}
              >
                <FormLabel
                  fontSize={styles.formLabel.fontSize}
                  fontWeight={styles.formLabel.fontWeight}
                >
                  Apartment or Unit Number
                </FormLabel>
                <Input
                  {...register('address.addressLine2', addressLine2Validator)} // eslint-disable-line react/jsx-props-no-spreading
                  type="string"
                  size={styles.input.fieldSize}
                  backgroundColor="brand.white"
                  placeholder="Apt #1A"
                />
              </FormControl>
              <Flex>
                <FormControl
                  id="add-client-city"
                  marginBottom={styles.formControl.marginBottom}
                  paddingRight={styles.formControl.paddingRight}
                  isDisabled={clientUpdatedAddress}
                  isInvalid={!!errors.address?.city}
                >
                  <FormLabel
                    fontSize={styles.formLabel.fontSize}
                    fontWeight={styles.formLabel.fontWeight}
                  >
                    City
                  </FormLabel>
                  <Input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register('address.city', cityValidator)}
                    type="string"
                    size={styles.input.fieldSize}
                    backgroundColor="brand.white"
                    placeholder="AnyTown"
                  />
                  <FormErrorMessage color="brand.orange1">
                    <Error />
                    {errors.address?.city?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  id="add-client-state"
                  marginBottom={styles.formControl.marginBottom}
                  paddingRight={styles.formControl.paddingRight}
                  isDisabled={clientUpdatedAddress}
                  isInvalid={!!errors.address?.state}
                >
                  <FormLabel
                    fontSize={styles.formLabel.fontSize}
                    fontWeight={styles.formLabel.fontWeight}
                  >
                    State
                  </FormLabel>
                  <Select
                    {...register('address.state')} // eslint-disable-line react/jsx-props-no-spreading
                    backgroundColor="brand.white"
                    placeholder="Select a state"
                  >
                    {Object.entries(States).map(([value, name]) => (
                      <option key={value} value={name}>
                        {value}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>
            </Box>
          </Tooltip>
          <Flex>
            <Tooltip
              hasArrow
              isDisabled={!clientUpdatedAddress}
              aria-label={clientUpdateTooltipNotice}
              placement="bottom"
              label={clientUpdateTooltipNotice}
              background="brand.gray1"
              arrowShadowColor="brand.gray1"
              arrowSize={10}
              fontSize="xs"
            >
              <FormControl
                id="add-client-zipcode"
                isInvalid={!!errors.address?.zipCode}
                marginBottom={styles.formControl.marginBottom}
                paddingRight={styles.formControl.paddingRight}
                isDisabled={clientUpdatedAddress}
              >
                <FormLabel
                  fontSize={styles.formLabel.fontSize}
                  fontWeight={styles.formLabel.fontWeight}
                >
                  Zip Code
                </FormLabel>
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('address.zipCode', zipCodeValidator)}
                  type="number"
                  size={styles.input.fieldSize}
                  backgroundColor="brand.white"
                  placeholder="99999"
                />
                <FormErrorMessage color="brand.orange1">
                  <Error />
                  {errors.address?.zipCode?.message}
                </FormErrorMessage>
              </FormControl>
            </Tooltip>
            <FormControl
              id="add-client-gender"
              marginBottom={styles.formControl.marginBottom}
              paddingRight={styles.formControl.paddingRight}
            >
              <FormLabel
                fontSize={styles.formLabel.fontSize}
                fontWeight={styles.formLabel.fontWeight}
              >
                Gender
              </FormLabel>
              <Select
                {...register('gender')} // eslint-disable-line react/jsx-props-no-spreading
                backgroundColor="brand.white"
                placeholder="Select a gender"
                isDisabled={false}
              >
                {Object.entries(Gender).map(([value, name]) => (
                  <option key={value} value={value}>
                    {name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Flex>
          <FormControl
            id="add-client-email"
            marginBottom={styles.formControl.marginBottom}
          >
            <FormLabel
              fontSize={styles.formLabel.fontSize}
              fontWeight={styles.formLabel.fontWeight}
            >
              Email
            </FormLabel>
            <Input
              {...register('email')} // eslint-disable-line react/jsx-props-no-spreading
              type="email"
              backgroundColor="white"
              placeholder="email.address@example.com"
            />
          </FormControl>
          <Tooltip
            hasArrow
            isDisabled={!clientUpdatedEmployer}
            aria-label={clientUpdateTooltipNotice}
            placement="bottom"
            label={clientUpdateTooltipNotice}
            background="brand.gray1"
            arrowShadowColor="brand.gray1"
            arrowSize={10}
            fontSize="xs"
            role="tooltip"
          >
            <FormControl
              id="add-client-employer"
              isDisabled={clientUpdatedEmployer}
              paddingRight={styles.formControl.paddingRight}
              marginBottom={styles.formControl.marginBottom}
            >
              <FormLabel
                fontSize={styles.formLabel.fontSize}
                fontWeight={styles.formLabel.fontWeight}
              >
                Employers(s)
              </FormLabel>
              <Input
                {...register('employer')} // eslint-disable-line react/jsx-props-no-spreading
                type="text"
                backgroundColor="brand.white"
                placeholder="Employer name"
              />
            </FormControl>
          </Tooltip>
          <Text
            fontSize={styles.formLabel.fontSize}
            fontWeight={styles.formLabel.fontWeight}
            marginBottom={2}
          >
            Circumstances
          </Text>
          <Flex>
            <Checkbox
              {...register('noCar')} // eslint-disable-line react/jsx-props-no-spreading
              id="add-client-car"
              paddingRight={styles.checkbox.paddingRight}
              paddingBottom={styles.checkbox.paddingBottom}
            >
              No vehicle
            </Checkbox>
            <Checkbox
              {...register('housingInsecure')} // eslint-disable-line react/jsx-props-no-spreading
              id="add-client-housing"
              paddingRight={styles.checkbox.paddingRight}
              paddingBottom={styles.checkbox.paddingBottom}
            >
              Housing insecure
            </Checkbox>
            <Checkbox
              {...register('needsChildCare')} // eslint-disable-line react/jsx-props-no-spreading
              id="add-client-children"
              paddingRight={styles.checkbox.paddingRight}
              paddingBottom={styles.checkbox.paddingBottom}
            >
              Needs childcare
            </Checkbox>
          </Flex>
          <Flex>
            <Tooltip
              hasArrow
              isDisabled={!clientUpdatedPhone}
              aria-label={clientUpdateTooltipNotice}
              label={clientUpdateTooltipNotice}
              background="brand.gray1"
              arrowShadowColor="brand.gray1"
              arrowSize={10}
              fontSize="xs"
            >
              <Box>
                <Checkbox
                  isDisabled={clientUpdatedPhone}
                  {...register('noPhone')} // eslint-disable-line react/jsx-props-no-spreading
                  onChange={() => {
                    setValue('noPhone', !watchPhone);
                  }}
                  id="add-client-phone"
                  paddingRight={styles.checkbox.paddingRight}
                  paddingBottom={styles.checkbox.paddingBottom}
                >
                  No phone
                </Checkbox>
              </Box>
            </Tooltip>
            <Checkbox
              {...register('inCustody')} // eslint-disable-line react/jsx-props-no-spreading
              id="add-client-incarcerated"
              paddingRight={styles.checkbox.paddingRight}
              paddingBottom={styles.checkbox.paddingBottom}
              paddingLeft={1.5}
            >
              Incarcerated
            </Checkbox>
          </Flex>
        </Box>
        <Box
          backgroundColor="brand.gray8"
          paddingX={8}
          paddingY={4}
          borderRadius="8px"
          marginBottom={styles.box.marginBottom}
        >
          <Text color={theme.colors.brand.gray2} marginBottom={2}>
            CASE INFORMATION
          </Text>
          {validationForCmsIdField && (
            <Flex>
              <FormControl
                id="add-client-id"
                data-testid="clientCmsIdField"
                paddingRight={styles.formControl.paddingRight}
                marginBottom={styles.formControl.marginBottom}
                isRequired={
                  (!formData.id && validationForCmsIdField) || undefined
                }
              >
                <FormLabel
                  fontSize={styles.formLabel.fontSize}
                  fontWeight={styles.formLabel.fontWeight}
                >
                  Client ID
                </FormLabel>
                <InputGroup>
                  <InputLeftAddon>{prefixCmsId}</InputLeftAddon>
                  <Input
                    {...register('cmsId')} // eslint-disable-line react/jsx-props-no-spreading
                    type="string"
                    size={styles.input.fieldSize}
                    backgroundColor="white"
                    placeholder="2345678"
                    required={
                      (!formData.id && validationForCmsIdField) || undefined
                    }
                  />
                </InputGroup>
                {cmsIdLoading ? (
                  <Spinner mt="3" color="brand.gray2" />
                ) : (
                  <Alert status={isCmsIdValid ? 'success' : 'error'}>
                    <AlertIcon />
                    {clientIdMessage(isCmsIdValid, debouncedCmsId)}
                  </Alert>
                )}
              </FormControl>
            </Flex>
          )}
          {enableMSPCFlag ? (
            <FormControlInput
              inputId="select-staff"
              fieldLabel="Assigned Staff"
              errors={errors.staffIds}
            >
              <Controller
                control={control}
                name="staffIds"
                rules={{
                  validate: {
                    atLeastOne: (v) =>
                      (Array.isArray(v) && !!v.length) ||
                      'At least one staff assignment is required',
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <StaffMultiSelector
                    maxItems={maxStaffPerClientValue}
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                    }}
                    staffMemberList={staffList.map((staffMember) => ({
                      id: staffMember.id,
                      label: staffMember.name,
                    }))}
                  />
                )}
              />
            </FormControlInput>
          ) : (
            <FormControl
              id="add-client-supervisor"
              marginBottom={styles.formControl.marginBottom}
              isRequired
            >
              <FormLabel
                fontSize={styles.formLabel.fontSize}
                fontWeight={styles.formLabel.fontWeight}
              >
                {sentenceCase(staffMemberLabelFlag)}
              </FormLabel>
              <Select
                {...register('staff.id')} // eslint-disable-line react/jsx-props-no-spreading
                placeholder={
                  staffLoading ? 'Staff list loading...' : 'Select staff'
                }
                backgroundColor="white"
                isDisabled={staffLoading}
              >
                {staffList.map((member) => (
                  <option key={member.id} value={member.id}>
                    {member.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}

          {showRiskLevelFlag && (
            <FormControl
              id="add-client-risk-level"
              marginBottom={styles.formControl.marginBottom}
              isRequired
            >
              <FormLabel
                fontSize={styles.formLabel.fontSize}
                fontWeight={styles.formLabel.fontWeight}
              >
                Risk Level
              </FormLabel>
              <Select
                {...register('riskLevel')} // eslint-disable-line react/jsx-props-no-spreading
                backgroundColor="white"
                isRequired
                defaultValue="NOT_ASSESSED"
                placeholder="Select a risk level"
              >
                <option value="NOT_ASSESSED">{RiskLevels.NOT_ASSESSED}</option>
                <option value="LOWEST">{RiskLevels.LOWEST}</option>
                <option value="LOW">{RiskLevels.LOW}</option>
                <option value="MEDIUM">{RiskLevels.MEDIUM}</option>
                <option value="HIGH">{RiskLevels.HIGH}</option>
                <option value="HIGHEST">{RiskLevels.HIGHEST}</option>
              </Select>
            </FormControl>
          )}
          {showCohortFlag && cohortsList.length > 0 && (
            <FormControl
              id="add-client-cohortid"
              marginBottom={styles.formControl.marginBottom}
            >
              <FormLabel
                fontSize={styles.formLabel.fontSize}
                fontWeight={styles.formLabel.fontWeight}
              >
                {titleCase(cohortLabel ?? 'Program Group')}
              </FormLabel>
              <Select
                {...register('cohortId')} // eslint-disable-line react/jsx-props-no-spreading
                backgroundColor="white"
                isDisabled={!cohortsList.length}
                placeholder={
                  !cohortsList.length
                    ? 'Group list loading...'
                    : `Select ${(cohortLabel ?? '').toLowerCase()}`
                }
              >
                {cohortsList.map((cohort) => (
                  <option key={cohort.id} value={cohort.id}>
                    {cohort.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
      </Flex>
      <Box
        backgroundColor="brand.gray8"
        paddingX={8}
        paddingY={4}
        borderRadius="8px"
        marginBottom={styles.box.marginBottom}
      >
        <Text color={theme.colors.brand.gray2} marginBottom={2}>
          CONTACTS
        </Text>
        {fields.length === 0 && (
          <Button
            type="button"
            colorScheme="blue"
            variant="link"
            fontWeight="medium"
            paddingRight={5}
            onClick={() => {
              append([{}]);
            }}
          >
            Add contact
          </Button>
        )}
        {fields.map((field, index) => (
          <div key={field.id}>
            <Flex>
              <input
                style={{ display: 'none' }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register(`contacts.${index}.id` as const)}
              />
              <FormControl
                id={`add-client-contact-name-${index}`}
                isInvalid={!!errors?.contacts?.[index]?.name}
                paddingRight={styles.formControl.paddingRight}
                marginBottom={styles.formControl.marginBottom}
              >
                <FormLabel
                  fontSize={styles.formLabel.fontSize}
                  fontWeight={styles.formLabel.fontWeight}
                >
                  First & Last Name
                </FormLabel>
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register(
                    `contacts.${index}.name` as const,
                    nameValidator
                  )}
                  defaultValue={field.name}
                  size={styles.input.fieldSize}
                  backgroundColor="white"
                  placeholder="Contact name"
                />
                <FormErrorMessage color="brand.orange1">
                  <Error />
                  {errors?.contacts?.[index]?.name?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id={`add-client-contact-phone-${index}`}
                isInvalid={!!errors?.contacts?.[index]?.phone}
                paddingRight={styles.formControl.paddingRight}
                marginBottom={styles.formControl.marginBottom}
              >
                <FormLabel
                  fontSize={styles.formLabel.fontSize}
                  fontWeight={styles.formLabel.fontWeight}
                >
                  Phone Number
                </FormLabel>
                <PhoneInput
                  control={control}
                  inputComponent={Input}
                  country="US"
                  size={styles.input.fieldSize}
                  rules={phoneNumberValidators}
                  backgroundColor="white"
                  placeholder="888-555-9999"
                  name={`contacts.${index}.phone`}
                  defaultValue={field.phone}
                />
                <FormErrorMessage color="brand.orange1">
                  <Error />
                  {errors?.contacts?.[index]?.phone?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id={`add-client-contact-optin-${index}`}
                marginBottom={styles.formControl.marginBottom}
                marginRight={styles.formControl.marginRight}
              >
                <FormLabel
                  fontSize={styles.formLabel.fontSize}
                  fontWeight={styles.formLabel.fontWeight}
                >
                  Send Reminders
                </FormLabel>
                <Select
                  {...register(`contacts.${index}.optIn` as const)} // eslint-disable-line react/jsx-props-no-spreading
                  backgroundColor="white"
                  defaultValue={field.optIn}
                >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </Select>
              </FormControl>
            </Flex>
            <FormControl
              id={`add-client-contact-notes-${index}`}
              paddingRight={styles.formControl.paddingRight}
              marginBottom={styles.formControl.marginBottom}
            >
              <FormLabel
                fontSize={styles.formLabel.fontSize}
                fontWeight={styles.formLabel.fontWeight}
              >
                Notes
              </FormLabel>
              <Input
                {...register(`contacts.${index}.notes` as const)} // eslint-disable-line react/jsx-props-no-spreading
                defaultValue={field.notes}
                size={styles.input.fieldSize}
                backgroundColor="white"
                placeholder="Contact notes"
              />
            </FormControl>
            {index === fields.length - 1 && (
              <Button
                type="button"
                colorScheme="blue"
                variant="link"
                fontWeight="medium"
                paddingRight={5}
                onClick={() => {
                  append([{}]);
                }}
              >
                Add another
              </Button>
            )}
            {fields.length > 1 && <Container height={6} />}
          </div>
        ))}
      </Box>
    </form>
  );
}

export default EditClientsForm;
