import React, { useRef, useState } from 'react';
import { Flex, Heading, Divider } from '@chakra-ui/react';
import { subDays, startOfHour } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import DashboardGrid from 'sharedComponents/DashboardGrid';
import {
  SquareWidget,
  RectangleWidget,
  FullSizeWidget,
} from 'sharedComponents/dashboardWidgets/';
import AttendanceTool from 'sharedComponents/AttendanceTool';
import DateRangeMenu from 'sharedComponents/DateRangeMenu';
import useIdentifyUser from 'sharedHooks/useIdentifyUser';

import useClientsDashboardStats from '../hooks/useClientsDashboardStats';
import DashboardStaffSelectMenu from './DashboardStaffSelectMenu';
import AttendanceDonutWidget from './AttendanceDonut';
import AttendanceTidbits from './AttendanceTidbits';

import EngagementTripleStack from './EngagementTripleStack';
import EngagementTidbits from './EngagementTidbits';

const roundedDate = startOfHour(Date.now());

function Dashboard(): JSX.Element {
  const [selectedStaffId, setSelectedStaffId] = useState<string>('');
  const [startDate, setStartDate] = useState(subDays(roundedDate, 30));
  const [endDate, setEndDate] = useState(roundedDate);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { hideClientsDashboardStaffFilterFlag } = useFlags();
  const { role } = useIdentifyUser();
  const { allStats, loading } = useClientsDashboardStats({
    startDate,
    endDate,
    selectedStaffId,
  });

  const currentScrollPosition = useRef(0);

  const refetchDashboard = () => {
    setStartDate((previousState) => new Date(previousState.getTime() + 1));
    setScrollPosition(currentScrollPosition.current);
  };

  const scrollRef = useRef<HTMLDivElement>(null);

  const restorePosition = () => {
    scrollRef.current?.scrollTo({ top: scrollPosition });
  };

  const handleScroll = (event: any) => {
    currentScrollPosition.current = event.currentTarget?.scrollTop;
  };

  return (
    <Flex
      direction="column"
      width="100%"
      paddingY={4}
      paddingX={{ base: 0, lg: 6 }}
    >
      <Flex
        direction="row"
        paddingY={2}
        paddingX={9}
        justifyContent="space-between"
        alignItems="baseline"
      >
        <Heading fontSize="2xl" fontWeight="normal" color="brand.blue1">
          Client Dashboard
        </Heading>
        <Flex>
          <DateRangeMenu
            dashboardDates={{ startDate, endDate }}
            setFilter={(dates: [Date, Date]) => {
              const [start, end] = dates;
              setStartDate(start);
              setEndDate(end);
              setScrollPosition(currentScrollPosition.current);
            }}
          />
          {!hideClientsDashboardStaffFilterFlag && role === 'supervisor' && (
            <DashboardStaffSelectMenu
              selectedStaffMember={selectedStaffId}
              setFilter={setSelectedStaffId}
            />
          )}
        </Flex>
      </Flex>
      <Divider color="brand.gray7" />
      <Flex
        height="100%"
        overflow="hidden"
        id="grid-container"
        ref={scrollRef}
        overflowY="auto"
        onScroll={handleScroll}
      >
        <DashboardGrid numberOfRows={2}>
          <RectangleWidget>
            <AttendanceTidbits allStats={allStats} loading={loading} />
          </RectangleWidget>
          <SquareWidget justifySelf="end">
            <AttendanceDonutWidget allStats={allStats} loading={loading} />
          </SquareWidget>
          <RectangleWidget>
            <EngagementTidbits allStats={allStats} loading={loading} />
          </RectangleWidget>
          <SquareWidget justifySelf="end">
            <EngagementTripleStack
              allStats={allStats}
              loading={loading}
              selectedStaffId={selectedStaffId}
            />
          </SquareWidget>
          <FullSizeWidget>
            <AttendanceTool
              dashboardDates={{ startDate, endDate }}
              selectedStaffId={selectedStaffId}
              scrollRef={scrollRef}
              refetchDashboard={refetchDashboard}
              restorePosition={restorePosition}
            />
          </FullSizeWidget>
        </DashboardGrid>
      </Flex>
    </Flex>
  );
}

export default Dashboard;
