import developmentConfig from './development';
import productionConfig from './production';
import stageConfig from './stage';

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId?: string;
}

interface DatadogRUMType {
  applicationId: string;
  clientToken: string;
  site: string;
  service: string;
  env: string;
  sampleRate: number;
  version: string;
}

interface ConfigType {
  firebase: FirebaseConfig;
  api: {
    url: string;
    wss: string;
  };
  launchDarkly: {
    clientSideId: string;
  };
  datadogRUM: DatadogRUMType;
}

export default (): ConfigType => {
  switch (import.meta.env.VITE_ENV) {
    case 'stage':
      return stageConfig;
    case 'production':
      return productionConfig;

    case 'development':
    default:
      return developmentConfig;
  }
};
