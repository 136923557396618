import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Any valid base64 string */
  Base64: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234. */
  PhoneNumber: any;
  /** Any valid "TZ database name" from https://en.wikipedia.org/wiki/List_of_tz_database_time_zones */
  TimeZone: any;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: any;
  addressLine1_String_NotNull_maxLength_120: any;
  addressLine2_String_maxLength_120: any;
  answer_String_maxLength_2000: any;
  authUid_String_maxLength_128: any;
  body_String_maxLength_2000: any;
  caseNumber_String_maxLength_50: any;
  charge_String_maxLength_500: any;
  city_String_NotNull_maxLength_50: any;
  cmsId_String_maxLength_100: any;
  cohortLabel_String_maxLength_50: any;
  countryCode_String_maxLength_50: any;
  courtSubtype_String_maxLength_50: any;
  courtType_String_maxLength_100: any;
  dailyObligationDesc_String_maxLength_5000: any;
  defaultStaffUid_String_maxLength_80: any;
  description_String_NotNull_maxLength_100: any;
  docket_String_maxLength_100: any;
  email_String_NotNull_maxLength_100: any;
  email_String_maxLength_100: any;
  employer_String_maxLength_400: any;
  ethnicity_String_maxLength_50: any;
  explanation_String_maxLength_2000: any;
  firstName_String_maxLength_100: any;
  lastName_String_maxLength_100: any;
  locale_String_NotNull_maxLength_5: any;
  mobileCountryCode_String_maxLength_50: any;
  mobileNetworkCode_String_maxLength_50: any;
  name_String_NotNull_maxLength_100: any;
  name_String_NotNull_maxLength_200: any;
  name_String_maxLength_100: any;
  name_String_maxLength_200: any;
  nationalFormat_String_maxLength_50: any;
  number_String_maxLength_20: any;
  officeNumber_String_maxLength_20: any;
  organizationUid_String_NotNull_maxLength_36: any;
  pushBody_String_maxLength_1024: any;
  pushTitle_String_maxLength_1024: any;
  pushToken_String_maxLength_256: any;
  questionBankUid_String_NotNull_maxLength_36: any;
  sendingNumber_String_maxLength_20: any;
  staffUid_String_NotNull_maxLength_80: any;
  state_String_NotNull_maxLength_2: any;
  suffix_String_maxLength_20: any;
  supportNumber_String_maxLength_20: any;
  text_String_NotNull_maxLength_100: any;
  title_String_NotNull_maxLength_80: any;
  title_String_maxLength_80: any;
  type_String_NotNull_maxLength_50: any;
  type_String_maxLength_50: any;
  upperDocket_String_maxLength_100: any;
  zipCode_String_NotNull_maxLength_5: any;
};


export type Address = {
  __typename?: 'Address';
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type AddressInput = {
  addressLine1: Scalars['addressLine1_String_NotNull_maxLength_120'];
  addressLine2?: Maybe<Scalars['addressLine2_String_maxLength_120']>;
  city: Scalars['city_String_NotNull_maxLength_50'];
  /**
   * 2-letter USPS abbreviation for US subdivision (state, territory, etc.).
   * See [here](https://pe.usps.com/text/pub28/28apb.htm) for the list of valid codes.
   */
  state: Scalars['state_String_NotNull_maxLength_2'];
  /** 5-digit ZIP Code. */
  zipCode: Scalars['zipCode_String_NotNull_maxLength_5'];
};

export type Announcement = Node & {
  __typename?: 'Announcement';
  id: Scalars['ID'];
  body: Scalars['String'];
  /** When the announcement was (or will be) sent. */
  timestamp: Scalars['Float'];
  sender: StaffUser;
  /**
   * Messages resulting from this announcement.
   * Provide `filterStatusTypes` to filter down to messages of the given statuses.
   */
  messages: MessageConnection;
  /** When the API accepted the announcement request. */
  createdAt: Scalars['Float'];
};


export type AnnouncementMessagesArgs = {
  filterStatusTypes?: Maybe<Array<StatusType>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AnnouncementConnection = {
  __typename?: 'AnnouncementConnection';
  edges: Array<AnnouncementEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AnnouncementEdge = {
  __typename?: 'AnnouncementEdge';
  cursor: Scalars['String'];
  node?: Maybe<Announcement>;
};

export enum AttendanceInfoType {
  Attended = 'ATTENDED',
  Missed = 'MISSED',
  Blank = 'BLANK',
  Other = 'OTHER'
}

export type AutomatedAttendanceMessage = Message & Node & {
  __typename?: 'AutomatedAttendanceMessage';
  id: Scalars['ID'];
  clientId: Scalars['ID'];
  type: MessageType;
  timestamp: Scalars['Float'];
  status: StatusType;
  batchNotify: Scalars['Boolean'];
  messageThreadId?: Maybe<Scalars['ID']>;
  staffId: Scalars['ID'];
  staff: StaffUser;
  outcomeMessageId: Scalars['ID'];
  latestStreakLength: Scalars['Int'];
  platform?: Maybe<PlatformType>;
  pushOnly?: Maybe<Scalars['Boolean']>;
  pushType?: Maybe<PushType>;
  pushTitle?: Maybe<Scalars['String']>;
  pushBody?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
};

export type AutomatedAttendanceMessageInput = {
  staffId: Scalars['String'];
  clientId: Scalars['ID'];
  outcomeMessageId: Scalars['ID'];
  timestamp: Scalars['Float'];
  latestStreakLength: Scalars['Int'];
};

export type AutomatedAttendanceMessagePayload = {
  __typename?: 'AutomatedAttendanceMessagePayload';
  message: AutomatedAttendanceMessage;
};

export type Badges = {
  openCheckIns: Scalars['Int'];
  schedule: Scalars['Int'];
};

export type BankedChoice = Node & {
  __typename?: 'BankedChoice';
  id: Scalars['ID'];
  sendAlert: Scalars['Boolean'];
  needsExplanation: Scalars['Boolean'];
  order: Scalars['Int'];
  answerText: Array<Text>;
};

export type BankedQuestion = {
  id: Scalars['ID'];
  bankedQuestionUid: Scalars['String'];
  type: QuestionType;
  questionText: Array<Text>;
  tooltip?: Maybe<Array<Text>>;
  isOptional: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
};

export type BankedQuestionConnection = {
  __typename?: 'BankedQuestionConnection';
  edges: Array<BankedQuestionEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type BankedQuestionEdge = {
  __typename?: 'BankedQuestionEdge';
  cursor: Scalars['String'];
  node?: Maybe<BankedQuestion>;
};


export type BasePlatformMessage = {
  id: Scalars['ID'];
  clientId: Scalars['ID'];
  type: MessageType;
  timestamp: Scalars['Float'];
  status: StatusType;
  batchNotify: Scalars['Boolean'];
  messageThreadId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
  staffId?: Maybe<Scalars['ID']>;
  staff?: Maybe<StaffUser>;
  body?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['ID']>;
  errorMessage?: Maybe<Scalars['String']>;
  platform?: Maybe<PlatformType>;
  pushOnly?: Maybe<Scalars['Boolean']>;
  pushType?: Maybe<PushType>;
  pushTitle?: Maybe<Scalars['String']>;
  pushBody?: Maybe<Scalars['String']>;
  smsOnly?: Maybe<Scalars['Boolean']>;
  twilioSid?: Maybe<Scalars['String']>;
  template?: Maybe<Template>;
};

export type Carrier = {
  __typename?: 'Carrier';
  type?: Maybe<CarrierType>;
  name?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  nationalFormat?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Float']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobileNetworkCode?: Maybe<Scalars['String']>;
  goodToBadTimestamp?: Maybe<Scalars['Float']>;
};

/** The concrete type for messages of type `PING`. */
export type CarrierCheck = Node & {
  __typename?: 'CarrierCheck';
  id: Scalars['ID'];
  clientId: Scalars['ID'];
  timestamp: Scalars['Float'];
  status: StatusType;
  batchNotify: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Float']>;
};

export type CarrierCheckConnection = {
  __typename?: 'CarrierCheckConnection';
  edges: Array<CarrierCheckEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CarrierCheckEdge = {
  __typename?: 'CarrierCheckEdge';
  cursor: Scalars['String'];
  node?: Maybe<CarrierCheck>;
};

export enum CarrierType {
  Mobile = 'MOBILE',
  Viop = 'VIOP',
  Voip = 'VOIP',
  Landline = 'LANDLINE'
}

export type Case = Node & {
  __typename?: 'Case';
  /** GraphQL Case ID */
  id: Scalars['ID'];
  /** Case docket number */
  number: Scalars['String'];
  open: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
};

export type CaseConnection = {
  __typename?: 'CaseConnection';
  edges: Array<CaseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CaseEdge = {
  __typename?: 'CaseEdge';
  cursor: Scalars['String'];
  node?: Maybe<Case>;
};

export type CasePayload = {
  __typename?: 'CasePayload';
  case?: Maybe<Case>;
};

export enum CategoryType {
  Counseling = 'COUNSELING',
  DrugsAndAlcohol = 'DRUGS_AND_ALCOHOL',
  FoodAndClothing = 'FOOD_AND_CLOTHING',
  Healthcare = 'HEALTHCARE',
  Housing = 'HOUSING',
  Jobs = 'JOBS',
  Other = 'OTHER',
  Veterans = 'VETERANS'
}

export type CheckInEvent = Event & Node & {
  __typename?: 'CheckInEvent';
  attendanceInfo?: Maybe<AttendanceInfoType>;
  checkInReport?: Maybe<CheckInReport>;
  checkInReportId?: Maybe<Scalars['ID']>;
  checkInTemplate: CheckInTemplate;
  client: Client;
  /** Only filled in from Staff.events right now API-457 */
  clientFirstName?: Maybe<Scalars['String']>;
  clientId: Scalars['ID'];
  clientLastName?: Maybe<Scalars['String']>;
  closed: Scalars['Boolean'];
  cms?: Maybe<Scalars['JSONObject']>;
  createdAt?: Maybe<Scalars['Float']>;
  endTimestamp: Scalars['Float'];
  groupEventId?: Maybe<Scalars['ID']>;
  /** GraphQL ID */
  id: Scalars['ID'];
  isCanceled: Scalars['Boolean'];
  isCompleted: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  organizationId: Scalars['ID'];
  outcome: Outcome;
  /** The staff user requesting the checkin. */
  staff: StaffUser;
  staffAttending: Scalars['Boolean'];
  staffId: Scalars['ID'];
  startTimestamp: Scalars['Float'];
  submittedTimestamp?: Maybe<Scalars['Float']>;
  /** @deprecated Use endTimestamp */
  timestamp: Scalars['Float'];
  timezoneOffset: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  type: EventType;
  updatedAt?: Maybe<Scalars['Float']>;
};


export type CheckInEventCheckInReportArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type CheckInEventCheckInTemplateArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type CheckInPage = Node & {
  __typename?: 'CheckInPage';
  id: Scalars['ID'];
  pageNumber?: Maybe<Scalars['Int']>;
  questions: Array<CheckInQuestion>;
};

export type CheckInPageInput = {
  pageNumber: Scalars['Int'];
  questions: Array<CheckInQuestionInput>;
};

export type CheckInPageResponseInput = {
  pageId: Scalars['ID'];
  questions: Array<CheckInQuestionResponseInput>;
};

export type CheckInQuestion = {
  id: Scalars['ID'];
  isOptional: Scalars['Boolean'];
  order: Scalars['Int'];
  questionBankId: Scalars['ID'];
  questionText: Scalars['String'];
  tooltip?: Maybe<Scalars['String']>;
  type: QuestionType;
};

export type CheckInQuestionConnection = {
  __typename?: 'CheckInQuestionConnection';
  edges: Array<CheckInQuestionEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CheckInQuestionEdge = {
  __typename?: 'CheckInQuestionEdge';
  cursor: Scalars['String'];
  node?: Maybe<CheckInQuestion>;
};

export type CheckInQuestionInput = {
  questionBankId: Scalars['ID'];
  type: QuestionType;
  questionText: Array<TextInput>;
  tooltip?: Maybe<Array<TextInput>>;
  order: Scalars['Int'];
  isOptional: Scalars['Boolean'];
  choiceType?: Maybe<MultipleChoiceType>;
  choices?: Maybe<Array<ChoiceInput>>;
};

export type CheckInQuestionResponseInput = {
  questionId: Scalars['ID'];
  choiceId?: Maybe<Scalars['ID']>;
  answer?: Maybe<Scalars['answer_String_maxLength_2000']>;
  explanation?: Maybe<Scalars['explanation_String_maxLength_2000']>;
};

/** Check In Report */
export type CheckInReport = Node & {
  __typename?: 'CheckInReport';
  id: Scalars['ID'];
  timestamp: Scalars['Float'];
  organization: Organization;
  client: Client;
  checkInEvent: CheckInEvent;
  name: Scalars['String'];
  pages: Array<CheckInReportPage>;
};

export type CheckInReportConnection = {
  __typename?: 'CheckInReportConnection';
  edges: Array<CheckInReportEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CheckInReportEdge = {
  __typename?: 'CheckInReportEdge';
  cursor: Scalars['String'];
  node?: Maybe<CheckInReport>;
};

export type CheckInReportPage = Node & {
  __typename?: 'CheckInReportPage';
  id: Scalars['ID'];
  pageNumber?: Maybe<Scalars['Int']>;
  responses: Array<CheckInReportResponse>;
};

export type CheckInReportResponse = {
  __typename?: 'CheckInReportResponse';
  id: Scalars['ID'];
  questionText: Scalars['String'];
  answerText: Scalars['String'];
  order: Scalars['Int'];
  sendAlert: Scalars['Boolean'];
  needsExplanation: Scalars['Boolean'];
  explanationText?: Maybe<Scalars['String']>;
};

/** input for saving check in responses */
export type CheckInResponseInput = {
  checkInTemplateId: Scalars['ID'];
  clientId?: Maybe<Scalars['ID']>;
  eventId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Float']>;
  pages: Array<CheckInPageResponseInput>;
};

export type CheckInResponsePayload = {
  __typename?: 'CheckInResponsePayload';
  response: CheckInReport;
};

/** Check In Template */
export type CheckInTemplate = Node & {
  __typename?: 'CheckInTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  /** @deprecated use staffDisplayName */
  staffWebDisplayName: Scalars['String'];
  staffDisplayName: Scalars['String'];
  organization: Organization;
  pages: Array<CheckInPage>;
  isActive: Scalars['Boolean'];
  isSurvey: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
};

export type CheckInTemplateConnection = {
  __typename?: 'CheckInTemplateConnection';
  edges: Array<CheckInTemplateEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CheckInTemplateEdge = {
  __typename?: 'CheckInTemplateEdge';
  cursor: Scalars['String'];
  node?: Maybe<CheckInTemplate>;
};

/** Check In Template Input */
export type CheckInTemplateInput = {
  organizationId: Scalars['ID'];
  /** default: false */
  isSurvey?: Maybe<Scalars['Boolean']>;
  names: Array<CheckInTemplateNameInput>;
  pages: Array<CheckInPageInput>;
};

export type CheckInTemplateNameInput = {
  locale: Scalars['String'];
  name: Scalars['String'];
  staffDisplayName: Scalars['String'];
};

export type CheckInTemplatePayload = {
  __typename?: 'CheckInTemplatePayload';
  id: Scalars['ID'];
};

export type Choice = Node & {
  __typename?: 'Choice';
  id: Scalars['ID'];
  answerText: Scalars['String'];
  needsExplanation: Scalars['Boolean'];
  order: Scalars['Int'];
  sendAlert: Scalars['Boolean'];
};

export type ChoiceInput = {
  sendAlert: Scalars['Boolean'];
  needsExplanation: Scalars['Boolean'];
  order: Scalars['Int'];
  answerText: Array<TextInput>;
};

export type ClearReminderMessageInput = {
  id: Scalars['ID'];
  eventUid: Scalars['String'];
};

export type ClearReminderMessagePayload = {
  __typename?: 'ClearReminderMessagePayload';
  deleted: Scalars['Boolean'];
};

export type Client = Node & {
  __typename?: 'Client';
  activity?: Maybe<Array<Maybe<Scalars['String']>>>;
  address?: Maybe<Address>;
  alerts?: Maybe<Array<Maybe<Scalars['String']>>>;
  arrestDateTimestamp?: Maybe<Scalars['Float']>;
  /**
   * The Client has a "direct message" thread with each of their assigned staff.
   * This list will have length [1, `Organization.maxStaffPerClient`].
   * The first StaffUser in this list is their primary staff, also available via
   * `Client.staff`.
   */
  assignedStaff: Array<StaffUser>;
  carrier?: Maybe<Carrier>;
  /** List of cases for the client */
  cases: CaseConnection;
  charge?: Maybe<Scalars['String']>;
  checkInReports: CheckInReportConnection;
  /** @deprecated use needsChildCare */
  childCare?: Maybe<Scalars['Boolean']>;
  /**
   * Internal Firebase ID
   * @deprecated Use clientUid instead
   */
  clientId: Scalars['String'];
  clientUid: Scalars['String'];
  clientUpdatedAddress: Scalars['Boolean'];
  clientUpdatedEmployer: Scalars['Boolean'];
  clientUpdatedPhone: Scalars['Boolean'];
  closed: Scalars['Boolean'];
  closedAt?: Maybe<Scalars['Float']>;
  cms?: Maybe<Scalars['JSONObject']>;
  /** Client id in site content management system */
  cmsId?: Maybe<Scalars['String']>;
  cohort?: Maybe<Cohort>;
  cohortId?: Maybe<Scalars['ID']>;
  contacts: ContactConnection;
  created?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Float']>;
  /**
   * Returns true or null
   * @deprecated not used anymore in enduser-mobile
   */
  dailyObligation?: Maybe<Scalars['Boolean']>;
  dailyObligationDesc?: Maybe<Scalars['String']>;
  dailyObligationKind?: Maybe<DailyObligationKindType>;
  displayName?: Maybe<Scalars['String']>;
  docket?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  employer?: Maybe<Scalars['String']>;
  ethnicity?: Maybe<Scalars['String']>;
  /**
   * Events are sorted by descending `timestamp` order
   *   - `timestamp` means `startTimestamp` for court, office, and virtual events and `endTimestamp` for check-in events
   */
  events: EventConnection;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderType>;
  hasMobileApp: Scalars['Boolean'];
  hasOpenCases?: Maybe<Scalars['Boolean']>;
  housingInsecure?: Maybe<Scalars['Boolean']>;
  /** GraphQL ID */
  id: Scalars['ID'];
  inCustody: Scalars['Boolean'];
  isAttendanceRequired?: Maybe<Scalars['Boolean']>;
  language?: Maybe<LanguageType>;
  /**
   * The largest timestamp of the following:
   *  - The time the client was created in staff-web (a one-time event)
   *  - The last time a `FromEndUserMessage` was received from the client
   *  - The last time a carrier check was handled for the client
   *  - The last time an SMS was successfully queued for the client
   *  - the last time a push notification was successfully sent to the client
   *  - The start of the earliest unindicated event
   *  - [SLC ONLY]: The last time the ETL processed one of this client's records from a batch file
   *
   * Often used to restrict the list of clients that are visible/returned for UX displays.
   */
  lastActiveTimestamp?: Maybe<Scalars['Float']>;
  lastName?: Maybe<Scalars['String']>;
  lastOpenedTimestamp?: Maybe<Scalars['Float']>;
  /** The body of the most recent PlatformMessage sent to the client, either Welcomes, Autoreplies or Reminders */
  lastPlatformBody?: Maybe<Scalars['String']>;
  /** The last time a `PlatformMessage` was sent to the client */
  lastPlatformTimestamp?: Maybe<Scalars['Float']>;
  /** The body of the `FromEndUserMessage` that was last received from the client. */
  lastReceivedBody?: Maybe<Scalars['String']>;
  /** The time that a `FromEndUserMessage` was last received from the client. */
  lastReceivedTimestamp?: Maybe<Scalars['Float']>;
  /**
   * The result of the latest attempt at sending an SMS message to the client,
   * set either following the initial Twilio API request or from a message
   * status callback request from Twilio. Either
   *  - "error", if the send attempt immediately received a 4xx or 5xx response
   *    from Twilio's REST API); or
   *  - one of Twilio's message status values, otherwise. See
   *    [here](https://www.twilio.com/docs/sms/api/message-resource#message-status-values)
   *    for a list.
   * @deprecated Use lastSendStatus
   */
  lastSend?: Maybe<Scalars['String']>;
  /** The last time that an autoreply was sent to the client */
  lastSendAutoTimestamp?: Maybe<Scalars['Float']>;
  /** The body of the most recent `FromStaffMessage` that was sent to the client. */
  lastSendBody?: Maybe<Scalars['String']>;
  /** The last time that a `FromStaffMessage` was sent to the client. */
  lastSendManualTimestamp?: Maybe<Scalars['Float']>;
  /** "The status of the latest attempt at sending an SMS message to the client." */
  lastSendStatus?: Maybe<StatusType>;
  /**
   * The last time any of the following occurred:
   *  - any type of message was sent to the client
   *  - we received a message status callback request from Twilio regarding a message
   *    that we attempted to send to this client via SMS
   */
  lastSendTimestamp?: Maybe<Scalars['Float']>;
  /**
   * In each thread the participants are this Client plus
   * some immutable set of StaffUsers and groups of StaffUsers
   * (e.g., a "Supervisor/Support" group).
   *
   * Not supported until the client's org is onboarded to MSPC.
   * Additionally, does not include threads that contain 0 messages.
   *
   * If `staffId` is given, the list is further filtered depending on the given staff's role:
   *   - If `STAFF`: a list of length 0 or 1, containing the 1:1 thread between them
   *     and client (if such a thread exists).
   *   - Otherwise, all threads.
   * This allows for the discovery of the most recent message across any of
   * the threads, from the perspective of the staff with the given ID.
   */
  messageThreads: Array<MessageThread>;
  messages: MessageConnection;
  needsChildCare?: Maybe<Scalars['Boolean']>;
  needsPing?: Maybe<Scalars['Boolean']>;
  noCar?: Maybe<Scalars['Boolean']>;
  noPhone?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  optOut?: Maybe<Scalars['Boolean']>;
  orgPhone?: Maybe<Scalars['String']>;
  organization: Organization;
  phone?: Maybe<Scalars['String']>;
  riskLevel?: Maybe<RiskLevelType>;
  /**
   * If the client is eligible to directly receive messages.
   * Returns false for inactive or incarcerated clients; true otherwise.
   */
  shouldReceiveMessages: Scalars['Boolean'];
  staff?: Maybe<StaffUser>;
  staffId?: Maybe<Scalars['ID']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  suffix?: Maybe<Scalars['String']>;
  /** Only used with organizations of type 'probation_parole' */
  terms?: Maybe<Terms>;
  transportation?: Maybe<TransportationType>;
  updatedAt?: Maybe<Scalars['Float']>;
  upperDocket?: Maybe<Scalars['String']>;
  user?: Maybe<EndUser>;
  userId?: Maybe<Scalars['ID']>;
  /** @deprecated Use address */
  zip?: Maybe<Scalars['String']>;
};


export type ClientCasesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ClientCheckInReportsArgs = {
  locale?: Maybe<Scalars['String']>;
  fromTimestamp?: Maybe<Scalars['Float']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ClientContactsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ClientEventsArgs = {
  type?: Maybe<EventType>;
  eventFilter?: Maybe<EventFilter>;
  eventFilterTimestamp?: Maybe<Scalars['Float']>;
  onlyOpen?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ClientMessageThreadsArgs = {
  staffId?: Maybe<Scalars['ID']>;
};


export type ClientMessagesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ClientConnection = {
  __typename?: 'ClientConnection';
  edges: Array<ClientEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ClientEdge = {
  __typename?: 'ClientEdge';
  cursor: Scalars['String'];
  node?: Maybe<Client>;
};

export type ClientPayload = {
  __typename?: 'ClientPayload';
  client: Client;
};

export type ClientStats = {
  __typename?: 'ClientStats';
  /** Count of all clients who have the app */
  hasMobileApp: Scalars['Int'];
  /** Count of all clients who can receive a message through sms and don't have the app */
  reachableBySms: Scalars['Int'];
  /** Count of all clients who are currently unreachable */
  unreachable: Scalars['Int'];
  /** Count of all clients who have sent a message in the last 30 days */
  sentMessage: Scalars['Int'];
  /** Count of all clients */
  total: Scalars['Int'];
};

export type ClientStatsFilter = {
  organizationId: Scalars['ID'];
  /** Returns clients of given staff. See onlyAssignedTo flag */
  staffIds: Array<Scalars['ID']>;
  /**
   * False - Returns all visible clients of given staff
   * True - Returns all clients that are directly assigned to staff
   */
  onlyAssignedTo?: Maybe<Scalars['Boolean']>;
  /**
   * If specified, `endTimestamp` is also required.
   * Defaults to 30 days ago.
   * Must be within last 6 months.
   */
  startTimestamp?: Maybe<Scalars['Float']>;
  /**
   * If specified, `startTimestamp` is also required.
   * Defaults to now().
   * Must be within last 6 months.
   */
  endTimestamp?: Maybe<Scalars['Float']>;
};

/**
 * A Cohort is a way to segment clients along some dimension.
 * These can be different for each organization.
 */
export type Cohort = Node & {
  __typename?: 'Cohort';
  /** GraphQL ID */
  id: Scalars['ID'];
  name: Array<Text>;
  createdAt: Scalars['Float'];
  updatedAt: Scalars['Float'];
};

export type CohortPayload = {
  __typename?: 'CohortPayload';
  cohort?: Maybe<Cohort>;
};

export type Condition = Node & {
  __typename?: 'Condition';
  id: Scalars['ID'];
  description: Scalars['String'];
};

export type ConditionConnection = {
  __typename?: 'ConditionConnection';
  edges: Array<ConditionEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ConditionEdge = {
  __typename?: 'ConditionEdge';
  cursor: Scalars['String'];
  node?: Maybe<Condition>;
};

export type Contact = Node & {
  __typename?: 'Contact';
  /** GraphQL ID */
  id: Scalars['ID'];
  /** The client to whom this contact belongs */
  client?: Maybe<Client>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  /** The organization that the client is a client of */
  organization?: Maybe<Organization>;
  phone?: Maybe<Scalars['String']>;
  /** Whether or not to send reminders to this contact INSTEAD of the main client */
  optIn?: Maybe<Scalars['Boolean']>;
  /** The body of the `FromEndUserMessage` that was last received from the client. */
  lastReceivedBody?: Maybe<Scalars['String']>;
  /** The time that a `FromEndUserMessage` was last received from the client. */
  lastReceivedTimestamp?: Maybe<Scalars['Float']>;
  /**
   * The result of the latest attempt at sending an SMS message to the client,
   * set either following the initial Twilio API request or from a message
   * status callback request from Twilio. Either
   *  - "error", if the send attempt immediately received a 4xx or 5xx response
   *    from Twilio's REST API); or
   *  - one of Twilio's message status values, otherwise. See
   *    [here](https://www.twilio.com/docs/sms/api/message-resource#message-status-values)
   *    for a list.
   * @deprecated Use lastSendStatus
   */
  lastSend?: Maybe<Scalars['String']>;
  /** "The status of the latest attempt at sending an SMS message to the client." */
  lastSendStatus?: Maybe<StatusType>;
  /** The body of the most recent `FromStaffMessage` that was sent to the client. */
  lastSendBody?: Maybe<Scalars['String']>;
  /** The last time that a `FromStaffMessage` was sent to the client. */
  lastSendManualTimestamp?: Maybe<Scalars['Float']>;
  /**
   * The last time any of the following occurred:
   *  - any type of message was sent to the client
   *  - we received a message status callback request from Twilio regarding a message
   *    that we attempted to send to this client via SMS
   */
  lastSendTimestamp?: Maybe<Scalars['Float']>;
  /** The last time that an autoreply was sent to the client */
  lastSendAutoTimestamp?: Maybe<Scalars['Float']>;
  /**
   * The largest timestamp of the following:
   *  - The last time an SMS was received from the contact
   *  - The last time an SMS was successfully queued for the contact
   */
  lastActiveTimestamp?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
};

export type ContactConnection = {
  __typename?: 'ContactConnection';
  edges: Array<ContactEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ContactEdge = {
  __typename?: 'ContactEdge';
  cursor: Scalars['String'];
  node?: Maybe<Contact>;
};

export type ContactPayload = {
  __typename?: 'ContactPayload';
  contact: Contact;
};

/**
 * E.g.,
 *   /organizations/<org>/courts/<loc>
 *     addr: "123 Fake St"
 *     city: "Justiceville"
 *     child_care: true
 *     zip: 01370
 *     rooms:
 *       "HR01": "Hearing Room 1"
 *       "HR02": "Hearing Room 2"
 */
export type CourtDateEvent = Event & Node & {
  __typename?: 'CourtDateEvent';
  attendanceInfo?: Maybe<AttendanceInfoType>;
  attended?: Maybe<Outcome>;
  /** @deprecated Temp till auth on nested subscription data fixed (API-542) */
  attendedUid?: Maybe<Scalars['String']>;
  /** Optional identifier for the case associated with the court appearance, or an attorney name. */
  caseNumber?: Maybe<Scalars['String']>;
  client: Client;
  clientFirstName?: Maybe<Scalars['String']>;
  clientId: Scalars['ID'];
  clientLastName?: Maybe<Scalars['String']>;
  closed: Scalars['Boolean'];
  cms?: Maybe<Scalars['JSONObject']>;
  /** Additional information on the type of legal proceeding */
  courtSubtype?: Maybe<Scalars['String']>;
  /** Optional code or specification on the type of legal proceeding, such as 'Arraignment'. */
  courtType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Float']>;
  /** Always null */
  endTimestamp?: Maybe<Scalars['Float']>;
  groupEventId?: Maybe<Scalars['ID']>;
  /** GraphQL ID */
  id: Scalars['ID'];
  isCanceled: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  judge?: Maybe<Judge>;
  location: Location;
  outcome: Outcome;
  room?: Maybe<Room>;
  /** @deprecated not needed */
  scheduled?: Maybe<Scalars['Boolean']>;
  staff: StaffUser;
  staffAttending: Scalars['Boolean'];
  staffId: Scalars['ID'];
  startTimestamp: Scalars['Float'];
  /** @deprecated Use startTimestamp */
  timestamp: Scalars['Float'];
  timezoneOffset: Scalars['Int'];
  title: Scalars['String'];
  triggeredOutcome?: Maybe<Outcome>;
  /** @deprecated Temp till auth on nested subscription data fixed (API-542) */
  triggeredOutcomeUid?: Maybe<Scalars['String']>;
  triggeredTimestamp?: Maybe<Scalars['Float']>;
  type: EventType;
  updatedAt?: Maybe<Scalars['Float']>;
  /** @deprecated not needed */
  welcomed?: Maybe<Scalars['Boolean']>;
};

export type CreateCaseInput = {
  clientId: Scalars['ID'];
  number: Scalars['String'];
  open: Scalars['Boolean'];
};

export type CreateCheckInEventInput = {
  clientId: Scalars['ID'];
  /** Defaults to primary staff */
  staffId?: Maybe<Scalars['ID']>;
  checkInTemplateId: Scalars['ID'];
  /** Event id in the site's content management system */
  cmsId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Float']>;
  startTimestamp: Scalars['Float'];
  /** Required; marked optional for backwards compatibility with timestamp */
  endTimestamp?: Maybe<Scalars['Float']>;
  timezoneOffset: Scalars['Int'];
  /** Default: `true` */
  isRequired?: Maybe<Scalars['Boolean']>;
  cms?: Maybe<Scalars['JSONObject']>;
};

export type CreateCheckInEventPayload = {
  __typename?: 'CreateCheckInEventPayload';
  checkInEvent: CheckInEvent;
};

export type CreateCheckInEventsPayload = {
  __typename?: 'CreateCheckInEventsPayload';
  event?: Maybe<Array<Maybe<Event>>>;
};

export type CreateClientInput = {
  organizationId: Scalars['ID'];
  /** The client's primary assigned staff. */
  staffId: Scalars['ID'];
  /** The client's non-primary assigned staff. If too many are specified, an error is given. */
  additionalStaff?: Maybe<Array<Scalars['ID']>>;
  cmsId?: Maybe<Scalars['cmsId_String_maxLength_100']>;
  cms?: Maybe<Scalars['JSONObject']>;
  firstName?: Maybe<Scalars['firstName_String_maxLength_100']>;
  lastName?: Maybe<Scalars['lastName_String_maxLength_100']>;
  phone?: Maybe<Scalars['String']>;
  language?: Maybe<LanguageType>;
  email?: Maybe<Scalars['email_String_maxLength_100']>;
  address?: Maybe<AddressInput>;
  employer?: Maybe<Scalars['employer_String_maxLength_400']>;
  gender?: Maybe<GenderType>;
  /** milliseconds */
  created?: Maybe<Scalars['Float']>;
  hasOpenCases?: Maybe<Scalars['Boolean']>;
  needsPing?: Maybe<Scalars['Boolean']>;
  lastActiveTimestamp?: Maybe<Scalars['Float']>;
  riskLevel?: Maybe<RiskLevelType>;
  arrestDateTimestamp?: Maybe<Scalars['Float']>;
  charge?: Maybe<Scalars['charge_String_maxLength_500']>;
  dailyObligation?: Maybe<Scalars['Boolean']>;
  dailyObligationDesc?: Maybe<Scalars['dailyObligationDesc_String_maxLength_5000']>;
  dailyObligationKind?: Maybe<DailyObligationKindType>;
  noCar?: Maybe<Scalars['Boolean']>;
  noPhone?: Maybe<Scalars['Boolean']>;
  housingInsecure?: Maybe<Scalars['Boolean']>;
  needsChildCare?: Maybe<Scalars['Boolean']>;
  transportation?: Maybe<TransportationType>;
  notes?: Maybe<Scalars['String']>;
  docket?: Maybe<Scalars['docket_String_maxLength_100']>;
  upperDocket?: Maybe<Scalars['upperDocket_String_maxLength_100']>;
  closed?: Maybe<Scalars['Boolean']>;
  /** Only set if closed is true */
  closedAt?: Maybe<Scalars['Float']>;
  inCustody?: Maybe<Scalars['Boolean']>;
  ethnicity?: Maybe<Scalars['ethnicity_String_maxLength_50']>;
  isAttendanceRequired?: Maybe<Scalars['Boolean']>;
  optOut?: Maybe<Scalars['Boolean']>;
  suffix?: Maybe<Scalars['suffix_String_maxLength_20']>;
  cohortId?: Maybe<Scalars['ID']>;
  orgPhone?: Maybe<Scalars['String']>;
};

export type CreateCohortInput = {
  organizationId: Scalars['ID'];
  cohort: CreateSingleCohortInput;
};

export type CreateCohortsInput = {
  organizationId: Scalars['ID'];
  cohorts: Array<CreateSingleCohortInput>;
};

export type CreateCohortsPayload = {
  __typename?: 'CreateCohortsPayload';
  cohorts: Array<Cohort>;
};

export type CreateContactInput = {
  /** The client to whom this contact belongs */
  clientId: Scalars['ID'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  /** The organization that the client is a client of */
  organizationId?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  optIn?: Maybe<Scalars['Boolean']>;
};

export type CreateCourtDateInput = {
  clientId: Scalars['ID'];
  staffId?: Maybe<Scalars['ID']>;
  /** Event id in the site's content management system */
  cmsId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Float']>;
  /** Required; marked optional for backwards compatibility with timestamp */
  startTimestamp?: Maybe<Scalars['Float']>;
  endTimestamp?: Maybe<Scalars['Float']>;
  timezoneOffset: Scalars['Int'];
  locationId: Scalars['ID'];
  roomId?: Maybe<Scalars['ID']>;
  judgeId?: Maybe<Scalars['ID']>;
  attendedId?: Maybe<Scalars['ID']>;
  /** Default: `true` */
  isRequired?: Maybe<Scalars['Boolean']>;
  cms?: Maybe<Scalars['JSONObject']>;
  courtType?: Maybe<Scalars['courtType_String_maxLength_100']>;
  courtSubtype?: Maybe<Scalars['courtSubtype_String_maxLength_50']>;
  caseNumber?: Maybe<Scalars['caseNumber_String_maxLength_50']>;
  staffAttending?: Maybe<Scalars['Boolean']>;
};

export type CreateCourtDatePayload = {
  __typename?: 'CreateCourtDatePayload';
  courtDate: CourtDateEvent;
};

export type CreateEndUserInput = {
  name?: Maybe<Scalars['name_String_maxLength_200']>;
  phone: Scalars['String'];
  clientIds: Array<Scalars['ID']>;
  authUid: Scalars['ID'];
};

export type CreateEndUserPayload = {
  __typename?: 'CreateEndUserPayload';
  userId: Scalars['ID'];
};

export type CreateEventDetails = {
  timezoneOffset: Scalars['Int'];
  /** Not to be used with createRoom */
  roomId?: Maybe<Scalars['ID']>;
  /** Not to be used with locationId. Expect 0 or 1 created room */
  createLocation?: Maybe<CreateLocationInput>;
  /**
   * Not to be used with roomId or createLocation.
   * Room can be created in createLocation.
   */
  createRoom?: Maybe<CreateRoomInput>;
  isRequired?: Maybe<Scalars['Boolean']>;
  cms?: Maybe<Scalars['JSONObject']>;
  cmsId?: Maybe<Scalars['String']>;
  staffAttending?: Maybe<Scalars['Boolean']>;
  checkInTemplateId?: Maybe<Scalars['ID']>;
  judgeId?: Maybe<Scalars['ID']>;
  attendedId?: Maybe<Scalars['ID']>;
  courtType?: Maybe<Scalars['courtType_String_maxLength_100']>;
  courtSubtype?: Maybe<Scalars['courtSubtype_String_maxLength_50']>;
  caseNumber?: Maybe<Scalars['caseNumber_String_maxLength_50']>;
};

export type CreateGroupEventInput = {
  staffId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  eventType: EventType;
  clientIds: Array<Scalars['ID']>;
  locationId?: Maybe<Scalars['String']>;
  startTimestamp?: Maybe<Scalars['Float']>;
  endTimestamp?: Maybe<Scalars['Float']>;
  eventDetails?: Maybe<CreateEventDetails>;
};

export type CreateGroupEventPayload = {
  __typename?: 'CreateGroupEventPayload';
  groupEvent?: Maybe<GroupEvent>;
};

export type CreateJudgeInput = {
  /** GraphQL ID */
  organizationId: Scalars['ID'];
  /** The name of this Judge */
  name: Scalars['String'];
};

export type CreateLocationInput = {
  organizationId: Scalars['ID'];
  location: CreateSingleLocationInput;
};

export type CreateLocationsInput = {
  organizationId: Scalars['ID'];
  locations: Array<CreateSingleLocationInput>;
};

export type CreateLocationsPayload = {
  __typename?: 'CreateLocationsPayload';
  locations: Array<Location>;
};

export type CreateOfficeEventInput = {
  clientId: Scalars['ID'];
  staffId?: Maybe<Scalars['ID']>;
  /** Event id in site content management system */
  cmsId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Float']>;
  /** Required; marked optional for backwards compatibility with timestamp */
  startTimestamp?: Maybe<Scalars['Float']>;
  endTimestamp?: Maybe<Scalars['Float']>;
  timezoneOffset: Scalars['Int'];
  title: Scalars['title_String_NotNull_maxLength_80'];
  /** Not to be used with createLocation */
  locationId?: Maybe<Scalars['ID']>;
  /** Not to be used with createRoom */
  roomId?: Maybe<Scalars['ID']>;
  /** Not to be used with locationId. Expect 0 or 1 created room */
  createLocation?: Maybe<CreateLocationInput>;
  /**
   * Not to be used with roomId or createLocation.
   * Room can be created in createLocation.
   */
  createRoom?: Maybe<CreateRoomInput>;
  attendedId?: Maybe<Scalars['ID']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  cms?: Maybe<Scalars['JSONObject']>;
  staffAttending?: Maybe<Scalars['Boolean']>;
};

export type CreateOfficeEventPayload = {
  __typename?: 'CreateOfficeEventPayload';
  officeEvent: OfficeEvent;
};

export type CreateOrganizationInput = {
  organizationUid: Scalars['organizationUid_String_NotNull_maxLength_36'];
  name?: Maybe<Scalars['name_String_maxLength_100']>;
  sendingNumber?: Maybe<Scalars['sendingNumber_String_maxLength_20']>;
  officeNumber?: Maybe<Scalars['officeNumber_String_maxLength_20']>;
  timezone?: Maybe<Scalars['TimeZone']>;
  type: Scalars['type_String_NotNull_maxLength_50'];
  selfOnboard?: Maybe<Scalars['Boolean']>;
  cohortLabel?: Maybe<Scalars['cohortLabel_String_maxLength_50']>;
  defaultStaffUid?: Maybe<Scalars['defaultStaffUid_String_maxLength_80']>;
};

export type CreateOutcomeInput = {
  attendanceId: Scalars['ID'];
  status: OutcomeType;
  name: Scalars['String'];
};

export type CreateOutcomePayload = {
  __typename?: 'CreateOutcomePayload';
  outcome?: Maybe<Outcome>;
};

export type CreatePhoneClaimInput = {
  phoneNumber: Scalars['String'];
  authUid: Scalars['String'];
  timestamp: Scalars['Float'];
};

export type CreateQuietHoursInput = {
  autoreply: Scalars['String'];
  autoreply_enabled: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  end: Scalars['Float'];
  /** Staff ID (GraphQL ID or internal Firebase ID) */
  staffId: Scalars['ID'];
  start: Scalars['Float'];
  weekends_off: Scalars['Boolean'];
};

export type CreateQuietHoursPayload = {
  __typename?: 'CreateQuietHoursPayload';
  quietHours: QuietHours;
};

export type CreateRoomInput = {
  locationId: Scalars['ID'];
  room: CreateSingleRoomInput;
};

export type CreateRoomsInput = {
  locationId: Scalars['ID'];
  rooms: Array<CreateSingleRoomInput>;
};

export type CreateRoomsPayload = {
  __typename?: 'CreateRoomsPayload';
  rooms: Array<Room>;
};

export type CreateSingleCohortInput = {
  name: Array<NameTextInput>;
};

export type CreateSingleLocationInput = {
  name: Scalars['name_String_NotNull_maxLength_100'];
  address: AddressInput;
  /** defaults to false */
  providesChildCare?: Maybe<Scalars['Boolean']>;
  rooms?: Maybe<Array<CreateSingleRoomInput>>;
  /** defaults to false */
  isExternal?: Maybe<Scalars['Boolean']>;
};

export type CreateSingleRoomInput = {
  name?: Maybe<Scalars['name_String_maxLength_100']>;
  description: Scalars['description_String_NotNull_maxLength_100'];
};

export type CreateStaffPayload = {
  __typename?: 'CreateStaffPayload';
  staff: StaffUser;
};

export type CreateStaffUserInput = {
  staffUid: Scalars['staffUid_String_NotNull_maxLength_80'];
  organizationId: Scalars['ID'];
  email: Scalars['email_String_NotNull_maxLength_100'];
  lawyer?: Maybe<Scalars['Boolean']>;
  name: Scalars['name_String_NotNull_maxLength_200'];
  pushToken?: Maybe<Scalars['pushToken_String_maxLength_256']>;
  supervisor?: Maybe<Scalars['Boolean']>;
  authUid?: Maybe<Scalars['authUid_String_maxLength_128']>;
};

export type CreateTemplateInput = {
  organizationId: Scalars['ID'];
  templateUid: Scalars['String'];
  body: Array<TemplateBodyInput>;
  days?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['Int']>;
  contact?: Maybe<Scalars['Boolean']>;
  type?: Maybe<TemplateType>;
  targetStart?: Maybe<Scalars['Boolean']>;
};

export type CreateTemplatePayload = {
  __typename?: 'CreateTemplatePayload';
  template: Template;
};

export type CreateVirtualEventInput = {
  clientId: Scalars['ID'];
  /** Defaults to primary staff */
  staffId?: Maybe<Scalars['ID']>;
  /** Event id in the site's content management system */
  cmsId?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Float']>;
  /** Required; marked optional for backwards compatibility with timestamp */
  startTimestamp?: Maybe<Scalars['Float']>;
  endTimestamp: Scalars['Float'];
  timezoneOffset: Scalars['Int'];
  cms?: Maybe<Scalars['JSONObject']>;
  title: Scalars['String'];
  isRequired?: Maybe<Scalars['Boolean']>;
};

export type CreateVirtualEventPayload = {
  __typename?: 'CreateVirtualEventPayload';
  virtualEvent: VirtualEvent;
};

export enum DailyObligationKindType {
  Job = 'JOB',
  Childcare = 'CHILDCARE',
  Eldercare = 'ELDERCARE',
  School = 'SCHOOL',
  Other = 'OTHER'
}

export type DeleteAutomatedAttendanceMessagesInput = {
  messageIds: Array<Scalars['ID']>;
};

export type DeleteCarrierCheckInput = {
  /** GraphQL ID */
  carrierCheckId: Scalars['ID'];
};

export type DeleteCaseInput = {
  caseId: Scalars['ID'];
};

export type DeleteCheckInEventInput = {
  id: Scalars['ID'];
};

export type DeleteCheckInEventPayload = {
  __typename?: 'DeleteCheckInEventPayload';
  checkInEvent: CheckInEvent;
};

export type DeleteClientInput = {
  id: Scalars['ID'];
  deletedAt: Scalars['Float'];
};

export type DeleteCohortInput = {
  cohortId: Scalars['ID'];
};

export type DeleteCohortPayload = {
  __typename?: 'DeleteCohortPayload';
  deleted: Scalars['Boolean'];
};

export type DeleteContactInput = {
  id: Scalars['ID'];
};

export type DeleteCourtDateInput = {
  id: Scalars['ID'];
};

export type DeleteCourtDatePayload = {
  __typename?: 'DeleteCourtDatePayload';
  courtDate: CourtDateEvent;
};

export type DeleteEndUserInput = {
  endUserId: Scalars['ID'];
};

export type DeleteEndUserPayload = {
  __typename?: 'DeleteEndUserPayload';
  deleted: Scalars['Boolean'];
};

export type DeleteJudgeInput = {
  /** GraphQL ID */
  id: Scalars['ID'];
};

export type DeleteMessageInput = {
  /** GraphQL ID */
  messageId: Scalars['ID'];
};

export type DeleteOfficeEventInput = {
  id: Scalars['ID'];
};

export type DeleteOfficeEventPayload = {
  __typename?: 'DeleteOfficeEventPayload';
  officeEvent: OfficeEvent;
};

export type DeleteOrganizationInput = {
  id: Scalars['ID'];
};

export type DeleteOutcomePayload = {
  __typename?: 'DeleteOutcomePayload';
  deleted: Scalars['Boolean'];
};

export type DeleteRoomInput = {
  id: Scalars['ID'];
};

export type DeleteVirtualEventInput = {
  id: Scalars['ID'];
};

export type DeleteVirtualEventPayload = {
  __typename?: 'DeleteVirtualEventPayload';
  virtualEvent: VirtualEvent;
};

export type DestroyLocationInput = {
  id: Scalars['ID'];
};

export type EndUser = User & Node & {
  __typename?: 'EndUser';
  /** GraphQL ID */
  id: Scalars['ID'];
  type: UserType;
  /** Will leave as string until it's required by API-1042 */
  authUid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  pushToken?: Maybe<Scalars['String']>;
  badges?: Maybe<EndUserBadges>;
  address?: Maybe<Address>;
  employer?: Maybe<Scalars['String']>;
  clientUpdatedPhone: Scalars['Boolean'];
  clientUpdatedEmployer: Scalars['Boolean'];
  clientUpdatedAddress: Scalars['Boolean'];
  clients: ClientConnection;
  events: EventConnection;
  socialServices: SocialServiceConnection;
};


export type EndUserClientsArgs = {
  closed?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type EndUserEventsArgs = {
  type?: Maybe<EventType>;
  eventFilter?: Maybe<EventFilter>;
  eventFilterTimestamp?: Maybe<Scalars['Float']>;
  onlyOpen?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type EndUserSocialServicesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Counts of various types of messages by threads that require an end user's attention. */
export type EndUserBadgeThread = {
  __typename?: 'EndUserBadgeThread';
  messageThreadId: Scalars['ID'];
  value: Scalars['Int'];
};

export type EndUserBadgeThreadInput = {
  messageThreadId: Scalars['ID'];
  value: Scalars['Int'];
};

export type EndUserBadges = Badges & {
  __typename?: 'EndUserBadges';
  conversations?: Maybe<Array<EndUserConversation>>;
  threads?: Maybe<Array<EndUserBadgeThread>>;
  openCheckIns: Scalars['Int'];
  schedule: Scalars['Int'];
};

/** Counts of various types of messages by org that require an end user's attention. */
export type EndUserConversation = {
  __typename?: 'EndUserConversation';
  organizationId: Scalars['ID'];
  value: Scalars['Int'];
};

export type EndUserConversationInput = {
  organizationId: Scalars['ID'];
  value: Scalars['Int'];
};

export type Event = {
  attendanceInfo?: Maybe<AttendanceInfoType>;
  client: Client;
  /** Added for performance reasons. Only non-null in StaffUser.events */
  clientFirstName?: Maybe<Scalars['String']>;
  clientId: Scalars['ID'];
  clientLastName?: Maybe<Scalars['String']>;
  closed: Scalars['Boolean'];
  /**
   * Arbitrary data from the customer's case management system that may be
   * worth retaining.
   */
  cms?: Maybe<Scalars['JSONObject']>;
  createdAt?: Maybe<Scalars['Float']>;
  /**
   * Millisecond-precision unix timestamp. The end time for this event. May be null if the event
   * does not have a duration.
   */
  endTimestamp?: Maybe<Scalars['Float']>;
  /** Refers to the Group Event the event belongs to, otherwise null. */
  groupEventId?: Maybe<Scalars['ID']>;
  /** GraphQL ID */
  id: Scalars['ID'];
  isCanceled: Scalars['Boolean'];
  /**
   * Whether the client's attendance would be required by law, or just loosely "required"
   * by a staff member.
   *
   * This value may change, e.g., if a supervisor changes their mind about whether a
   * particular `OfficeEvent` is required.
   */
  isRequired: Scalars['Boolean'];
  outcome: Outcome;
  /** Staff user relevant for the event */
  staff: StaffUser;
  staffAttending: Scalars['Boolean'];
  staffId: Scalars['ID'];
  /** Millisecond-precision unix timestamp. The start time for this event. */
  startTimestamp: Scalars['Float'];
  /**
   * Millisecond-precision unix timestamp.
   *
   * Refers to the date/time emphasized in the reminder language and of highest importance
   * to the event type, e.g., when a `CheckInEvent` is due.
   *
   * Due to the confusing semantics of this value for events with durations, this is deprecated.
   * @deprecated See startTimestamp/endTimestamp instead
   */
  timestamp: Scalars['Float'];
  timezoneOffset: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  type: EventType;
  updatedAt?: Maybe<Scalars['Float']>;
};

export type EventConnection = {
  __typename?: 'EventConnection';
  edges: Array<EventEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type EventEdge = {
  __typename?: 'EventEdge';
  cursor: Scalars['String'];
  node?: Maybe<Event>;
};

export enum EventFilter {
  Past = 'PAST',
  Upcoming = 'UPCOMING',
  All = 'ALL'
}

/**
 * These stats group events in a similar (but not identical) manner as `Event.attendanceInfo`,
 * a field computed client-side by staff-web. For details see API-861.
 */
export type EventOutcomeStats = {
  __typename?: 'EventOutcomeStats';
  /**
   * Count of all events that have been attended.
   *   CheckIn, Virtual: status is NotFailed
   *   CourtDate, Office: outcomeUid is Attended ("1")
   */
  attended: Scalars['Int'];
  /**
   * Count of all events that have been missed.
   *   CheckIn, Virtual: status is Failed
   *   CourtDate, Office: outcomeUid is Missed ("0") or BenchWarrant ("6")
   */
  missed: Scalars['Int'];
  /**
   * Count of all events that have outcome set other than attended or missed.
   *   CheckIn, Virtual: N/A
   *   CourtDate, Office: outcomeUid is set but is not Attended("1"), Missed ("0"), or BenchWarrant ("6")
   */
  other: Scalars['Int'];
  /**
   * Count of all events that have no outcome set.
   *   CheckIn, Virtual: N/A
   *   CourtDate, Office: outcomeUid is null
   */
  unknown: Scalars['Int'];
  /** Total count of all events */
  total: Scalars['Int'];
};

export type EventStatsFilter = {
  organizationId: Scalars['ID'];
  /** Returns events of clients for given staff. See onlyAssignedTo flag */
  staffIds: Array<Scalars['ID']>;
  /**
   * False - Returns all events of visible clients for given staff
   * True - Returns all events of clients that are directly assigned to staff
   */
  onlyAssignedTo?: Maybe<Scalars['Boolean']>;
  /**
   * If specified, `endTimestamp` is also required.
   * Defaults to 30 days ago.
   * Must be within last 6 months.
   */
  startTimestamp?: Maybe<Scalars['Float']>;
  /**
   * If specified, `startTimestamp` is also required.
   * Defaults to now().
   * Must be within last 6 months.
   */
  endTimestamp?: Maybe<Scalars['Float']>;
};

export enum EventType {
  CourtDate = 'COURT_DATE',
  CheckIn = 'CHECK_IN',
  Office = 'OFFICE',
  Virtual = 'VIRTUAL'
}

export type EventsByTypeOutcomeStats = {
  __typename?: 'EventsByTypeOutcomeStats';
  courtDate: EventOutcomeStats;
  office: EventOutcomeStats;
  checkIn: EventOutcomeStats;
  virtual: EventOutcomeStats;
  totalEvents: EventOutcomeStats;
};

export enum FeatureForFeedback {
  VideoChat = 'VIDEO_CHAT'
}

/** The concrete type for messages of type `INCOMING`. */
export type FromEndUserMessage = Message & Node & {
  __typename?: 'FromEndUserMessage';
  id: Scalars['ID'];
  clientId: Scalars['ID'];
  /** Always INCOMING. */
  type: MessageType;
  /** The time at which Uptrust received the message. */
  timestamp: Scalars['Float'];
  /** Always RECEIVED, DELIVERED or ERROR. */
  status: StatusType;
  platform?: Maybe<PlatformType>;
  pushOnly?: Maybe<Scalars['Boolean']>;
  pushType?: Maybe<PushType>;
  pushTitle?: Maybe<Scalars['String']>;
  pushBody?: Maybe<Scalars['String']>;
  messageThreadId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
  staffId?: Maybe<Scalars['ID']>;
  staff?: Maybe<StaffUser>;
  batchNotify: Scalars['Boolean'];
  batchNotifyTimestamp?: Maybe<Scalars['Float']>;
  body?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['ID']>;
  /**
   * Whether side effects (e.g., notifying managers, updating client/contact
   * `last_received`) have resolved. Ought not block the readability of the message by
   * any party.
   *
   * Only has meaning for PlatformType.MOBILE messages.
   */
  processed?: Maybe<Scalars['Boolean']>;
  sid?: Maybe<Scalars['String']>;
  /** Number the message was from. Only defined for `PlatformType.SMS` messages. */
  fromPhoneNumber?: Maybe<Scalars['String']>;
  /** Number the message was to. Only defined for `PlatformType.SMS` messages. */
  toPhoneNumber?: Maybe<Scalars['String']>;
  /** Media sent with an MMS message. Only defined for `PlatformType.SMS` messages. */
  media?: Maybe<Array<Media>>;
  errorMessage?: Maybe<Scalars['String']>;
};

/** The concrete type for messages of type `REMINDER` and `OUTCOME`. */
export type FromPlatformEventMessage = BasePlatformMessage & Message & Node & {
  __typename?: 'FromPlatformEventMessage';
  id: Scalars['ID'];
  clientId: Scalars['ID'];
  type: MessageType;
  timestamp: Scalars['Float'];
  status: StatusType;
  batchNotify: Scalars['Boolean'];
  messageThreadId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
  staffId?: Maybe<Scalars['ID']>;
  staff?: Maybe<StaffUser>;
  event?: Maybe<Event>;
  body?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['ID']>;
  errorMessage?: Maybe<Scalars['String']>;
  platform?: Maybe<PlatformType>;
  pushOnly?: Maybe<Scalars['Boolean']>;
  pushType?: Maybe<PushType>;
  pushTitle?: Maybe<Scalars['String']>;
  pushBody?: Maybe<Scalars['String']>;
  smsOnly?: Maybe<Scalars['Boolean']>;
  twilioSid?: Maybe<Scalars['String']>;
  template?: Maybe<Template>;
};

/** The concrete type for messages of type `WELCOME`, and `AUTOREPLY`. */
export type FromPlatformMessage = BasePlatformMessage & Message & Node & {
  __typename?: 'FromPlatformMessage';
  id: Scalars['ID'];
  clientId: Scalars['ID'];
  type: MessageType;
  timestamp: Scalars['Float'];
  status: StatusType;
  batchNotify: Scalars['Boolean'];
  messageThreadId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
  staffId?: Maybe<Scalars['ID']>;
  staff?: Maybe<StaffUser>;
  body?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['ID']>;
  errorMessage?: Maybe<Scalars['String']>;
  platform?: Maybe<PlatformType>;
  pushOnly?: Maybe<Scalars['Boolean']>;
  pushType?: Maybe<PushType>;
  pushTitle?: Maybe<Scalars['String']>;
  pushBody?: Maybe<Scalars['String']>;
  smsOnly?: Maybe<Scalars['Boolean']>;
  twilioSid?: Maybe<Scalars['String']>;
  template?: Maybe<Template>;
};

export type FromPlatformMessageInput = {
  clientId: Scalars['ID'];
  type: MessageType;
  timestamp: Scalars['Float'];
  staffId?: Maybe<Scalars['ID']>;
  eventId?: Maybe<Scalars['ID']>;
  body?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['ID']>;
  templateId?: Maybe<Scalars['ID']>;
  platform?: Maybe<PlatformType>;
};

export type FromPlatformMessagePayload = {
  __typename?: 'FromPlatformMessagePayload';
  message: BasePlatformMessage;
};

/** The concrete type for messages of type `MANUAL`. */
export type FromStaffMessage = Message & Node & {
  __typename?: 'FromStaffMessage';
  id: Scalars['ID'];
  clientId: Scalars['ID'];
  type: MessageType;
  timestamp: Scalars['Float'];
  status: StatusType;
  batchNotify: Scalars['Boolean'];
  messageThreadId?: Maybe<Scalars['ID']>;
  body?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  platform?: Maybe<PlatformType>;
  pushOnly?: Maybe<Scalars['Boolean']>;
  pushType?: Maybe<PushType>;
  pushTitle?: Maybe<Scalars['String']>;
  pushBody?: Maybe<Scalars['String']>;
  smsOnly?: Maybe<Scalars['Boolean']>;
  twilioSid?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['ID']>;
  senderName?: Maybe<Scalars['String']>;
  /** Alias of `senderId`. */
  staffId?: Maybe<Scalars['ID']>;
  staff?: Maybe<StaffUser>;
  /** If part of an announcement, the announcement record */
  announcement?: Maybe<Announcement>;
  createdAt?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
};

export type FullCheckInPage = Node & {
  __typename?: 'FullCheckInPage';
  id: Scalars['ID'];
  pageNumber?: Maybe<Scalars['Int']>;
  questions: Array<FullCheckInQuestion>;
};

export type FullCheckInQuestion = {
  id: Scalars['ID'];
  isOptional: Scalars['Boolean'];
  order: Scalars['Int'];
  questionBankId: Scalars['ID'];
  questionText: Array<Text>;
  tooltip: Array<Text>;
  type: QuestionType;
};

/** Check In Template with all language versions */
export type FullCheckInTemplate = Node & {
  __typename?: 'FullCheckInTemplate';
  id: Scalars['ID'];
  name: Array<Text>;
  /** @deprecated use staffDisplayName */
  staffWebDisplayName: Array<Text>;
  staffDisplayName: Array<Text>;
  organization: Organization;
  pages: Array<FullCheckInPage>;
  isActive: Scalars['Boolean'];
  isSurvey: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
};

export type FullChoice = Node & {
  __typename?: 'FullChoice';
  id: Scalars['ID'];
  answerText: Array<Text>;
  needsExplanation: Scalars['Boolean'];
  order: Scalars['Int'];
  sendAlert: Scalars['Boolean'];
};

export type FullMultipleChoiceQuestion = FullCheckInQuestion & Node & {
  __typename?: 'FullMultipleChoiceQuestion';
  id: Scalars['ID'];
  isOptional: Scalars['Boolean'];
  order: Scalars['Int'];
  questionBankId: Scalars['ID'];
  questionText: Array<Text>;
  tooltip: Array<Text>;
  type: QuestionType;
  choiceType: MultipleChoiceType;
  choices: Array<FullChoice>;
};

export type FullTextQuestion = FullCheckInQuestion & Node & {
  __typename?: 'FullTextQuestion';
  id: Scalars['ID'];
  isOptional: Scalars['Boolean'];
  order: Scalars['Int'];
  questionBankId: Scalars['ID'];
  questionText: Array<Text>;
  tooltip: Array<Text>;
  type: QuestionType;
};

export enum GenderType {
  Male = 'MALE',
  Female = 'FEMALE'
}

export type GetAllOrganizationsFilter = {
  active?: Maybe<Scalars['Boolean']>;
};

export type GetClientsFilter = {
  /** GraphQL Organization ID */
  organizationId: Scalars['ID'];
  /** Filter clients with the following active status - defaults to true */
  active?: Maybe<Scalars['Boolean']>;
  /** Clients that may be messaged by the given staff ID */
  messageableByStaffId: Scalars['ID'];
  /** Select clients whose primary staff's ID is in the given list */
  clientsOfStaffIds?: Maybe<Array<Scalars['ID']>>;
  /**
   * Select clients that have some event that starts or ends in given time period.
   * Requires `GetClientsFilter.timeZone` to be set.
   * Cannot be used with `outcomeId` filter
   */
  hasEventsInPeriod?: Maybe<TimePeriod>;
  timeZone?: Maybe<Scalars['TimeZone']>;
  /**
   * Clients whose outcome matches given outcome ID where event has already
   * ended.
   * Cannot be used with `hasEventsInPeriod` filter
   * Cannot be used with outcomeStatus filter
   * Note - This id is actually the attendedId: type: Attended, ids: [orgUid, outcomeUid]
   *        which is returned from Organizations.outcomes
   */
  outcomeId?: Maybe<Scalars['ID']>;
  /**
   * Clients whose outcome status matches given OutcomeType
   * Cannot be used with outcomeId filter
   */
  outcomeStatus?: Maybe<OutcomeType>;
  /** Select clients whose cohort's ID is in the given list */
  cohortIds?: Maybe<Array<Scalars['ID']>>;
  /** Select clients with at least one open event */
  onlyOpen?: Maybe<Scalars['Boolean']>;
};

export type GetFromEndUserMessageInfoInput = {
  from: Scalars['PhoneNumber'];
  to: Scalars['PhoneNumber'];
};

export type GetFromEndUserMessageInfoPayload = {
  __typename?: 'GetFromEndUserMessageInfoPayload';
  /** Null indicates no org was found for number */
  organizationId?: Maybe<Scalars['ID']>;
  /**
   * NONE indicates that we don't recognize the input numbers as
   * relating to any known Client or Contact.
   */
  senderType: SmsSenderType;
  sender?: Maybe<SmsSender>;
  /** NONE indicates no staff was found for number */
  recipientIdType: RecipientIdType;
  recipientId?: Maybe<Scalars['ID']>;
};

export type GroupEvent = Node & {
  __typename?: 'GroupEvent';
  id: Scalars['ID'];
  title: Scalars['String'];
  staff: StaffUser;
  staffId: Scalars['String'];
  events: EventConnection;
  clients: ClientConnection;
  location?: Maybe<Location>;
  startTimestamp: Scalars['Float'];
  endTimestamp?: Maybe<Scalars['Float']>;
  createdAt: Scalars['Float'];
  updatedAt: Scalars['Float'];
};


export type GroupEventEventsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type GroupEventClientsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type GroupEventConnection = {
  __typename?: 'GroupEventConnection';
  edges: Array<GroupEventEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type GroupEventEdge = {
  __typename?: 'GroupEventEdge';
  cursor: Scalars['String'];
  node?: Maybe<GroupEvent>;
};

export enum IsActiveFilter {
  True = 'TRUE',
  False = 'FALSE',
  All = 'ALL'
}

export type IssueFileUploadUrlInput = {
  /** The ID of the Client to record as the uploader of the file. */
  uploaderId: Scalars['ID'];
  /** The size of the file to be uploaded, in bytes. */
  contentLength: Scalars['Int'];
  /**
   * Base64-encoded MD5 digest of the file contents, to aid with corruption
   * detection during upload.
   */
  contentMd5: Scalars['Base64'];
  /**
   * The media type (f.k.a. MIME type) of the file. See
   * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
   * Must be lowercase and have no leading or trailing whitespace.
   */
  contentType: Scalars['String'];
};

export type IssueFileUploadUrlPayload = {
  __typename?: 'IssueFileUploadUrlPayload';
  /** The URL to which to make a PUT request to upload a file. */
  uploadUrl: Scalars['URL'];
  /**
   * A list of key-value pairs representing HTTP header names and values.
   * These must be included in the upload request, or else it will fail.
   */
  uploadHeaders: Array<Array<Scalars['String']>>;
  /**
   * The canonical URL for the uploaded file. Use this when referencing the file in
   * places like a `Media.url`.
   */
  canonicalUrl: Scalars['URL'];
  /**
   * The time at which the `uploadUrl` expires. Any upload request must be in progress
   * by this time or else it may be denied.
   */
  expiresAt: Scalars['Timestamp'];
};

export type IssueMasqueradeTokenInput = {
  /** Any valid `StaffUser.id`. */
  userId: Scalars['ID'];
};

export type IssueMasqueradeTokenPayload = {
  __typename?: 'IssueMasqueradeTokenPayload';
  /**
   * A short-lived credential authorizing its bearer to execute operations using
   * the security privileges of another user--with the important caveat that access
   * is read-only.
   */
  token: Scalars['String'];
};

export type IssueVideoChatTokenInput = {
  /** Video chat-eligible event for which an access token is requested. */
  eventId: Scalars['ID'];
};

export type IssueVideoChatTokenPayload = {
  __typename?: 'IssueVideoChatTokenPayload';
  /**
   * Access token scoped to a video chat room whose `UniqueName` is
   * the given `eventId`.
   */
  accessToken: Scalars['String'];
};


/**
 * The judge assigned to this event. Judges can be specified at the org-level.
 * E.g.,
 *   /organizations/<org>/judges
 *     <judge1>:
 *       name: <name1>
 *     <judge2>:
 *       name: <name2>
 *     <judge3>:
 *       name: <name3>
 *     ...
 */
export type Judge = Node & {
  __typename?: 'Judge';
  id: Scalars['ID'];
  /**
   * The Firebase key for this judge.
   *
   * We would normally hide internal IDs like this one, but templates often use it
   * in their rendering logic.
   */
  judgeUid: Scalars['String'];
  /** @deprecated Use judgeUid */
  judgeId: Scalars['String'];
  /** The name of this Judge */
  name: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
};

export type JudgePayload = {
  __typename?: 'JudgePayload';
  judge: Judge;
};

export enum LanguageType {
  English = 'ENGLISH',
  Spanish = 'SPANISH',
  Russian = 'RUSSIAN',
  Chinese = 'CHINESE',
  Other = 'OTHER'
}

export type Location = Node & {
  __typename?: 'Location';
  id: Scalars['ID'];
  /**
   * The uid of the location
   * Corresponds to the firebase key for this location
   * Might not be human readable
   */
  locationUid: Scalars['String'];
  /** @deprecated Use locationUid */
  locationId: Scalars['String'];
  /**
   * The uid of the organization
   * Corresponds to the firebase key for the org
   * @deprecated not needed
   */
  organizationId?: Maybe<Scalars['String']>;
  /** The human-readable address of the location. */
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  /** @deprecated Use addressLine1 */
  address: Scalars['String'];
  /** The city of the location */
  city: Scalars['String'];
  /** The state of the location */
  state?: Maybe<Scalars['String']>;
  /** The zipcode of the location */
  zip?: Maybe<Scalars['String']>;
  /**
   * Whether this location has on-site childcare.
   * Firebase null means false.
   */
  providesChildCare: Scalars['Boolean'];
  /** Whether this location is not on main site. */
  isExternal: Scalars['Boolean'];
  /** The list of rooms at the location */
  rooms: RoomConnection;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
};


export type LocationRoomsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type LocationPayload = {
  __typename?: 'LocationPayload';
  location: Location;
};

export type Media = {
  __typename?: 'Media';
  /**
   * Media type (f.k.a. MIME type). See [here](https://www.twilio.com/docs/sms/accepted-mime-types#accepted-mime-types)
   * for possible values.
   */
  contentType: Scalars['String'];
  /** A capability URL -- https://www.w3.org/TR/capability-urls/ -- so handle with care! */
  url: Scalars['String'];
  /** Expiration of the `url`, if one applies. */
  expiresAt?: Maybe<Scalars['Timestamp']>;
};

export type MediaInput = {
  contentType: Scalars['String'];
  url: Scalars['String'];
};

/**
 * Messages model correspondence between a client (or a contact thereof) and their
 * organization that was (or will be) mediated by Uptrust.
 */
export type Message = {
  /** GraphQL ID */
  id: Scalars['ID'];
  /** Global Client Id */
  clientId: Scalars['ID'];
  /**
   * ID of the StaffUser who is the sender of this message, or is the person
   * indirectly responsible for the message being sent.
   */
  staffId?: Maybe<Scalars['ID']>;
  staff?: Maybe<StaffUser>;
  /** Type of message */
  type: MessageType;
  /** Timestamp (ms) at which the effects of the message were (or will be) attempted. */
  timestamp: Scalars['Float'];
  /** Status type of message */
  status: StatusType;
  /** If true, message is set to be batched to send to staff at a later time */
  batchNotify: Scalars['Boolean'];
  platform?: Maybe<PlatformType>;
  /**
   * Whether the message is intended to contain a `body` (rather than just a `pushBody`).
   * This is set by the message template's `push_only` key, or defaults to `false`.
   * Messages for which this field is `false` but whose body template rendering fails or produces an
   * empty string will proceed to `StatusType.SKIPPED` and will not be delivered.
   */
  pushOnly?: Maybe<Scalars['Boolean']>;
  pushType?: Maybe<PushType>;
  /** The recommended title for push notification representations of the message. */
  pushTitle?: Maybe<Scalars['String']>;
  /** The recommended body for push notification representations of the message. */
  pushBody?: Maybe<Scalars['String']>;
  /** Message thread associated with the message */
  messageThreadId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
};

export type MessageConnection = {
  __typename?: 'MessageConnection';
  edges: Array<MessageEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MessageEdge = {
  __typename?: 'MessageEdge';
  cursor: Scalars['String'];
  node?: Maybe<Message>;
};

export type MessageStats = {
  __typename?: 'MessageStats';
  /** Count of all messages of type MANUAL */
  fromStaff: Scalars['Int'];
  /** Count of all messages of type INCOMING */
  fromEndUser: Scalars['Int'];
  /** Count of all messages of type REMINDER, WELCOME, and OUTCOME */
  fromPlatform: Scalars['Int'];
  /** Count of all messages of type AUTOREPLY */
  other: Scalars['Int'];
  /** Count of all messages */
  total: Scalars['Int'];
};

/**
 * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Stats
 * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 */
export type MessageStatsFilter = {
  organizationId: Scalars['ID'];
  /** Returns messages for clients of given staff. See onlyAssignedTo flag */
  staffIds: Array<Scalars['ID']>;
  /**
   * False - Returns all messages for visible clients of given staff
   * True - Returns all messages for clients that are directly assigned to staff
   */
  onlyAssignedTo?: Maybe<Scalars['Boolean']>;
  /**
   * If specified, `endTimestamp` is also required.
   * Defaults to 30 days ago.
   * Must be within last 6 months.
   */
  startTimestamp?: Maybe<Scalars['Float']>;
  /**
   * If specified, `startTimestamp` is also required.
   * Defaults to now().
   * Must be within last 6 months.
   */
  endTimestamp?: Maybe<Scalars['Float']>;
  /** excludeStatuses and includeStatuses are mutually exclusive */
  excludeStatuses?: Maybe<Array<StatusType>>;
  includeStatuses?: Maybe<Array<StatusType>>;
};

export type MessageThread = Node & {
  __typename?: 'MessageThread';
  id: Scalars['ID'];
  /** Name to be displayed for thread. */
  name: Scalars['String'];
  /** Global id of staff associated with this thread, null if support thread */
  staffId?: Maybe<Scalars['ID']>;
  /** The message that would appear at the bottom of the conversation view. */
  lastMessage?: Maybe<Scalars['String']>;
  lastMessageTimestamp?: Maybe<Scalars['Float']>;
  /** A hint to whether the principal would be allowed to send a message to this thread. */
  canSend: Scalars['Boolean'];
};

export enum MessageType {
  /**
   * Any automated message to a client or contact regarding an impending court date,
   * checkin, or office event (including the initial "welcome" message).
   * Initial welcome messages get status 'ready'; others start as 'pending'.
   *
   * May define `contact`, denoting that the message is to that contact, not the client.
   */
  Reminder = 'REMINDER',
  /**
   * A message from a staff member to a client, hand-typed in staff-web or staff-mobile.
   * Initial status 'ready'.
   */
  Manual = 'MANUAL',
  /**
   * A message to:
   *   - Newly-created clients and contacts, welcoming them to Uptrust.
   *   - Newly created end users when switching from SMS->MOBILE during phone claiming.
   *     Sent only once to a single client associated with the user.
   * Initial status 'ready'.
   *
   * May define `contact`, denoting that the message is to that contact, not the client.
   */
  Welcome = 'WELCOME',
  /**
   * A task-like message which, when "sent", refreshes the client's `Carrier` data.
   *
   * Additionally, if the refreshed `CarrierType` is not `MOBILE`, notify the client's
   * manager on the grounds that SMS messages may not be deliverable to the phone number
   * currently on file for the client.
   */
  Ping = 'PING',
  /**
   * If a client sends a message
   *  - to a staff member during their Quiet Hours, and
   *  - that staff member has autoreplies enabled,
   * an AUTOREPLY message will be created, with initial status 'ready', with a body equal
   * to the incoming message's client's staff's configured autoreply.
   *
   * May define `contact`, denoting that the message is a reply to that contact, not the
   * client.
   */
  Autoreply = 'AUTOREPLY',
  /**
   * Message from a client (via SMS or enduser-mobile) or contact thereof (via SMS), to
   * that client's org.
   *
   * May define `contact`, denoting that the message was received from that contact, not
   * the client.
   */
  Incoming = 'INCOMING',
  /**
   * An OUTCOME message is created, with initial status 'ready', if an event gains an
   * outcome for which the event's corresponding organization defines a trigger. It's an
   * automated message from an organization to a client regarding the outcome. For
   * example, a public defender org may configure a trigger on "failed to appear"
   * outcomes. That trigger would cause OUTCOME messages to be sent to the clients after
   * failures to appear, alerting them to prudent next steps.
   *
   * Additionally, if the event is "eligible" and the client attended it, data about the
   * client is enqueued to be sent in the next Automated Attendance Message (AAM) email
   * sent the client's manager. This email summarizes the manager's clients' current
   * attendance streaks.
   *
   * Note: If the event is nearly eligible but is too far in the past to qualify for AAM,
   * the message to the client will be SKIPPED.
   */
  Outcome = 'OUTCOME',
  /** Message type for queueing AAM alerts to staff */
  Attendance = 'ATTENDANCE',
  /** If the persisted message completely lacks a status. */
  Undefined = 'UNDEFINED'
}

export type MessagesFilter = {
  clientId: Scalars['ID'];
  staffId: Scalars['ID'];
  /** ATTENDANCE, if present, is ignored. */
  types?: Maybe<Array<MessageType>>;
};

export type MultipleChoiceBankedQuestion = BankedQuestion & Node & {
  __typename?: 'MultipleChoiceBankedQuestion';
  id: Scalars['ID'];
  bankedQuestionUid: Scalars['String'];
  type: QuestionType;
  questionText: Array<Text>;
  tooltip?: Maybe<Array<Text>>;
  isOptional: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
  choiceType?: Maybe<MultipleChoiceType>;
  choices?: Maybe<Array<BankedChoice>>;
};

export type MultipleChoiceQuestion = CheckInQuestion & Node & {
  __typename?: 'MultipleChoiceQuestion';
  id: Scalars['ID'];
  isOptional: Scalars['Boolean'];
  order: Scalars['Int'];
  questionBankId: Scalars['ID'];
  questionText: Scalars['String'];
  tooltip?: Maybe<Scalars['String']>;
  type: QuestionType;
  choiceType: MultipleChoiceType;
  choices: Array<Choice>;
};

export enum MultipleChoiceType {
  Other = 'OTHER',
  YesNo = 'YES_NO'
}

export type Mutation = {
  __typename?: 'Mutation';
  _dummy?: Maybe<Scalars['Boolean']>;
  /**
   * Create new Quiet Hours settings for a staff user.
   * NOTE: Quiet Hours settings must not exist before creation.
   */
  createQuietHours: CreateQuietHoursPayload;
  /**
   * Update existing Quiet Hours settings
   * NOTE: Quiet Hours settings must exist before update.
   */
  updateQuietHours: UpdateQuietHoursPayload;
  /** Create EndUser. For use by Uptrust systems only. */
  createEndUser: CreateEndUserPayload;
  /** Update existing EndUser. For use by Uptrust systems only. */
  updateEndUser: UpdateEndUserPayload;
  /** Update EndUser social service favorite */
  updateEndUserSocialService: UpdateEndUserPayload;
  updateEndUserBadges: UpdateEndUserPayload;
  /** Update EndUser phone, address, and employer and all associated clients */
  updateEndUserInfoFromClient: UpdateEndUserPayload;
  updateEndUserAuth: UpdateEndUserPayload;
  deleteEndUser: DeleteEndUserPayload;
  updateStaffBadges: UpdateStaffPayload;
  createStaffUser: CreateStaffPayload;
  updateStaffPushToken: UpdateStaffPayload;
  updateStaffNotifyFlags: UpdateStaffPayload;
  updateStaff: UpdateStaffPayload;
  updateStaffAssignments: StaffUserConnection;
  createOrganization: OrganizationPayload;
  deleteOrganization: OrganizationPayload;
  updateOrganization: OrganizationPayload;
  updatePhoneNumbersOfOrganization: OrganizationPayload;
  createCohort: CohortPayload;
  createCohorts: CreateCohortsPayload;
  updateCohort: CohortPayload;
  deleteCohort: DeleteCohortPayload;
  /**
   * Create new template for an organization.
   * NOTE: Template must not exist before creation.
   */
  createTemplate: CreateTemplatePayload;
  /**
   * Update existing Template
   * NOTE: Template must exist before update.
   */
  updateTemplate: UpdateTemplatePayload;
  /**
   * Get a URL to upload an object to via PUT request.
   * Currently usable only by EndUsers, for uploading images.
   *
   * The subsequent upload request must include a `Content-Length`
   * header whose value is consistent with that given in this mutation's
   * input. In addition the request must include any headers returned in
   * `IssueFileUploadUrlPayload.uploadHeaders`.
   *
   * Errors:
   *   - `UNSUPPORTED_MEDIA_TYPE` if the declared media type does not have the
   *     top-level type `image`.
   *   - `FILE_SIZE_LIMIT_EXCEEDED` if the declared file size exceeds the limit
   *     related to the declared media type.
   */
  issueFileUploadUrl: IssueFileUploadUrlPayload;
  /** Send FromStaff Message */
  sendFromStaffMessage: SendFromStaffMessagePayload;
  /** Send FromPlatform Message */
  sendFromPlatformMessage: FromPlatformMessagePayload;
  /** Schedule FromPlatform Message */
  scheduleFromPlatformMessage: FromPlatformMessagePayload;
  /** Send FromEndUser Message */
  sendFromEndUserMessage: SendFromEndUserMessagePayload;
  /** Send an announcement. Only StaffUsers may call this. */
  sendAnnouncement: SendAnnouncementPayload;
  /** Update a FromStaff message. */
  updateFromStaffMessage: UpdateFromStaffMessagePayload;
  /** Update a FromEndUser message. */
  updateFromEndUserMessage: UpdateFromEndUserMessagePayload;
  /** Update a FromPlatform message. */
  updateFromPlatformMessage: UpdateFromPlatformMessagePayload;
  /** Set message status from pending -> ready */
  updatePendingToReadyMessages: UpdatePendingToReadyMessagesPayload;
  /** Delete pending message from pending/. Only needed for Firebase. No-op for Postgres */
  deletePendingMessage: Scalars['Boolean'];
  /** Delete message for batch delivery from queues/quiet_hours_messages */
  deleteQuietHoursMessageFromQueue: Scalars['Boolean'];
  /** Delete message from queues/messages/batch_notify */
  deleteBatchNotifyMessageFromQueue: Scalars['Boolean'];
  /** Completely delete a reminder message from the system */
  clearReminderMessage: ClearReminderMessagePayload;
  /** Queues Automated Attendance Alerts to staff */
  saveAutomatedAttendanceMessage: AutomatedAttendanceMessagePayload;
  /** Deletes Automated Attendance Message Alerts from list of ids */
  deleteAutomatedAttendanceMessages: Scalars['Boolean'];
  /** Send Carrier Check (ping message type) */
  sendCarrierCheck: SendCarrierCheckPayload;
  /** Update a Carrier Check. */
  updateCarrierCheck: UpdateCarrierCheckPayload;
  /** Delete message from queues/messages/carrierCheckNotify */
  deleteBatchNotifyCarrierCheckFromQueue: Scalars['Boolean'];
  createJudge: JudgePayload;
  updateJudge: JudgePayload;
  deleteJudge: JudgePayload;
  createRooms: CreateRoomsPayload;
  createRoom: RoomPayload;
  updateRoom: RoomPayload;
  deleteRoom: RoomPayload;
  createLocations: CreateLocationsPayload;
  createLocation: LocationPayload;
  updateLocation: LocationPayload;
  destroyLocation: LocationPayload;
  softDeleteLocation: LocationPayload;
  createGroupEvent?: Maybe<CreateGroupEventPayload>;
  updateGroupEvent?: Maybe<UpdateGroupEventPayload>;
  deleteGroupEvent?: Maybe<Scalars['Boolean']>;
  /** Create new CheckIn Event */
  createCheckInEvent: CreateCheckInEventPayload;
  /** Update an existing CheckIn Event */
  updateCheckInEvent: UpdateCheckInEventPayload;
  /** Delete an existing CheckIn Event */
  deleteCheckInEvent: DeleteCheckInEventPayload;
  createCourtDate: CreateCourtDatePayload;
  updateCourtDate: UpdateCourtDatePayload;
  deleteCourtDate: DeleteCourtDatePayload;
  /** Create new Office Event */
  createOfficeEvent: CreateOfficeEventPayload;
  /** Update existing Office Event */
  updateOfficeEvent: UpdateOfficeEventPayload;
  /** Delete existing Office Event */
  deleteOfficeEvent: DeleteOfficeEventPayload;
  /** Create a Virtual Event */
  createVirtualEvent: CreateVirtualEventPayload;
  /** Delete an existing Virtual Event */
  deleteVirtualEvent: DeleteVirtualEventPayload;
  /** Update an existing Virtual Event */
  updateVirtualEvent: UpdateVirtualEventPayload;
  /** Allow the client to update a subset of the event information */
  updateVirtualEventFromClient: UpdateVirtualEventPayload;
  createOutcome: CreateOutcomePayload;
  updateOutcome: UpdateOutcomePayload;
  deleteOutcome: DeleteOutcomePayload;
  saveUserFeedback: UserFeedbackPayload;
  createClient: ClientPayload;
  updateClientInfo: ClientPayload;
  updateClientMessageStatus: ClientPayload;
  updateClientCarrier: UpdateClientCarrierPayload;
  updateClientUser: UpdateClientUserPayload;
  deleteClient: ClientPayload;
  unlinkClientFromUser: Scalars['Boolean'];
  /** Create a new question for the question bank */
  createBankedQuestion: QuestionBankPayload;
  /** Save check in response */
  saveCheckInResponse: CheckInResponsePayload;
  /** Create Check In Template */
  createCheckInTemplate: CheckInTemplatePayload;
  /** Update Check In Template */
  updateCheckInTemplate: UpdateCheckInTemplatePayload;
  /** Send batched check in responses to staff */
  sendBatchCheckInReports?: Maybe<Scalars['Boolean']>;
  createContact: ContactPayload;
  updateContact: ContactPayload;
  updateContactMessageStatus: ContactPayload;
  deleteContact: ContactPayload;
  createPhoneClaim: PhoneClaimPayload;
  updatePhoneClaim: PhoneClaimPayload;
  /**
   * Get an access token to join a video chat room.
   * Allowed if and only if the caller is either the event's client, or is that
   * client's primary staff.
   */
  issueVideoChatToken: IssueVideoChatTokenPayload;
  createCase: CasePayload;
  updateCase: CasePayload;
  deleteCase: CasePayload;
  /**
   * Issues a masquerade token: a short-lived credential authorizing its bearer to
   * execute operations using the security privileges of another user, the
   * "masqueradee"--with the important caveat that access is read-only.
   *
   * This mutation is only authorized for users with the `isAdminRead` permission.
   */
  issueMasqueradeToken: IssueMasqueradeTokenPayload;
};


export type MutationCreateQuietHoursArgs = {
  input: CreateQuietHoursInput;
};


export type MutationUpdateQuietHoursArgs = {
  input: UpdateQuietHoursInput;
};


export type MutationCreateEndUserArgs = {
  input: CreateEndUserInput;
};


export type MutationUpdateEndUserArgs = {
  input: UpdateEndUserInput;
};


export type MutationUpdateEndUserSocialServiceArgs = {
  input: UpdateEndUserSocialServiceInput;
};


export type MutationUpdateEndUserBadgesArgs = {
  input: UpdateEndUserBadgesInput;
};


export type MutationUpdateEndUserInfoFromClientArgs = {
  input: UpdateEndUserInfoInput;
};


export type MutationUpdateEndUserAuthArgs = {
  input: UpdateEndUserAuthInput;
};


export type MutationDeleteEndUserArgs = {
  input: DeleteEndUserInput;
};


export type MutationUpdateStaffBadgesArgs = {
  input: UpdateStaffBadgesInput;
};


export type MutationCreateStaffUserArgs = {
  input: CreateStaffUserInput;
};


export type MutationUpdateStaffPushTokenArgs = {
  input: UpdateStaffPushTokenInput;
};


export type MutationUpdateStaffNotifyFlagsArgs = {
  input: UpdateStaffNotifyFlagsInput;
};


export type MutationUpdateStaffArgs = {
  input: UpdateStaffInput;
};


export type MutationUpdateStaffAssignmentsArgs = {
  input: UpdateStaffAssignmentsInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationUpdatePhoneNumbersOfOrganizationArgs = {
  input: UpdatePhoneNumbersOfOrganizationInput;
};


export type MutationCreateCohortArgs = {
  input: CreateCohortInput;
};


export type MutationCreateCohortsArgs = {
  input: CreateCohortsInput;
};


export type MutationUpdateCohortArgs = {
  input: UpdateCohortInput;
};


export type MutationDeleteCohortArgs = {
  input: DeleteCohortInput;
};


export type MutationCreateTemplateArgs = {
  input: CreateTemplateInput;
};


export type MutationUpdateTemplateArgs = {
  input: UpdateTemplateInput;
};


export type MutationIssueFileUploadUrlArgs = {
  input: IssueFileUploadUrlInput;
};


export type MutationSendFromStaffMessageArgs = {
  input: SendFromStaffMessageInput;
};


export type MutationSendFromPlatformMessageArgs = {
  input: FromPlatformMessageInput;
};


export type MutationScheduleFromPlatformMessageArgs = {
  input: FromPlatformMessageInput;
};


export type MutationSendFromEndUserMessageArgs = {
  input: SendFromEndUserMessageInput;
};


export type MutationSendAnnouncementArgs = {
  input: SendAnnouncementInput;
};


export type MutationUpdateFromStaffMessageArgs = {
  input?: Maybe<UpdateFromStaffMessageInput>;
};


export type MutationUpdateFromEndUserMessageArgs = {
  input?: Maybe<UpdateFromEndUserMessageInput>;
};


export type MutationUpdateFromPlatformMessageArgs = {
  input?: Maybe<UpdateFromPlatformMessageInput>;
};


export type MutationUpdatePendingToReadyMessagesArgs = {
  sendAt: Scalars['Float'];
};


export type MutationDeletePendingMessageArgs = {
  eventUid: Scalars['String'];
  messageUid: Scalars['String'];
};


export type MutationDeleteQuietHoursMessageFromQueueArgs = {
  input: DeleteMessageInput;
};


export type MutationDeleteBatchNotifyMessageFromQueueArgs = {
  input: DeleteMessageInput;
};


export type MutationClearReminderMessageArgs = {
  input?: Maybe<ClearReminderMessageInput>;
};


export type MutationSaveAutomatedAttendanceMessageArgs = {
  input: AutomatedAttendanceMessageInput;
};


export type MutationDeleteAutomatedAttendanceMessagesArgs = {
  input: DeleteAutomatedAttendanceMessagesInput;
};


export type MutationSendCarrierCheckArgs = {
  input: SendCarrierCheckInput;
};


export type MutationUpdateCarrierCheckArgs = {
  input?: Maybe<UpdateCarrierCheckInput>;
};


export type MutationDeleteBatchNotifyCarrierCheckFromQueueArgs = {
  input: DeleteCarrierCheckInput;
};


export type MutationCreateJudgeArgs = {
  input: CreateJudgeInput;
};


export type MutationUpdateJudgeArgs = {
  input: UpdateJudgeInput;
};


export type MutationDeleteJudgeArgs = {
  input: DeleteJudgeInput;
};


export type MutationCreateRoomsArgs = {
  input: CreateRoomsInput;
};


export type MutationCreateRoomArgs = {
  input: CreateRoomInput;
};


export type MutationUpdateRoomArgs = {
  input: UpdateRoomInput;
};


export type MutationDeleteRoomArgs = {
  input: DeleteRoomInput;
};


export type MutationCreateLocationsArgs = {
  input: CreateLocationsInput;
};


export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};


export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput;
};


export type MutationDestroyLocationArgs = {
  input: DestroyLocationInput;
};


export type MutationSoftDeleteLocationArgs = {
  input: DestroyLocationInput;
};


export type MutationCreateGroupEventArgs = {
  input: CreateGroupEventInput;
};


export type MutationUpdateGroupEventArgs = {
  input: UpdateGroupEventInput;
};


export type MutationDeleteGroupEventArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCheckInEventArgs = {
  input: CreateCheckInEventInput;
};


export type MutationUpdateCheckInEventArgs = {
  input: UpdateCheckInEventInput;
};


export type MutationDeleteCheckInEventArgs = {
  input: DeleteCheckInEventInput;
};


export type MutationCreateCourtDateArgs = {
  input: CreateCourtDateInput;
};


export type MutationUpdateCourtDateArgs = {
  input: UpdateCourtDateInput;
};


export type MutationDeleteCourtDateArgs = {
  input: DeleteCourtDateInput;
};


export type MutationCreateOfficeEventArgs = {
  input: CreateOfficeEventInput;
};


export type MutationUpdateOfficeEventArgs = {
  input: UpdateOfficeEventInput;
};


export type MutationDeleteOfficeEventArgs = {
  input: DeleteOfficeEventInput;
};


export type MutationCreateVirtualEventArgs = {
  input: CreateVirtualEventInput;
};


export type MutationDeleteVirtualEventArgs = {
  input: DeleteVirtualEventInput;
};


export type MutationUpdateVirtualEventArgs = {
  input: UpdateVirtualEventInput;
};


export type MutationUpdateVirtualEventFromClientArgs = {
  input: UpdateVirtualEventClientInput;
};


export type MutationCreateOutcomeArgs = {
  input: CreateOutcomeInput;
};


export type MutationUpdateOutcomeArgs = {
  input: UpdateOutcomeInput;
};


export type MutationDeleteOutcomeArgs = {
  attendanceId: Scalars['String'];
};


export type MutationSaveUserFeedbackArgs = {
  input: UserFeedbackCreateInput;
};


export type MutationCreateClientArgs = {
  input: CreateClientInput;
};


export type MutationUpdateClientInfoArgs = {
  input: UpdateClientInfoInput;
};


export type MutationUpdateClientMessageStatusArgs = {
  input: UpdateClientMessageStatusInput;
};


export type MutationUpdateClientCarrierArgs = {
  input: UpdateClientCarrierInput;
};


export type MutationUpdateClientUserArgs = {
  input: UpdateClientUserInput;
};


export type MutationDeleteClientArgs = {
  input: DeleteClientInput;
};


export type MutationUnlinkClientFromUserArgs = {
  clientId: Scalars['ID'];
};


export type MutationCreateBankedQuestionArgs = {
  input: QuestionBankInput;
};


export type MutationSaveCheckInResponseArgs = {
  input: CheckInResponseInput;
};


export type MutationCreateCheckInTemplateArgs = {
  input: CheckInTemplateInput;
};


export type MutationUpdateCheckInTemplateArgs = {
  input: UpdateCheckInTemplateInput;
};


export type MutationSendBatchCheckInReportsArgs = {
  organizationId: Scalars['ID'];
  fromTimestamp: Scalars['Float'];
  locale?: Maybe<Scalars['String']>;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationUpdateContactMessageStatusArgs = {
  input: UpdateContactMessageStatusInput;
};


export type MutationDeleteContactArgs = {
  input: DeleteContactInput;
};


export type MutationCreatePhoneClaimArgs = {
  input: CreatePhoneClaimInput;
};


export type MutationUpdatePhoneClaimArgs = {
  input: UpdatePhoneClaimInput;
};


export type MutationIssueVideoChatTokenArgs = {
  input: IssueVideoChatTokenInput;
};


export type MutationCreateCaseArgs = {
  input: CreateCaseInput;
};


export type MutationUpdateCaseArgs = {
  input: UpdateCaseInput;
};


export type MutationDeleteCaseArgs = {
  input: DeleteCaseInput;
};


export type MutationIssueMasqueradeTokenArgs = {
  input: IssueMasqueradeTokenInput;
};

export type NameTextInput = {
  text: Scalars['text_String_NotNull_maxLength_100'];
  locale: Scalars['locale_String_NotNull_maxLength_5'];
};

export type Node = {
  id: Scalars['ID'];
};

export type Notify = {
  __typename?: 'Notify';
  batchBadPhone: Scalars['Boolean'];
  batchIncoming: Scalars['Boolean'];
  notifyAllManagers: Scalars['Boolean'];
};

export type OfficeEvent = Event & Node & {
  __typename?: 'OfficeEvent';
  attendanceInfo?: Maybe<AttendanceInfoType>;
  attended?: Maybe<Outcome>;
  /** @deprecated Temp till auth on nested subscription data fixed (API-542) */
  attendedUid?: Maybe<Scalars['String']>;
  client: Client;
  clientFirstName?: Maybe<Scalars['String']>;
  clientId: Scalars['ID'];
  clientLastName?: Maybe<Scalars['String']>;
  closed: Scalars['Boolean'];
  cms?: Maybe<Scalars['JSONObject']>;
  createdAt?: Maybe<Scalars['Float']>;
  endTimestamp?: Maybe<Scalars['Float']>;
  groupEventId?: Maybe<Scalars['ID']>;
  /** GraphQL ID */
  id: Scalars['ID'];
  isCanceled: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  location: Location;
  outcome: Outcome;
  room?: Maybe<Room>;
  staff: StaffUser;
  staffAttending: Scalars['Boolean'];
  staffId: Scalars['ID'];
  startTimestamp: Scalars['Float'];
  /** @deprecated Use startTimestamp */
  timestamp: Scalars['Float'];
  timezoneOffset: Scalars['Int'];
  title: Scalars['String'];
  triggeredOutcome?: Maybe<Outcome>;
  /** @deprecated Temp till auth on nested subscription data fixed (API-542) */
  triggeredOutcomeUid?: Maybe<Scalars['String']>;
  triggeredTimestamp?: Maybe<Scalars['Float']>;
  type: EventType;
  updatedAt?: Maybe<Scalars['Float']>;
};

export type Organization = Node & {
  __typename?: 'Organization';
  /** GraphQL ID */
  id: Scalars['ID'];
  /**
   * Internal Firebase ID
   * @deprecated Use organizationUid
   */
  organizationId: Scalars['String'];
  organizationUid: Scalars['String'];
  /** Name of the Organization */
  name?: Maybe<Scalars['String']>;
  /** Twiio number for the Organization (managed by Uptrust) */
  sendingNumber?: Maybe<Scalars['String']>;
  sendingNumberPool?: Maybe<Array<Maybe<Scalars['String']>>>;
  supportNumber?: Maybe<Scalars['String']>;
  /** Normal voice line for the Organization (managed by the Organization) */
  officeNumber?: Maybe<Scalars['String']>;
  /** Type of Organization */
  type: Scalars['String'];
  timezone?: Maybe<Scalars['TimeZone']>;
  defaultStaffUid?: Maybe<Scalars['String']>;
  selfOnboard: Scalars['Boolean'];
  /** List of Staff members of the Organization */
  staff: StaffUserConnection;
  /** List of Templates for the Organization */
  templates: TemplateConnection;
  /** List of Clients for the Organization */
  clients: ClientConnection;
  /**
   * List of Check In Templates for the Organization
   * locale default: en
   * isActiveFilter default: ALL
   */
  checkInTemplates: CheckInTemplateConnection;
  /** List of Check In Reports for the Organization */
  checkInReports: CheckInReportConnection;
  /** List of Social Services for the Organization */
  socialServices: SocialServiceConnection;
  /** List of Outcomes for the Organization */
  outcomes: Array<Outcome>;
  /** List of Locations for the Organization */
  locations: Array<Location>;
  /** List of Cohorts for the Organization */
  cohorts: Array<Cohort>;
  /** Tells if the Organization is Closed */
  isClosed: Scalars['Boolean'];
  /** Tells the Organization Close Time */
  closedAt?: Maybe<Scalars['Float']>;
  /** Label to display in web */
  cohortLabel?: Maybe<Scalars['String']>;
  /** Maximum allowed number of staff per client */
  maxStaffPerClient: Scalars['Int'];
};


export type OrganizationStaffArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrganizationTemplatesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrganizationClientsArgs = {
  fromLastActiveTimestamp?: Maybe<Scalars['Float']>;
  closed?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrganizationCheckInTemplatesArgs = {
  locale?: Maybe<Scalars['String']>;
  isActiveFilter?: Maybe<IsActiveFilter>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrganizationCheckInReportsArgs = {
  locale?: Maybe<Scalars['String']>;
  fromTimestamp?: Maybe<Scalars['Float']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrganizationSocialServicesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  edges: Array<OrganizationEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type OrganizationEdge = {
  __typename?: 'OrganizationEdge';
  cursor: Scalars['String'];
  node?: Maybe<Organization>;
};

export type OrganizationPayload = {
  __typename?: 'OrganizationPayload';
  organization: Organization;
};

/**
 * Describes the outcome of an `Event`.
 *
 * In practice, most of the fields on this type will be `null`. **All** of `status`,
 * `outcomeUid`, and `name` will be null if any of the following is true:
 *   - The event was canceled or closed. (`CheckInEvent` only)
 *   - The event lacks a known or inferred outcome. This generally indicates that the
 *     event is in the future, or we haven't yet ingested outcome data from the org.
 *
 * If none of the above is true, which fields are non-null depends on the context in
 * which the `Outcome` appears:
 *   - For `Event.outcome`, only `status` will be non-null.
 *   - For `{CourtDateEvent|OfficeEvent|VirtualEvent}.{attended|triggeredOutcome}`,
 *     only `outcomeUid` and `name` will be non-null.
 */
export type Outcome = Node & {
  __typename?: 'Outcome';
  id: Scalars['ID'];
  status?: Maybe<OutcomeType>;
  /**
   * One of the following values, having the meaning given:
   *   - `"0"`: The client did not attend or appear at the event
   *   - `"1"`: The client did attend the event
   *   - `"2"`: The client was excused from having to attend
   *   - `"3"`: A judge vacated the client's obligation to attend
   *   - `"4"`: The event was canceled or rescheduled, but the original ocurrence
   *       remains in the org's system of record.
   *   - `"5"`: The event was removed from the org's system of record
   *   - `"6"`: A judge issued a bench warrant for the client for reasons believed
   *       to be causally related to the event (most likely, the judge is responding
   *       to a client's failure to appear for a `CourtDateEvent`).
   */
  outcomeUid?: Maybe<Scalars['String']>;
  /** @deprecated Use outcomeUid */
  outcomeId?: Maybe<Scalars['String']>;
  /**
   * A short description (2-3 words) of the `outcomeUid` suitable for display in a UI.
   * The descriptions themselves aren't strictly defined, as they can differ between
   * organizations (though not significantly).
   */
  name?: Maybe<Scalars['String']>;
};

export enum OutcomeType {
  /**
   * The client did not appear for a meeting, or check in on time.
   *
   * Note: `FAILED` doesn't imply that the client failed to meet anything that was "required" of them.
   * To determine if that's true, check `Event.isRequired`.
   */
  Failed = 'FAILED',
  /** The client attended a meeting, or checked in at all (after deadline is OK). */
  NotFailed = 'NOT_FAILED'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export type PhoneClaim = Node & {
  __typename?: 'PhoneClaim';
  id: Scalars['ID'];
  phoneNumber: Scalars['String'];
  status: PhoneClaimStatus;
  authUid: Scalars['String'];
  timestamp: Scalars['Float'];
  timestampMatched?: Maybe<Scalars['Float']>;
  timestampMissing?: Maybe<Scalars['Float']>;
  timestampError?: Maybe<Scalars['Float']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type PhoneClaimPayload = {
  __typename?: 'PhoneClaimPayload';
  phoneClaim: PhoneClaim;
};

/**
 * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * Phone claims
 * - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 */
export enum PhoneClaimStatus {
  /**
   * The pending status for a phone claim - this state indicates that it
   * is ready to be processed by the phone claim handler in message server.
   *
   * Phone claims initially start off in this status - it can also be updated to NEW
   * to indicate it should be reprocessed.
   */
  New = 'NEW',
  /** Terminal status for a processed phone claim - no clients were found. */
  Missing = 'MISSING',
  /** Terminal status for a processed phone claim - at least one client was found. */
  Matched = 'MATCHED',
  /** Terminal status for a processed phone claim - an error was encountered during processing. */
  Error = 'ERROR'
}


export enum PlatformType {
  Mobile = 'MOBILE',
  Sms = 'SMS'
}

export enum PushType {
  Event = 'EVENT',
  Message = 'MESSAGE'
}

export type Query = {
  __typename?: 'Query';
  _dummy?: Maybe<Scalars['Boolean']>;
  /**
   * Get user record
   * Returns current user if `id` is `null`
   */
  getUser: User;
  /**
   * Get Quiet Hours settings for a staff user
   *
   * TODO: Once the QuietHours object is properly linked to some future User
   *       object, this behavior should be replaced by a simple graph traversal.
   */
  getQuietHours?: Maybe<QuietHours>;
  /**
   * Get staff messages
   * - Currently defaults to last 30 days
   * - No pending messages
   */
  getStaffMessages: MessageConnection;
  /**  global id and uid arguments are allowed */
  getOrganization?: Maybe<Organization>;
  getAllOrganizations: OrganizationConnection;
  getOrganizationByPhoneNumber?: Maybe<Organization>;
  getTemplate?: Maybe<Template>;
  getOrgTemplates: TemplateConnection;
  getMessage?: Maybe<Message>;
  getAnnouncement?: Maybe<Announcement>;
  getOrgClientMessages: MessageConnection;
  /**
   * called by EndUser-Mobile instead of getOrgClientMessages w/ staffId,
   * after discovering thread IDs via `Client.messageThreads`.
   *
   * In addition to the private correspondence between the Client and
   * the StaffUsers participating in a thread, every thread also includes all
   * announcements sent in the support thread.
   */
  getThreadMessages: MessageConnection;
  getPendingMessagesByEvent: Array<Maybe<Message>>;
  getQuietHourMessagesByTimestampRange: MessageConnection;
  getAutomatedAttendanceMessages: MessageConnection;
  /** to and from parameters are optional, but if 1 is specified, both are required */
  getBatchedMessages: MessageConnection;
  readyMessageSubscription?: Maybe<Scalars['Boolean']>;
  fromEndUserMessageSubscription?: Maybe<Scalars['Boolean']>;
  /** to and from parameters are optional, but if 1 is specified, both are required */
  getBatchedCarrierChecks: CarrierCheckConnection;
  carrierCheckMessageSubscription?: Maybe<Scalars['Boolean']>;
  getGroupEvent?: Maybe<GroupEvent>;
  getEvent?: Maybe<Event>;
  getEventByCmsId?: Maybe<Event>;
  /** Trigger check-in events in queues to be pushed to PubSub */
  checkInEventSubscription?: Maybe<Scalars['Boolean']>;
  /**
   * Field which, if selected, triggers publication of queued Clients to subscribers
   * of `clientPing` and `clientWelcome`. The field's boolean value has no meaning.
   */
  clientSubscription?: Maybe<Scalars['Boolean']>;
  /** Trigger court dates in queues to be pushed to PubSub */
  courtDateEventSubscription?: Maybe<Scalars['Boolean']>;
  /** Trigger office events in queues to be pushed to PubSub */
  officeEventSubscription?: Maybe<Scalars['Boolean']>;
  /** Trigger virtual events in queues to be pushed to PubSub */
  virtualEventSubscription?: Maybe<Scalars['Boolean']>;
  getAllUserFeedback: UserFeedbackConnection;
  /** Get Client */
  getClient?: Maybe<Client>;
  getClientByCmsId?: Maybe<Client>;
  /**
   * By default, the set of selected clients is all active clients in the specified org.
   *
   * Additional fields may be used to filter down the set of org clients and/or to select
   * inactive clients instead - to be included in the result set, a client must satisfy
   * ALL constraints.
   */
  getClients: ClientConnection;
  getClientsInOrgByPhoneNumber: ClientConnection;
  /**
   * Get the from phone number associated with the given client and staff for
   * outgoing messages (to endUser).
   *
   * Not supported until the client's org is onboarded to MSPC.
   * Will return null if client/staff is not found.
   */
  getFromPhoneForOutgoingMessage?: Maybe<Scalars['String']>;
  /**
   * Return org, client/contact, and staff based on to and from phone numbers from
   * an SMS message
   */
  getFromEndUserMessageInfo: GetFromEndUserMessageInfoPayload;
  /** Get all questions from the question bank */
  getBankedQuestions: BankedQuestionConnection;
  /** Get specific question from question bank */
  getBankedQuestion?: Maybe<BankedQuestion>;
  /** Get Check In Template */
  getCheckInTemplate?: Maybe<CheckInTemplate>;
  /**
   * Get all Check In Templates for an Organization
   * locale default: en
   * isActiveFilter default: ALL
   */
  getCheckInTemplates: CheckInTemplateConnection;
  getFullCheckInTemplate?: Maybe<FullCheckInTemplate>;
  /** Get single Check In Report */
  getCheckInReport: CheckInReport;
  getContact?: Maybe<Contact>;
  getContactsInOrgByPhoneNumber: ContactConnection;
  getPhoneClaim?: Maybe<PhoneClaim>;
  getStats: Stats;
};


export type QueryGetUserArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetQuietHoursArgs = {
  staffId: Scalars['ID'];
};


export type QueryGetStaffMessagesArgs = {
  filter: MessagesFilter;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetOrganizationArgs = {
  organizationId?: Maybe<Scalars['String']>;
  organizationUid?: Maybe<Scalars['String']>;
};


export type QueryGetAllOrganizationsArgs = {
  filter?: Maybe<GetAllOrganizationsFilter>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetOrganizationByPhoneNumberArgs = {
  phoneNumber: Scalars['String'];
};


export type QueryGetTemplateArgs = {
  templateId: Scalars['ID'];
};


export type QueryGetOrgTemplatesArgs = {
  organizationId: Scalars['ID'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetMessageArgs = {
  id: Scalars['ID'];
};


export type QueryGetAnnouncementArgs = {
  id: Scalars['ID'];
};


export type QueryGetOrgClientMessagesArgs = {
  clientId: Scalars['ID'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetThreadMessagesArgs = {
  messageThreadId: Scalars['ID'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetPendingMessagesByEventArgs = {
  eventId: Scalars['ID'];
};


export type QueryGetQuietHourMessagesByTimestampRangeArgs = {
  from: Scalars['Float'];
  to: Scalars['Float'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetAutomatedAttendanceMessagesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetBatchedMessagesArgs = {
  organizationId: Scalars['ID'];
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetBatchedCarrierChecksArgs = {
  organizationId: Scalars['ID'];
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetGroupEventArgs = {
  id: Scalars['ID'];
};


export type QueryGetEventArgs = {
  eventId: Scalars['ID'];
};


export type QueryGetEventByCmsIdArgs = {
  eventType: EventType;
  cmsId: Scalars['String'];
};


export type QueryGetAllUserFeedbackArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetClientArgs = {
  id: Scalars['ID'];
};


export type QueryGetClientByCmsIdArgs = {
  cmsId: Scalars['String'];
};


export type QueryGetClientsArgs = {
  filter: GetClientsFilter;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetClientsInOrgByPhoneNumberArgs = {
  organizationId?: Maybe<Scalars['ID']>;
  phoneNumber: Scalars['String'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetFromPhoneForOutgoingMessageArgs = {
  clientId: Scalars['ID'];
  staffId: Scalars['ID'];
};


export type QueryGetFromEndUserMessageInfoArgs = {
  input: GetFromEndUserMessageInfoInput;
};


export type QueryGetBankedQuestionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetBankedQuestionArgs = {
  id: Scalars['ID'];
};


export type QueryGetCheckInTemplateArgs = {
  id: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetCheckInTemplatesArgs = {
  organizationId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
  isActiveFilter?: Maybe<IsActiveFilter>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetFullCheckInTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryGetCheckInReportArgs = {
  id: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};


export type QueryGetContactArgs = {
  id: Scalars['ID'];
};


export type QueryGetContactsInOrgByPhoneNumberArgs = {
  organizationId: Scalars['ID'];
  phoneNumber: Scalars['String'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetPhoneClaimArgs = {
  id: Scalars['ID'];
};

/** Banked Question Input */
export type QuestionBankInput = {
  questionBankUid: Scalars['questionBankUid_String_NotNull_maxLength_36'];
  type: QuestionType;
  questionText: Array<TextInput>;
  tooltip?: Maybe<Array<TextInput>>;
  isOptional: Scalars['Boolean'];
  choiceType?: Maybe<MultipleChoiceType>;
  choices?: Maybe<Array<ChoiceInput>>;
};

export type QuestionBankPayload = {
  __typename?: 'QuestionBankPayload';
  id: Scalars['ID'];
};

export enum QuestionType {
  MultipleChoice = 'MULTIPLE_CHOICE',
  Text = 'TEXT'
}

/** Settings for Quiet Hours */
export type QuietHours = Node & {
  __typename?: 'QuietHours';
  /** Autoreply text */
  autoreply: Scalars['String'];
  /** Whether to autoreply when a client texts in during quiet hours */
  autoreply_enabled: Scalars['Boolean'];
  /** Whether quiet hours is active */
  enabled: Scalars['Boolean'];
  /** Time in milliseconds that quiet hours ends (in staff's org's timezone) */
  end: Scalars['Float'];
  /** GraphQL ID */
  id: Scalars['ID'];
  /** Time in milliseconds that quiet hours begins (in staff's org's timezone) */
  start: Scalars['Float'];
  /** Whether to apply quiet hours to Saturdays and Sundays */
  weekends_off: Scalars['Boolean'];
};

export enum RecipientIdType {
  StaffId = 'STAFF_ID',
  MessageThreadId = 'MESSAGE_THREAD_ID',
  None = 'NONE'
}

export type Restitution = Node & {
  __typename?: 'Restitution';
  id: Scalars['ID'];
  type: Scalars['String'];
  /** A number of USD cents. */
  amount: Scalars['Int'];
};

export type RestitutionConnection = {
  __typename?: 'RestitutionConnection';
  edges: Array<RestitutionEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type RestitutionEdge = {
  __typename?: 'RestitutionEdge';
  cursor: Scalars['String'];
  node?: Maybe<Restitution>;
};

export enum RiskLevelType {
  Lowest = 'LOWEST',
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
  Highest = 'HIGHEST',
  NotAssessed = 'NOT_ASSESSED'
}

/**
 * The room within a particular location where an event can take place.
 *
 * E.g.,
 *   /organizations/<org>/courts/<loc>/rooms/
 *     <name1>: <description1>
 *     <name2>: <description2>
 *     <name3>: <description3>
 *     ...
 */
export type Room = Node & {
  __typename?: 'Room';
  id: Scalars['ID'];
  /**
   * The name of the room
   * Corresponds to the firebase key for this room
   * Might not be human readable
   */
  roomUid: Scalars['String'];
  /** @deprecated Use roomUid */
  roomId: Scalars['String'];
  /**
   * Human-readable description of the room.
   * Corresponds to the firebase value for this room.
   */
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
};

export type RoomConnection = {
  __typename?: 'RoomConnection';
  edges: Array<RoomEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type RoomEdge = {
  __typename?: 'RoomEdge';
  cursor: Scalars['String'];
  node?: Maybe<Room>;
};

export type RoomPayload = {
  __typename?: 'RoomPayload';
  room: Room;
};

export enum SegmentType {
  MentalHealth = 'MENTAL_HEALTH',
  Educational = 'EDUCATIONAL',
  EmploymentAssistance = 'EMPLOYMENT_ASSISTANCE',
  Healthcare = 'HEALTHCARE',
  Housing = 'HOUSING',
  ClothingAssistance = 'CLOTHING_ASSISTANCE',
  FoodAssistance = 'FOOD_ASSISTANCE',
  VeteransResources = 'VETERANS_RESOURCES',
  SupportGroups = 'SUPPORT_GROUPS',
  UtilityAssistance = 'UTILITY_ASSISTANCE',
  DetoxInpatient = 'DETOX_INPATIENT',
  DetoxOutpatient = 'DETOX_OUTPATIENT'
}

export type SendAnnouncementInput = {
  /** Send the announcement to the following IDs. */
  clientsIds: Array<Scalars['ID']>;
  body: Scalars['String'];
};

export type SendAnnouncementPayload = {
  __typename?: 'SendAnnouncementPayload';
  announcement?: Maybe<Announcement>;
};

export type SendCarrierCheckInput = {
  clientId: Scalars['ID'];
  timestamp: Scalars['Float'];
};

export type SendCarrierCheckPayload = {
  __typename?: 'SendCarrierCheckPayload';
  carrierCheck: CarrierCheck;
};

export type SendFromEndUserMessageInput = {
  clientId: Scalars['ID'];
  type: MessageType;
  timestamp: Scalars['Float'];
  batchNotify?: Maybe<Scalars['Boolean']>;
  batchNotifyTimestamp?: Maybe<Scalars['Float']>;
  body: Scalars['String'];
  contactId?: Maybe<Scalars['ID']>;
  platform: PlatformType;
  processed?: Maybe<Scalars['Boolean']>;
  sid?: Maybe<Scalars['String']>;
  fromPhoneNumber?: Maybe<Scalars['String']>;
  toPhoneNumber?: Maybe<Scalars['String']>;
  media?: Maybe<Array<MediaInput>>;
  /**
   * The recipient of the message.
   * StaffId is ignored if messageThreadId is given.
   * If staffId and messageThreadId are null, the client's primary staff user is assumed.
   */
  staffId?: Maybe<Scalars['String']>;
  /**
   * The message thread the message belongs to.
   * Staff of message is filled in from message thread.
   * If staffId and messageThreadId are null, the client's primary staff user is assumed.
   */
  messageThreadId?: Maybe<Scalars['ID']>;
};

export type SendFromEndUserMessagePayload = {
  __typename?: 'SendFromEndUserMessagePayload';
  message: FromEndUserMessage;
};

export type SendFromStaffMessageInput = {
  clientId: Scalars['ID'];
  type: MessageType;
  timestamp: Scalars['Float'];
  body?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['ID']>;
  platform?: Maybe<PlatformType>;
};

export type SendFromStaffMessagePayload = {
  __typename?: 'SendFromStaffMessagePayload';
  message: FromStaffMessage;
};

export type SmsSender = Client | Contact;

export enum SmsSenderType {
  Client = 'CLIENT',
  Contact = 'CONTACT',
  None = 'NONE'
}

export type SocialService = {
  __typename?: 'SocialService';
  id: Scalars['ID'];
  address: Scalars['String'];
  category: CategoryType;
  description: Scalars['String'];
  displayCategory: Scalars['String'];
  displaySegment?: Maybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  hours: Scalars['String'];
  name: Scalars['String'];
  note: Scalars['String'];
  organizationId: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  segment?: Maybe<SegmentType>;
  /** URL to service's site. */
  website?: Maybe<Scalars['String']>;
};

export type SocialServiceConnection = {
  __typename?: 'SocialServiceConnection';
  edges: Array<SocialServiceEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SocialServiceEdge = {
  __typename?: 'SocialServiceEdge';
  cursor: Scalars['String'];
  node?: Maybe<SocialService>;
};

export type StaffBadges = Badges & {
  __typename?: 'StaffBadges';
  conversations?: Maybe<Array<StaffConversation>>;
  openCheckIns: Scalars['Int'];
  schedule: Scalars['Int'];
};

/** The count of unread messages a staff member has from a given client. */
export type StaffConversation = {
  __typename?: 'StaffConversation';
  clientId: Scalars['ID'];
  value: Scalars['Int'];
};

export type StaffConversationInput = {
  clientId: Scalars['ID'];
  value: Scalars['Int'];
};

/**
 * See definitions here:
 *   https://www.notion.so/uptrust/Ubiquitous-Language-acaf739e07a44f058d33d48b34ac2c84
 */
export enum StaffRole {
  Supervisor = 'SUPERVISOR',
  Staff = 'STAFF',
  Support = 'SUPPORT',
  LimitedSupport = 'LIMITED_SUPPORT'
}

export type StaffUser = Node & User & {
  __typename?: 'StaffUser';
  /** Retrieve any announcements sent by this staff user */
  announcements: AnnouncementConnection;
  /** The UID of the Firebase Auth user associated with this StaffUser. */
  authUid?: Maybe<Scalars['String']>;
  badges?: Maybe<StaffBadges>;
  /** fromLastActiveTimestamp defaults to last 6 months for supervisor only */
  clients?: Maybe<ClientConnection>;
  createdAt?: Maybe<Scalars['Float']>;
  email: Scalars['String'];
  /**
   * Events are sorted by descending `timestamp` order
   *   - `timestamp` means `startTimestamp` for court, office, and virtual events and `endTimestamp` for check-in events
   */
  events: EventConnection;
  /** GraphQL ID */
  id: Scalars['ID'];
  isSupportStaff?: Maybe<Scalars['Boolean']>;
  /** Whether this user is directly assignable to clients */
  lawyer?: Maybe<Scalars['Boolean']>;
  localRole?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** Flags to modify notifications to Staff */
  notify?: Maybe<Notify>;
  officeAddress?: Maybe<Scalars['String']>;
  officeId?: Maybe<Scalars['String']>;
  officeNumber?: Maybe<Scalars['String']>;
  organization: Organization;
  /**
   * Internal Firebase ID
   * @deprecated Use organizationUid instead
   */
  organizationId: Scalars['String'];
  organizationUid: Scalars['String'];
  pushToken?: Maybe<Scalars['String']>;
  /** Retrieve any configured quiet hour settings for the staff user */
  quietHours?: Maybe<QuietHours>;
  /** Used to enable relevant features by role in Neo-Web and LaunchDarkly */
  role: StaffRole;
  /**
   * Staff ID (internal Firebase ID)
   * @deprecated Use staffUid instead
   */
  staffId: Scalars['String'];
  staffUid: Scalars['String'];
  /** Whether this user can see everyone in the org or not */
  supervisor?: Maybe<Scalars['Boolean']>;
  type: UserType;
  updatedAt?: Maybe<Scalars['Float']>;
  /** Other staff ids that are visible to user */
  visibleStaffIds?: Maybe<Array<Scalars['ID']>>;
};


export type StaffUserAnnouncementsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type StaffUserClientsArgs = {
  fromLastActiveTimestamp?: Maybe<Scalars['Float']>;
  closed?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type StaffUserEventsArgs = {
  fromLastActiveTimestamp?: Maybe<Scalars['Float']>;
  type?: Maybe<EventType>;
  eventFilter?: Maybe<EventFilter>;
  eventFilterTimestamp?: Maybe<Scalars['Float']>;
  onlyOpen?: Maybe<Scalars['Boolean']>;
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type StaffUserConnection = {
  __typename?: 'StaffUserConnection';
  edges: Array<StaffUserEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type StaffUserEdge = {
  __typename?: 'StaffUserEdge';
  cursor: Scalars['String'];
  node?: Maybe<StaffUser>;
};

export type Stats = {
  __typename?: 'Stats';
  /** Currently defaults to the last 30 days of messages */
  messages: MessageStats;
  /** Currently counts all active and inactive clients */
  clients: ClientStats;
  /** Currently defaults to the last 30 days of events. Only counts events that are not closed or canceled */
  events: EventsByTypeOutcomeStats;
};


export type StatsMessagesArgs = {
  filter: MessageStatsFilter;
};


export type StatsClientsArgs = {
  filter: ClientStatsFilter;
};


export type StatsEventsArgs = {
  filter: EventStatsFilter;
};

/**
 * The status, or state, of the message. No READY state exists in the schema because, if
 * everything's working right, it's a transient state.
 */
export enum StatusType {
  /**
   * The initial state for messages whose `timestamp` is in the future.
   * message-server is responsible for acting on--"sending"--pending messages when
   * their timestamp arrives.
   */
  Pending = 'PENDING',
  /**
   * A terminal status for messages, e.g.,
   *   - that are MessageType.REMINDERS for events which have already occurred
   *   - to clients who, at the message's scheduled send time, have no phone
   *   - to contacts who are not opted in
   *   - to inactive clients or regarding closed events
   *   - that are MessageType.OUTCOMEs too old to qualify for AAM
   *   - whose template rendering produced a nil body
   */
  Skipped = 'SKIPPED',
  /**
   * A status for messages that attempt SMS delivery. From Twilio:
   *
   * > The nearest upstream carrier accepted the message.
   */
  Sent = 'SENT',
  /**
   * A status for messages that attempt SMS delivery. From Twilio:
   *
   * > The API request to send a message was successful and the message is queued to be
   * > sent out.
   */
  Queued = 'QUEUED',
  /**
   * A status for messages to a contact or client, indicating successful delivery.
   * Consult the OutgoingMessage's `platform` field to learn which delivery platform
   * was used.
   *
   * When PlatformType.MOBILE, this means our push notification API indicated that the
   * notification was successfully delivered.
   *
   * When PlatformType.SMS, see Twilio:
   *
   * > Twilio has received confirmation of message delivery from the upstream carrier,
   * > and, where available, the destination handset.
   *
   * INCOMING messages would be set to delivered if a push succeeds.
   */
  Delivered = 'DELIVERED',
  /**
   * A status for messages that attempt SMS delivery. From Twilio:
   *
   * > Twilio has received a delivery receipt indicating that the message was not
   * > delivered. This can happen for many reasons including carrier content filtering
   * > and the availability of the destination handset.
   */
  Undelivered = 'UNDELIVERED',
  /**
   * A terminal status for messages, e.g.,
   *   - to clients or contacts who, at the message's scheduled send time, don't exist
   *   - whose template rendering threw an exception
   *   - whose template rendered an empty string after calling String.prototype.trim
   *   - that would have been double-sent
   *   - that attempt to deliver via SMS but for whom the Twilio Create Message API fails
   *   - that are MessageType.REMINDERs for clients or events which are `closed`
   *   - that are MessageType.REMINDERs or MessageType.OUTCOMEs for nonexistent events
   *   - with neither a `from` nor an associated organization that has a `sending_number`
   */
  Error = 'ERROR',
  /** The sole terminal status for PING messages, set when the task completes. */
  Handled = 'HANDLED',
  /** The initial status for INCOMING messages. */
  Received = 'RECEIVED',
  /** Message is ready to be delivered */
  Ready = 'READY'
}

export type Subscription = {
  __typename?: 'Subscription';
  _dummy?: Maybe<Scalars['Boolean']>;
  /**
   * State changes on messages between clients and the organizations serving them.
   * The intent is that this subscription can be the foundation for live-updating
   * views of message threads betweeen clients and their orgs.
   *
   * Use this subscription where the caller is a staff member supporting the given
   * clients.
   *
   * Any type or status of message may be sent down this subscription; consumers should
   * filter for what they need. The set of data currently being served by default may
   * expand in the future. Having said that, the information below is informational.
   *
   * `FromEndUserMessages` and `FromStaffMessages` are published to this subscription
   * upon creation. All types of messages are published upon update.
   *
   * A message is included only if it is between a client and their org (i.e., not
   * one of their contacts). A message _update_ is sent only if it additionally has
   * status (post-update) of `SENT`, `DELIVERED`, `RECEIVED`, `ERROR`, `HANDLED`,
   * `UNDELIVERED`, or `SKIPPED`. Any updates to `FromStaffMessage`s are also
   * included.
   */
  messagesForStaffUsers: Message;
  /**
   * Like `messagesForStaffUsers`, but for use when the caller is identically the
   * given clients. A message _update_ is sent only if it has
   * status (post-update) of `SENT`, `DELIVERED`, or `RECEIVED`.
   * Any updates to `FromStaffMessage`s are also included.
   */
  messagesForEndUsers: Message;
  /**
   * Like `messagesForStaffUsers`, but for use when emobile is using message threads.
   * A message _update_ is sent only if it has
   * status (post-update) of `SENT`, `DELIVERED`, or `RECEIVED`.
   * Any updates to `FromStaffMessage`s are also included.
   */
  messagesForThread: Message;
  /** Any type of message that is marked for the READY queue may be send down this subscription. */
  readyMessages: Message;
  /** Messages coming from end users that were stored in queue may be send down this subscription. */
  fromEndUserMessages: FromEndUserMessage;
  carrierCheckMessages: CarrierCheck;
  checkInEventCreated: CheckInEvent;
  checkInEventUpdated: CheckInEvent;
  checkInEventDeleted: CheckInEvent;
  courtDateEventCreated: CourtDateEvent;
  courtDateEventUpdated: CourtDateEvent;
  courtDateEventDeleted: CourtDateEvent;
  officeEventCreated: OfficeEvent;
  officeEventUpdated: OfficeEvent;
  officeEventDeleted: OfficeEvent;
  virtualEventCreated: VirtualEvent;
  virtualEventUpdated: VirtualEvent;
  virtualEventDeleted: VirtualEvent;
  /** Flush with `clientSubscription`. */
  clientPing: Client;
  clientWelcome: Client;
};


export type SubscriptionMessagesForStaffUsersArgs = {
  clientIds: Array<Scalars['ID']>;
};


export type SubscriptionMessagesForEndUsersArgs = {
  clientIds: Array<Scalars['ID']>;
};


export type SubscriptionMessagesForThreadArgs = {
  messageThreadId: Scalars['ID'];
};

export type Template = Node & {
  __typename?: 'Template';
  /** GraphQL ID */
  id: Scalars['ID'];
  /**
   * The Organization to which this template belongs (internal Firebase ID)
   * TODO(API-246): Deprecate and replace with organizationUid
   * @deprecated Use organizationUid
   */
  organizationId: Scalars['String'];
  organizationUid: Scalars['String'];
  /**
   * Internal Firebase ID
   * TODO(API-246): Deprecate and replace with templateUid
   * @deprecated Use templateUid
   */
  templateId: Scalars['String'];
  templateUid: Scalars['String'];
  /** List of template bodies and their respective locale codes */
  body: Array<TemplateBody>;
  /**
   * On which day this message should be sent
   *
   * - **Less than `0`** indicates that the message should be sent that many days
   *   before the associated event
   * - **Greater than `0`** indicates that the message should be sent that many days
   *   after client creation
   * - **`null`** is for templates that do not get scheduled ahead of time (e.g.
   *   `client_welcome` or `court_date_welcome`)
   */
  days?: Maybe<Scalars['Int']>;
  /**
   * The hour of the day (in the recipient's local time) at which this template
   * should be sent. **`null`** is for templates that do not get scheduled ahead of time (e.g.
   *   `client_welcome` or `court_date_welcome`).
   */
  time?: Maybe<Scalars['Int']>;
  /**
   * Whether this message should be sent to the subject of this event or to their
   * related contacts. `null` implies `false`
   */
  contact?: Maybe<Scalars['Boolean']>;
  /** Event type for reminders. */
  type?: Maybe<TemplateType>;
  /** Allows message server to switch from ts to start_timestamp when calculating a scheduled message time */
  targetStart: Scalars['Boolean'];
};

export type TemplateBody = {
  __typename?: 'TemplateBody';
  text: Scalars['String'];
  /** Values used to match Client.language: en-US, es-MX, ru-RU, zh-CH */
  locale: Scalars['String'];
};

export type TemplateBodyInput = {
  text: Scalars['String'];
  locale: Scalars['String'];
};

export type TemplateConnection = {
  __typename?: 'TemplateConnection';
  edges: Array<TemplateEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TemplateEdge = {
  __typename?: 'TemplateEdge';
  cursor: Scalars['String'];
  node?: Maybe<Template>;
};

export enum TemplateType {
  CourtDate = 'COURT_DATE',
  Office = 'OFFICE',
  Virtual = 'VIRTUAL',
  Checkin = 'CHECKIN'
}

/**
 * Terms - Requirements of a probation or parolee
 * Conditions - person must follow a list of conditions in order to avoid jail
 * Restitution - relinquishment of a benefit or the return of money or other property obtained through an improper means to the person from whom the property was taken
 */
export type Terms = Node & {
  __typename?: 'Terms';
  id: Scalars['ID'];
  endDate?: Maybe<Scalars['Float']>;
  conditions: ConditionConnection;
  restitutions: RestitutionConnection;
};

export type Text = {
  __typename?: 'Text';
  locale: Scalars['String'];
  text: Scalars['String'];
};

export type TextBankedQuestion = BankedQuestion & Node & {
  __typename?: 'TextBankedQuestion';
  id: Scalars['ID'];
  bankedQuestionUid: Scalars['String'];
  type: QuestionType;
  questionText: Array<Text>;
  tooltip?: Maybe<Array<Text>>;
  isOptional: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
};

export type TextInput = {
  locale: Scalars['locale_String_NotNull_maxLength_5'];
  text: Scalars['String'];
};

export type TextQuestion = CheckInQuestion & Node & {
  __typename?: 'TextQuestion';
  id: Scalars['ID'];
  isOptional: Scalars['Boolean'];
  order: Scalars['Int'];
  questionBankId: Scalars['ID'];
  questionText: Scalars['String'];
  tooltip?: Maybe<Scalars['String']>;
  type: QuestionType;
};

export enum TimePeriod {
  /** The interval [now, relevant time zone's soonest midnight). */
  Today = 'TODAY',
  /** The interval [now, relevant time zone's soonest midnight at least 7*24 hours from now). */
  InTheNextWeek = 'IN_THE_NEXT_WEEK'
}



export enum TransportationType {
  Drive = 'DRIVE',
  Ride = 'RIDE',
  Bus = 'BUS',
  Walk = 'WALK',
  Unknown = 'UNKNOWN'
}


/** The concrete type for messages of type `UNDEFINED`. */
export type UndefinedMessage = Message & Node & {
  __typename?: 'UndefinedMessage';
  id: Scalars['ID'];
  clientId: Scalars['ID'];
  staffId?: Maybe<Scalars['ID']>;
  staff?: Maybe<StaffUser>;
  type: MessageType;
  timestamp: Scalars['Float'];
  status: StatusType;
  batchNotify: Scalars['Boolean'];
  messageThreadId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
  platform?: Maybe<PlatformType>;
  pushOnly?: Maybe<Scalars['Boolean']>;
  pushType?: Maybe<PushType>;
  pushTitle?: Maybe<Scalars['String']>;
  pushBody?: Maybe<Scalars['String']>;
};

export type UpdateCarrierCheckInput = {
  id: Scalars['ID'];
  status?: Maybe<StatusType>;
  /** If batchNotify is set, the message will go into the BATCHED queue */
  batchNotify?: Maybe<Scalars['Boolean']>;
};

export type UpdateCarrierCheckPayload = {
  __typename?: 'UpdateCarrierCheckPayload';
  carrierCheck: CarrierCheck;
};

export type UpdateCaseInput = {
  caseId: Scalars['ID'];
  number?: Maybe<Scalars['String']>;
  open?: Maybe<Scalars['Boolean']>;
};

export type UpdateCheckInEventInput = {
  id: Scalars['ID'];
  checkInTemplateId?: Maybe<Scalars['ID']>;
  timestamp?: Maybe<Scalars['Float']>;
  startTimestamp?: Maybe<Scalars['Float']>;
  endTimestamp?: Maybe<Scalars['Float']>;
  timezoneOffset?: Maybe<Scalars['Int']>;
  closed?: Maybe<Scalars['Boolean']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  cms?: Maybe<Scalars['JSONObject']>;
  staffId?: Maybe<Scalars['ID']>;
};

export type UpdateCheckInEventPayload = {
  __typename?: 'UpdateCheckInEventPayload';
  checkInEvent: CheckInEvent;
};

export type UpdateCheckInTemplateInput = {
  id: Scalars['ID'];
  names?: Maybe<Array<CheckInTemplateNameInput>>;
  isActive?: Maybe<Scalars['Boolean']>;
  isSurvey?: Maybe<Scalars['Boolean']>;
};

export type UpdateCheckInTemplatePayload = {
  __typename?: 'UpdateCheckInTemplatePayload';
  id: Scalars['ID'];
};

export type UpdateClientCarrierInput = {
  clientId: Scalars['ID'];
  type?: Maybe<CarrierType>;
  name?: Maybe<Scalars['name_String_maxLength_200']>;
  countryCode?: Maybe<Scalars['countryCode_String_maxLength_50']>;
  nationalFormat?: Maybe<Scalars['nationalFormat_String_maxLength_50']>;
  mobileCountryCode?: Maybe<Scalars['mobileCountryCode_String_maxLength_50']>;
  mobileNetworkCode?: Maybe<Scalars['mobileNetworkCode_String_maxLength_50']>;
  number?: Maybe<Scalars['number_String_maxLength_20']>;
  error?: Maybe<Scalars['String']>;
  /** If goodToBadTimestamp field is set, all other fields will be ignored */
  goodToBadTimestamp?: Maybe<Scalars['Float']>;
};

export type UpdateClientCarrierPayload = {
  __typename?: 'UpdateClientCarrierPayload';
  clientUpdated: Scalars['Boolean'];
};

/** Boolean inputs set to null translate to false */
export type UpdateClientInfoInput = {
  id: Scalars['ID'];
  /** The client's primary assigned staff. */
  staffId?: Maybe<Scalars['ID']>;
  /** The client's non-primary assigned staff. If too many are specified, an error is given. */
  additionalStaff?: Maybe<Array<Scalars['ID']>>;
  cmsId?: Maybe<Scalars['cmsId_String_maxLength_100']>;
  cms?: Maybe<Scalars['JSONObject']>;
  firstName?: Maybe<Scalars['firstName_String_maxLength_100']>;
  lastName?: Maybe<Scalars['lastName_String_maxLength_100']>;
  phone?: Maybe<Scalars['String']>;
  language?: Maybe<LanguageType>;
  email?: Maybe<Scalars['email_String_maxLength_100']>;
  address?: Maybe<AddressInput>;
  employer?: Maybe<Scalars['employer_String_maxLength_400']>;
  gender?: Maybe<GenderType>;
  hasOpenCases?: Maybe<Scalars['Boolean']>;
  needsPing?: Maybe<Scalars['Boolean']>;
  riskLevel?: Maybe<RiskLevelType>;
  arrestDateTimestamp?: Maybe<Scalars['Float']>;
  charge?: Maybe<Scalars['charge_String_maxLength_500']>;
  dailyObligation?: Maybe<Scalars['Boolean']>;
  dailyObligationDesc?: Maybe<Scalars['dailyObligationDesc_String_maxLength_5000']>;
  dailyObligationKind?: Maybe<DailyObligationKindType>;
  noCar?: Maybe<Scalars['Boolean']>;
  noPhone?: Maybe<Scalars['Boolean']>;
  housingInsecure?: Maybe<Scalars['Boolean']>;
  needsChildCare?: Maybe<Scalars['Boolean']>;
  transportation?: Maybe<TransportationType>;
  notes?: Maybe<Scalars['String']>;
  docket?: Maybe<Scalars['docket_String_maxLength_100']>;
  upperDocket?: Maybe<Scalars['upperDocket_String_maxLength_100']>;
  closed?: Maybe<Scalars['Boolean']>;
  /** Only set when closed is true in input. If closed is false or null, it is unset */
  closedAt?: Maybe<Scalars['Float']>;
  inCustody?: Maybe<Scalars['Boolean']>;
  ethnicity?: Maybe<Scalars['ethnicity_String_maxLength_50']>;
  isAttendanceRequired?: Maybe<Scalars['Boolean']>;
  optOut?: Maybe<Scalars['Boolean']>;
  suffix?: Maybe<Scalars['String']>;
  cohortId?: Maybe<Scalars['ID']>;
};

export type UpdateClientMessageStatusInput = {
  id: Scalars['ID'];
  needsPing?: Maybe<Scalars['Boolean']>;
  lastOpenedTimestamp?: Maybe<Scalars['Float']>;
  /** If set, lastReceivedTimestamp should also be set */
  lastReceivedBody?: Maybe<Scalars['String']>;
  lastReceivedTimestamp?: Maybe<Scalars['Float']>;
  lastSend?: Maybe<Scalars['String']>;
  lastSendStatus?: Maybe<StatusType>;
  lastSendBody?: Maybe<Scalars['String']>;
  lastPlatformBody?: Maybe<Scalars['String']>;
  lastPlatformTimestamp?: Maybe<Scalars['Float']>;
  lastSendManualTimestamp?: Maybe<Scalars['Float']>;
  lastSendTimestamp?: Maybe<Scalars['Float']>;
  lastSendAutoTimestamp?: Maybe<Scalars['Float']>;
  lastActiveTimestamp?: Maybe<Scalars['Float']>;
};

export type UpdateClientUserInput = {
  clientId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type UpdateClientUserPayload = {
  __typename?: 'UpdateClientUserPayload';
  clientUpdated: Scalars['Boolean'];
};

export type UpdateCohortInput = {
  name: Array<NameTextInput>;
  cohortId: Scalars['ID'];
};

export type UpdateContactInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  optIn?: Maybe<Scalars['Boolean']>;
};

export type UpdateContactMessageStatusInput = {
  id: Scalars['ID'];
  welcomed?: Maybe<Scalars['Boolean']>;
  /** If set, lastReceivedTimestamp should also be set */
  lastReceivedBody?: Maybe<Scalars['String']>;
  lastReceivedTimestamp?: Maybe<Scalars['Float']>;
  lastSend?: Maybe<Scalars['String']>;
  lastSendStatus?: Maybe<StatusType>;
  lastSendBody?: Maybe<Scalars['String']>;
  lastSendManualTimestamp?: Maybe<Scalars['Float']>;
  lastSendTimestamp?: Maybe<Scalars['Float']>;
  lastSendAutoTimestamp?: Maybe<Scalars['Float']>;
  lastActiveTimestamp?: Maybe<Scalars['Float']>;
};

export type UpdateCourtDateInput = {
  id: Scalars['ID'];
  staffId?: Maybe<Scalars['ID']>;
  timestamp?: Maybe<Scalars['Float']>;
  startTimestamp?: Maybe<Scalars['Float']>;
  endTimestamp?: Maybe<Scalars['Float']>;
  timezoneOffset?: Maybe<Scalars['Int']>;
  closed?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['ID']>;
  roomId?: Maybe<Scalars['ID']>;
  judgeId?: Maybe<Scalars['ID']>;
  attendedId?: Maybe<Scalars['ID']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  triggeredTimestamp?: Maybe<Scalars['Float']>;
  triggeredOutcomeId?: Maybe<Scalars['ID']>;
  cms?: Maybe<Scalars['JSONObject']>;
  courtType?: Maybe<Scalars['String']>;
  courtSubtype?: Maybe<Scalars['String']>;
  caseNumber?: Maybe<Scalars['String']>;
  staffAttending?: Maybe<Scalars['Boolean']>;
};

export type UpdateCourtDatePayload = {
  __typename?: 'UpdateCourtDatePayload';
  courtDate: CourtDateEvent;
};

export type UpdateEndUserAuthInput = {
  id: Scalars['ID'];
  authUid: Scalars['ID'];
};

export type UpdateEndUserBadgesInput = {
  endUserId: Scalars['ID'];
  conversations?: Maybe<Array<EndUserConversationInput>>;
  threads?: Maybe<Array<EndUserBadgeThreadInput>>;
  openCheckIns?: Maybe<Scalars['Int']>;
  schedule?: Maybe<Scalars['Int']>;
};

export type UpdateEndUserInfoInput = {
  id: Scalars['ID'];
  address?: Maybe<AddressInput>;
  phone?: Maybe<Scalars['String']>;
  employer?: Maybe<Scalars['employer_String_maxLength_400']>;
};

export type UpdateEndUserInput = {
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  pushToken?: Maybe<Scalars['String']>;
};

export type UpdateEndUserPayload = {
  __typename?: 'UpdateEndUserPayload';
  user: EndUser;
};

export type UpdateEndUserSocialServiceInput = {
  id: Scalars['ID'];
  socialServiceId: Scalars['ID'];
  isSocialServiceFavorite: Scalars['Boolean'];
};

export type UpdateEventDetails = {
  /** Not to be used with createRoom */
  roomId?: Maybe<Scalars['ID']>;
  /** Not to be used with locationId. Expect 0 or 1 created room */
  createLocation?: Maybe<CreateLocationInput>;
  /**
   * Not to be used with roomId or createLocation.
   * Room can be created in createLocation.
   */
  createRoom?: Maybe<CreateRoomInput>;
  attendedId?: Maybe<Scalars['ID']>;
  /** Default: `true` */
  triggeredTimestamp?: Maybe<Scalars['Float']>;
  triggeredOutcomeId?: Maybe<Scalars['ID']>;
  cms?: Maybe<Scalars['JSONObject']>;
  staffAttending?: Maybe<Scalars['Boolean']>;
  checkInTemplateId?: Maybe<Scalars['ID']>;
  judgeId?: Maybe<Scalars['ID']>;
  courtType?: Maybe<Scalars['String']>;
  courtSubtype?: Maybe<Scalars['String']>;
  caseNumber?: Maybe<Scalars['String']>;
  attended?: Maybe<Scalars['Boolean']>;
};

export type UpdateFromEndUserMessageInput = {
  id: Scalars['ID'];
  processed?: Maybe<Scalars['Boolean']>;
  /**
   * If batchNotify is set and batchNotifyTimestamp is not, the message will go
   *   into the BATCHED queue.
   * If both batchNotify and batchNotifyTimestampis set, the message will go
   *   into the QUIET_HOURS queue.
   */
  batchNotify?: Maybe<Scalars['Boolean']>;
  batchNotifyTimestamp?: Maybe<Scalars['Float']>;
  pushTitle?: Maybe<Scalars['pushTitle_String_maxLength_1024']>;
  pushBody?: Maybe<Scalars['pushBody_String_maxLength_1024']>;
  status?: Maybe<StatusType>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type UpdateFromEndUserMessagePayload = {
  __typename?: 'UpdateFromEndUserMessagePayload';
  message: FromEndUserMessage;
};

export type UpdateFromPlatformMessageInput = {
  id: Scalars['ID'];
  status?: Maybe<StatusType>;
  platform?: Maybe<PlatformType>;
  twilioSid?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  /** If batchNotify is set, the message will go into the BATCHED queue */
  batchNotify?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Scalars['body_String_maxLength_2000']>;
  pushTitle?: Maybe<Scalars['pushTitle_String_maxLength_1024']>;
  pushBody?: Maybe<Scalars['pushBody_String_maxLength_1024']>;
  smsOnly?: Maybe<Scalars['Boolean']>;
};

export type UpdateFromPlatformMessagePayload = {
  __typename?: 'UpdateFromPlatformMessagePayload';
  message: BasePlatformMessage;
};

export type UpdateFromStaffMessageInput = {
  id: Scalars['ID'];
  status?: Maybe<StatusType>;
  platform?: Maybe<PlatformType>;
  twilioSid?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  /** If batchNotify is set, the message will go into the BATCHED queue */
  batchNotify?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Scalars['body_String_maxLength_2000']>;
  pushTitle?: Maybe<Scalars['pushTitle_String_maxLength_1024']>;
  pushBody?: Maybe<Scalars['pushBody_String_maxLength_1024']>;
  smsOnly?: Maybe<Scalars['Boolean']>;
};

export type UpdateFromStaffMessagePayload = {
  __typename?: 'UpdateFromStaffMessagePayload';
  message: FromStaffMessage;
};

export type UpdateGroupEventInput = {
  groupEventId: Scalars['ID'];
  eventType: EventType;
  staffId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  startTimestamp?: Maybe<Scalars['Float']>;
  endTimestamp?: Maybe<Scalars['Float']>;
  timezoneOffset?: Maybe<Scalars['Int']>;
  closed?: Maybe<Scalars['Boolean']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  /**
   * Clients that schedule for the event, deletion or addition
   * will be processed by the API
   */
  clientIds?: Maybe<Array<Scalars['ID']>>;
  eventDetails?: Maybe<UpdateEventDetails>;
};

export type UpdateGroupEventPayload = {
  __typename?: 'UpdateGroupEventPayload';
  groupEvent?: Maybe<GroupEvent>;
};

export type UpdateJudgeInput = {
  /** GraphQL ID */
  id: Scalars['ID'];
  /** The name of this Judge */
  name: Scalars['String'];
};

export type UpdateLocationInput = {
  id: Scalars['ID'];
  address?: Maybe<AddressInput>;
  providesChildCare?: Maybe<Scalars['Boolean']>;
  isExternal?: Maybe<Scalars['Boolean']>;
};

export type UpdateOfficeEventInput = {
  id: Scalars['ID'];
  staffId?: Maybe<Scalars['ID']>;
  timestamp?: Maybe<Scalars['Float']>;
  startTimestamp?: Maybe<Scalars['Float']>;
  endTimestamp?: Maybe<Scalars['Float']>;
  timezoneOffset?: Maybe<Scalars['Int']>;
  closed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['title_String_maxLength_80']>;
  /** Not to be used with createLocation */
  locationId?: Maybe<Scalars['ID']>;
  /** Not to be used with createRoom */
  roomId?: Maybe<Scalars['ID']>;
  /** Not to be used with locationId. Expect 0 or 1 created room */
  createLocation?: Maybe<CreateLocationInput>;
  /**
   * Not to be used with roomId or createLocation.
   * Room can be created in createLocation.
   */
  createRoom?: Maybe<CreateRoomInput>;
  attendedId?: Maybe<Scalars['ID']>;
  /** Default: `true` */
  isRequired?: Maybe<Scalars['Boolean']>;
  triggeredTimestamp?: Maybe<Scalars['Float']>;
  triggeredOutcomeId?: Maybe<Scalars['ID']>;
  cms?: Maybe<Scalars['JSONObject']>;
  staffAttending?: Maybe<Scalars['Boolean']>;
};

export type UpdateOfficeEventPayload = {
  __typename?: 'UpdateOfficeEventPayload';
  officeEvent: OfficeEvent;
};

export type UpdateOrganizationInput = {
  organizationId: Scalars['String'];
  name?: Maybe<Scalars['name_String_maxLength_100']>;
  sendingNumber?: Maybe<Scalars['sendingNumber_String_maxLength_20']>;
  officeNumber?: Maybe<Scalars['officeNumber_String_maxLength_20']>;
  timezone?: Maybe<Scalars['TimeZone']>;
  type?: Maybe<Scalars['type_String_maxLength_50']>;
  selfOnboard?: Maybe<Scalars['Boolean']>;
  cohortLabel?: Maybe<Scalars['cohortLabel_String_maxLength_50']>;
  defaultStaffUid?: Maybe<Scalars['defaultStaffUid_String_maxLength_80']>;
};

export type UpdateOutcomeInput = {
  attendanceId: Scalars['ID'];
  status: OutcomeType;
  name: Scalars['String'];
};

export type UpdateOutcomePayload = {
  __typename?: 'UpdateOutcomePayload';
  outcome?: Maybe<Outcome>;
};

export type UpdatePendingToReadyMessagesPayload = {
  __typename?: 'UpdatePendingToReadyMessagesPayload';
  count: Scalars['Int'];
};

export type UpdatePhoneClaimInput = {
  id: Scalars['ID'];
  status: PhoneClaimStatus;
  timestampMatched?: Maybe<Scalars['Float']>;
  timestampMissing?: Maybe<Scalars['Float']>;
  timestampError?: Maybe<Scalars['Float']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type UpdatePhoneNumbersOfOrganizationInput = {
  organizationId: Scalars['ID'];
  sendingNumber?: Maybe<Scalars['sendingNumber_String_maxLength_20']>;
  sendingNumberPool?: Maybe<Array<Scalars['String']>>;
  supportNumber?: Maybe<Scalars['supportNumber_String_maxLength_20']>;
};

export type UpdateQuietHoursInput = {
  autoreply?: Maybe<Scalars['String']>;
  autoreply_enabled?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  end?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  start?: Maybe<Scalars['Float']>;
  weekends_off?: Maybe<Scalars['Boolean']>;
};

export type UpdateQuietHoursPayload = {
  __typename?: 'UpdateQuietHoursPayload';
  quietHours: QuietHours;
};

export type UpdateRoomInput = {
  id: Scalars['ID'];
  description: Scalars['description_String_NotNull_maxLength_100'];
};

export type UpdateStaffAssignmentsInput = {
  staffId: Scalars['ID'];
  assignedStaffIds: Array<Scalars['ID']>;
};

export type UpdateStaffBadgesInput = {
  staffId: Scalars['ID'];
  conversations?: Maybe<Array<StaffConversationInput>>;
  openCheckIns?: Maybe<Scalars['Int']>;
  schedule?: Maybe<Scalars['Int']>;
};

export type UpdateStaffInput = {
  staffId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  lawyer?: Maybe<Scalars['Boolean']>;
  supervisor?: Maybe<Scalars['Boolean']>;
  officeNumber?: Maybe<Scalars['String']>;
};

export type UpdateStaffNotifyFlagsInput = {
  staffId: Scalars['ID'];
  batchBadPhone?: Maybe<Scalars['Boolean']>;
  batchIncoming?: Maybe<Scalars['Boolean']>;
  notifyAllManagers?: Maybe<Scalars['Boolean']>;
};

export type UpdateStaffPayload = {
  __typename?: 'UpdateStaffPayload';
  staff: StaffUser;
};

export type UpdateStaffPushTokenInput = {
  staffId: Scalars['ID'];
  pushToken?: Maybe<Scalars['String']>;
};

export type UpdateTemplateInput = {
  id: Scalars['ID'];
  body: Array<TemplateBodyInput>;
  days?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['Int']>;
  contact?: Maybe<Scalars['Boolean']>;
  type?: Maybe<TemplateType>;
  targetStart?: Maybe<Scalars['Boolean']>;
};

export type UpdateTemplatePayload = {
  __typename?: 'UpdateTemplatePayload';
  template: Template;
};

export type UpdateVirtualEventClientInput = {
  id: Scalars['ID'];
  /** true / false indication on if the client attended the virtual event */
  attended: Scalars['Boolean'];
};

export type UpdateVirtualEventInput = {
  id: Scalars['ID'];
  timestamp?: Maybe<Scalars['Float']>;
  /** Millisecond-precision unix timestamp. If updated, `endTimestamp` is also required. */
  startTimestamp?: Maybe<Scalars['Float']>;
  /** Millisecond-precision unix timestamp. If updated, `startTimestamp` is also required. */
  endTimestamp?: Maybe<Scalars['Float']>;
  timezoneOffset?: Maybe<Scalars['Int']>;
  closed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  /** true / false indication on if the client attended the virtual event */
  attended?: Maybe<Scalars['Boolean']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  triggeredTimestamp?: Maybe<Scalars['Float']>;
  triggeredOutcomeId?: Maybe<Scalars['ID']>;
  cms?: Maybe<Scalars['JSONObject']>;
  staffId?: Maybe<Scalars['ID']>;
};

export type UpdateVirtualEventPayload = {
  __typename?: 'UpdateVirtualEventPayload';
  virtualEvent: VirtualEvent;
};

export type User = {
  id: Scalars['ID'];
  type: UserType;
};

export type UserFeedback = {
  __typename?: 'UserFeedback';
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  eventId: Scalars['ID'];
  userType: UserType;
  feature: FeatureForFeedback;
  isPositive: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
};

export type UserFeedbackConnection = {
  __typename?: 'UserFeedbackConnection';
  edges: Array<UserFeedbackEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UserFeedbackCreateInput = {
  /** Feedback is tied to a particular event */
  eventId: Scalars['ID'];
  userType: UserType;
  feature: FeatureForFeedback;
  isPositive: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
};

export type UserFeedbackEdge = {
  __typename?: 'UserFeedbackEdge';
  cursor: Scalars['String'];
  node?: Maybe<UserFeedback>;
};

export type UserFeedbackPayload = {
  __typename?: 'UserFeedbackPayload';
  userFeedback: UserFeedback;
};

export enum UserType {
  Staff = 'STAFF',
  Enduser = 'ENDUSER'
}

export type VirtualEvent = Event & Node & {
  __typename?: 'VirtualEvent';
  attendanceInfo?: Maybe<AttendanceInfoType>;
  /** See `type Outcome` for the semantics here. */
  attended?: Maybe<Outcome>;
  /** @deprecated Temp till auth on nested subscription data fixed (API-542) */
  attendedUid?: Maybe<Scalars['String']>;
  client: Client;
  clientFirstName?: Maybe<Scalars['String']>;
  clientId: Scalars['ID'];
  clientLastName?: Maybe<Scalars['String']>;
  closed: Scalars['Boolean'];
  cms?: Maybe<Scalars['JSONObject']>;
  createdAt?: Maybe<Scalars['Float']>;
  endTimestamp: Scalars['Float'];
  groupEventId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isCanceled: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
  /**
   * `Outcome.status` inferred as `OutcomeType.Failed` if outcome information
   * is missing and we are past the end time for this event.
   *
   * See `type Outcome` for additional semantics here.
   */
  outcome: Outcome;
  /** The staff user with whom the client is meeting. */
  staff: StaffUser;
  staffAttending: Scalars['Boolean'];
  staffId: Scalars['ID'];
  startTimestamp: Scalars['Float'];
  /** @deprecated Use startTimestamp */
  timestamp: Scalars['Float'];
  timezoneOffset: Scalars['Int'];
  /** Human-friendly name for the event */
  title: Scalars['String'];
  triggeredOutcome?: Maybe<Outcome>;
  /** @deprecated Temp till auth on nested subscription data fixed (API-542) */
  triggeredOutcomeUid?: Maybe<Scalars['String']>;
  triggeredTimestamp?: Maybe<Scalars['Float']>;
  type: EventType;
  updatedAt?: Maybe<Scalars['Float']>;
};




















































export type AttendanceDonutStatsFragment = (
  { __typename?: 'Stats' }
  & { attendanceDonutEvents: (
    { __typename?: 'EventsByTypeOutcomeStats' }
    & { totalEvents: (
      { __typename?: 'EventOutcomeStats' }
      & Pick<EventOutcomeStats, 'attended' | 'missed' | 'unknown'>
    ) }
  ) }
);

export type AttendanceTidbitsStatsFragment = (
  { __typename?: 'Stats' }
  & { attendanceTidbitsEvents: (
    { __typename?: 'EventsByTypeOutcomeStats' }
    & { courtDate: (
      { __typename?: 'EventOutcomeStats' }
      & Pick<EventOutcomeStats, 'attended' | 'total'>
    ), office: (
      { __typename?: 'EventOutcomeStats' }
      & Pick<EventOutcomeStats, 'attended' | 'total'>
    ), virtual: (
      { __typename?: 'EventOutcomeStats' }
      & Pick<EventOutcomeStats, 'attended' | 'total'>
    ), checkIn: (
      { __typename?: 'EventOutcomeStats' }
      & Pick<EventOutcomeStats, 'attended' | 'total'>
    ) }
  ) }
);

export type ClientDetailsFragment = (
  { __typename?: 'Client' }
  & Pick<Client, 'id' | 'firstName' | 'lastName' | 'phone' | 'email' | 'gender' | 'language' | 'riskLevel' | 'cmsId' | 'noPhone' | 'noCar' | 'housingInsecure' | 'needsChildCare' | 'inCustody' | 'employer' | 'clientUpdatedPhone' | 'clientUpdatedAddress' | 'clientUpdatedEmployer' | 'docket' | 'upperDocket'>
  & { staff?: Maybe<(
    { __typename?: 'StaffUser' }
    & Pick<StaffUser, 'id' | 'name'>
  )>, cases: (
    { __typename?: 'CaseConnection' }
    & { edges: Array<(
      { __typename?: 'CaseEdge' }
      & { node?: Maybe<(
        { __typename?: 'Case' }
        & Pick<Case, 'id' | 'number' | 'open'>
      )> }
    )> }
  ), cohort?: Maybe<(
    { __typename?: 'Cohort' }
    & Pick<Cohort, 'id'>
    & { name: Array<(
      { __typename?: 'Text' }
      & Pick<Text, 'locale' | 'text'>
    )> }
  )>, terms?: Maybe<(
    { __typename?: 'Terms' }
    & Pick<Terms, 'id' | 'endDate'>
    & { restitutions: (
      { __typename?: 'RestitutionConnection' }
      & { edges: Array<(
        { __typename?: 'RestitutionEdge' }
        & { node?: Maybe<(
          { __typename?: 'Restitution' }
          & Pick<Restitution, 'id' | 'amount' | 'type'>
        )> }
      )> }
    ), conditions: (
      { __typename?: 'ConditionConnection' }
      & { edges: Array<(
        { __typename?: 'ConditionEdge' }
        & { node?: Maybe<(
          { __typename?: 'Condition' }
          & Pick<Condition, 'id' | 'description'>
        )> }
      )> }
    ) }
  )>, address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zipCode'>
  )> }
);

export type EngagementTidbitsStatsFragment = (
  { __typename?: 'Stats' }
  & { engagementTidbitDeliveredReminders: (
    { __typename?: 'MessageStats' }
    & Pick<MessageStats, 'fromPlatform'>
  ), engagementTidbitAllReminders: (
    { __typename?: 'MessageStats' }
    & Pick<MessageStats, 'fromPlatform'>
  ), engagementTidbitClients: (
    { __typename?: 'ClientStats' }
    & Pick<ClientStats, 'hasMobileApp' | 'reachableBySms' | 'unreachable' | 'sentMessage' | 'total'>
  ) }
);

export type EngagementTripleStackStatsFragment = (
  { __typename?: 'Stats' }
  & { engagementTripleStackMessages: (
    { __typename?: 'MessageStats' }
    & Pick<MessageStats, 'fromStaff' | 'fromEndUser' | 'fromPlatform'>
  ) }
);

export type FilterFieldsFragment = (
  { __typename?: 'Client' }
  & Pick<Client, 'lastOpenedTimestamp' | 'lastReceivedTimestamp' | 'lastSendAutoTimestamp' | 'lastSendTimestamp' | 'activity' | 'alerts' | 'status' | 'riskLevel' | 'cohortId' | 'staffId' | 'hasMobileApp' | 'inCustody' | 'noPhone' | 'phone'>
);

export type GetClientDisplayNameFragment = (
  { __typename?: 'Client' }
  & Pick<Client, 'displayName'>
);

export type GetClientInformationFragment = (
  { __typename?: 'Client' }
  & Pick<Client, 'displayName' | 'phone'>
  & { staff?: Maybe<(
    { __typename?: 'StaffUser' }
    & Pick<StaffUser, 'id'>
  )> }
);

export type IsSupportStaffFragment = (
  { __typename?: 'StaffUser' }
  & Pick<StaffUser, 'supervisor' | 'lawyer' | 'visibleStaffIds' | 'isSupportStaff'>
);

export type StaffVirtualEventsFragment = (
  { __typename?: 'StaffUser' }
  & { events: (
    { __typename?: 'EventConnection' }
    & { edges: Array<(
      { __typename?: 'EventEdge' }
      & { node?: Maybe<(
        { __typename?: 'CheckInEvent' }
        & Pick<CheckInEvent, 'id' | 'type' | 'startTimestamp' | 'endTimestamp' | 'title' | 'clientFirstName' | 'clientLastName' | 'staffId'>
      ) | (
        { __typename?: 'CourtDateEvent' }
        & Pick<CourtDateEvent, 'id' | 'type' | 'startTimestamp' | 'endTimestamp' | 'title' | 'clientFirstName' | 'clientLastName' | 'staffId'>
      ) | (
        { __typename?: 'OfficeEvent' }
        & Pick<OfficeEvent, 'id' | 'type' | 'startTimestamp' | 'endTimestamp' | 'title' | 'clientFirstName' | 'clientLastName' | 'staffId'>
      ) | (
        { __typename?: 'VirtualEvent' }
        & Pick<VirtualEvent, 'id' | 'type' | 'startTimestamp' | 'endTimestamp' | 'title' | 'clientFirstName' | 'clientLastName' | 'staffId'>
      )> }
    )> }
  ) }
);

export type StatusBarInfoFragment = (
  { __typename?: 'Client' }
  & Pick<Client, 'closedAt' | 'hasMobileApp' | 'lastSend' | 'noPhone' | 'closed'>
  & { carrier?: Maybe<(
    { __typename?: 'Carrier' }
    & Pick<Carrier, 'type' | 'error'>
  )> }
);

export type CreateClientMutationVariables = Exact<{
  input: CreateClientInput;
}>;


export type CreateClientMutation = (
  { __typename?: 'Mutation' }
  & { createClient: (
    { __typename?: 'ClientPayload' }
    & { client: (
      { __typename?: 'Client' }
      & Pick<Client, 'id' | 'firstName' | 'lastName'>
    ) }
  ) }
);

export type CreateContactMutationVariables = Exact<{
  input: CreateContactInput;
}>;


export type CreateContactMutation = (
  { __typename?: 'Mutation' }
  & { createContact: (
    { __typename?: 'ContactPayload' }
    & { contact: (
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'name' | 'notes' | 'phone' | 'optIn'>
    ) }
  ) }
);

export type CreateCheckInEventMutationVariables = Exact<{
  input: CreateCheckInEventInput;
}>;


export type CreateCheckInEventMutation = (
  { __typename?: 'Mutation' }
  & { createCheckInEvent: (
    { __typename?: 'CreateCheckInEventPayload' }
    & { checkInEvent: (
      { __typename?: 'CheckInEvent' }
      & Pick<CheckInEvent, 'id'>
    ) }
  ) }
);

export type CreateCourtDateMutationVariables = Exact<{
  input: CreateCourtDateInput;
}>;


export type CreateCourtDateMutation = (
  { __typename?: 'Mutation' }
  & { createCourtDate: (
    { __typename?: 'CreateCourtDatePayload' }
    & { courtDate: (
      { __typename?: 'CourtDateEvent' }
      & Pick<CourtDateEvent, 'id'>
    ) }
  ) }
);

export type CreateOfficeEventMutationVariables = Exact<{
  input: CreateOfficeEventInput;
}>;


export type CreateOfficeEventMutation = (
  { __typename?: 'Mutation' }
  & { createOfficeEvent: (
    { __typename?: 'CreateOfficeEventPayload' }
    & { officeEvent: (
      { __typename?: 'OfficeEvent' }
      & Pick<OfficeEvent, 'id'>
    ) }
  ) }
);

export type CreateVirtualEventMutationVariables = Exact<{
  input: CreateVirtualEventInput;
}>;


export type CreateVirtualEventMutation = (
  { __typename?: 'Mutation' }
  & { createVirtualEvent: (
    { __typename?: 'CreateVirtualEventPayload' }
    & { virtualEvent: (
      { __typename?: 'VirtualEvent' }
      & Pick<VirtualEvent, 'id'>
    ) }
  ) }
);

export type CreateGroupEventMutationVariables = Exact<{
  input: CreateGroupEventInput;
}>;


export type CreateGroupEventMutation = (
  { __typename?: 'Mutation' }
  & { createGroupEvent?: Maybe<(
    { __typename?: 'CreateGroupEventPayload' }
    & { groupEvent?: Maybe<(
      { __typename?: 'GroupEvent' }
      & Pick<GroupEvent, 'id'>
    )> }
  )> }
);

export type DeleteCheckInEventMutationVariables = Exact<{
  input: DeleteCheckInEventInput;
}>;


export type DeleteCheckInEventMutation = (
  { __typename?: 'Mutation' }
  & { deleteCheckInEvent: (
    { __typename?: 'DeleteCheckInEventPayload' }
    & { checkInEvent: (
      { __typename?: 'CheckInEvent' }
      & Pick<CheckInEvent, 'id'>
    ) }
  ) }
);

export type DeleteCourtDateMutationVariables = Exact<{
  input: DeleteCourtDateInput;
}>;


export type DeleteCourtDateMutation = (
  { __typename?: 'Mutation' }
  & { deleteCourtDate: (
    { __typename?: 'DeleteCourtDatePayload' }
    & { courtDate: (
      { __typename?: 'CourtDateEvent' }
      & Pick<CourtDateEvent, 'id'>
    ) }
  ) }
);

export type DeleteOfficeEventMutationVariables = Exact<{
  input: DeleteOfficeEventInput;
}>;


export type DeleteOfficeEventMutation = (
  { __typename?: 'Mutation' }
  & { deleteOfficeEvent: (
    { __typename?: 'DeleteOfficeEventPayload' }
    & { officeEvent: (
      { __typename?: 'OfficeEvent' }
      & Pick<OfficeEvent, 'id'>
    ) }
  ) }
);

export type DeleteVirtualEventMutationVariables = Exact<{
  input: DeleteVirtualEventInput;
}>;


export type DeleteVirtualEventMutation = (
  { __typename?: 'Mutation' }
  & { deleteVirtualEvent: (
    { __typename?: 'DeleteVirtualEventPayload' }
    & { virtualEvent: (
      { __typename?: 'VirtualEvent' }
      & Pick<VirtualEvent, 'id'>
    ) }
  ) }
);

export type DeleteGroupEventMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteGroupEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGroupEvent'>
);

export type IssueVideoChatTokenMutationVariables = Exact<{
  input: IssueVideoChatTokenInput;
}>;


export type IssueVideoChatTokenMutation = (
  { __typename?: 'Mutation' }
  & { issueVideoChatToken: (
    { __typename?: 'IssueVideoChatTokenPayload' }
    & Pick<IssueVideoChatTokenPayload, 'accessToken'>
  ) }
);

export type IssueMasqueradeTokenMutationVariables = Exact<{
  input: IssueMasqueradeTokenInput;
}>;


export type IssueMasqueradeTokenMutation = (
  { __typename?: 'Mutation' }
  & { issueMasqueradeToken: (
    { __typename?: 'IssueMasqueradeTokenPayload' }
    & Pick<IssueMasqueradeTokenPayload, 'token'>
  ) }
);

export type CreateQuietHoursMutationVariables = Exact<{
  quietHours: CreateQuietHoursInput;
}>;


export type CreateQuietHoursMutation = (
  { __typename?: 'Mutation' }
  & { createQuietHours: (
    { __typename?: 'CreateQuietHoursPayload' }
    & { quietHours: (
      { __typename?: 'QuietHours' }
      & Pick<QuietHours, 'enabled' | 'end' | 'start' | 'autoreply_enabled' | 'autoreply' | 'weekends_off' | 'id'>
    ) }
  ) }
);

export type UpdateQuietHoursMutationVariables = Exact<{
  quietHours: UpdateQuietHoursInput;
}>;


export type UpdateQuietHoursMutation = (
  { __typename?: 'Mutation' }
  & { updateQuietHours: (
    { __typename?: 'UpdateQuietHoursPayload' }
    & { quietHours: (
      { __typename?: 'QuietHours' }
      & Pick<QuietHours, 'enabled' | 'end' | 'start' | 'autoreply_enabled' | 'autoreply' | 'weekends_off' | 'id'>
    ) }
  ) }
);

export type SendAnnouncementMutationVariables = Exact<{
  input: SendAnnouncementInput;
}>;


export type SendAnnouncementMutation = (
  { __typename?: 'Mutation' }
  & { sendAnnouncement: (
    { __typename?: 'SendAnnouncementPayload' }
    & { announcement?: Maybe<(
      { __typename?: 'Announcement' }
      & Pick<Announcement, 'id'>
    )> }
  ) }
);

export type SendFromStaffMessageMutationVariables = Exact<{
  input: SendFromStaffMessageInput;
}>;


export type SendFromStaffMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendFromStaffMessage: (
    { __typename?: 'SendFromStaffMessagePayload' }
    & { message: (
      { __typename?: 'FromStaffMessage' }
      & Pick<FromStaffMessage, 'id' | 'body'>
    ) }
  ) }
);

export type UpdateClientMutationVariables = Exact<{
  input: UpdateClientInfoInput;
}>;


export type UpdateClientMutation = (
  { __typename?: 'Mutation' }
  & { updateClientInfo: (
    { __typename?: 'ClientPayload' }
    & { client: (
      { __typename?: 'Client' }
      & ClientDetailsFragment
    ) }
  ) }
);

export type UpdateClientClosedMutationVariables = Exact<{
  input: UpdateClientInfoInput;
}>;


export type UpdateClientClosedMutation = (
  { __typename?: 'Mutation' }
  & { updateClientInfo: (
    { __typename?: 'ClientPayload' }
    & { client: (
      { __typename?: 'Client' }
      & Pick<Client, 'id' | 'closed'>
    ) }
  ) }
);

export type UpdateContactMutationVariables = Exact<{
  input: UpdateContactInput;
}>;


export type UpdateContactMutation = (
  { __typename?: 'Mutation' }
  & { updateContact: (
    { __typename?: 'ContactPayload' }
    & { contact: (
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'name' | 'notes' | 'phone' | 'optIn'>
    ) }
  ) }
);

export type UpdateCheckInEventMutationVariables = Exact<{
  input: UpdateCheckInEventInput;
}>;


export type UpdateCheckInEventMutation = (
  { __typename?: 'Mutation' }
  & { updateCheckInEvent: (
    { __typename?: 'UpdateCheckInEventPayload' }
    & { checkInEvent: (
      { __typename?: 'CheckInEvent' }
      & Pick<CheckInEvent, 'id'>
    ) }
  ) }
);

export type UpdateCourtDateMutationVariables = Exact<{
  input: UpdateCourtDateInput;
}>;


export type UpdateCourtDateMutation = (
  { __typename?: 'Mutation' }
  & { updateCourtDate: (
    { __typename?: 'UpdateCourtDatePayload' }
    & { courtDate: (
      { __typename?: 'CourtDateEvent' }
      & Pick<CourtDateEvent, 'id'>
    ) }
  ) }
);

export type UpdateOfficeEventMutationVariables = Exact<{
  input: UpdateOfficeEventInput;
}>;


export type UpdateOfficeEventMutation = (
  { __typename?: 'Mutation' }
  & { updateOfficeEvent: (
    { __typename?: 'UpdateOfficeEventPayload' }
    & { officeEvent: (
      { __typename?: 'OfficeEvent' }
      & Pick<OfficeEvent, 'id'>
    ) }
  ) }
);

export type UpdateVirtualEventMutationVariables = Exact<{
  input: UpdateVirtualEventInput;
}>;


export type UpdateVirtualEventMutation = (
  { __typename?: 'Mutation' }
  & { updateVirtualEvent: (
    { __typename?: 'UpdateVirtualEventPayload' }
    & { virtualEvent: (
      { __typename?: 'VirtualEvent' }
      & Pick<VirtualEvent, 'id'>
    ) }
  ) }
);

export type UpdateGroupEventMutationVariables = Exact<{
  input: UpdateGroupEventInput;
}>;


export type UpdateGroupEventMutation = (
  { __typename?: 'Mutation' }
  & { updateGroupEvent?: Maybe<(
    { __typename?: 'UpdateGroupEventPayload' }
    & { groupEvent?: Maybe<(
      { __typename?: 'GroupEvent' }
      & Pick<GroupEvent, 'id'>
    )> }
  )> }
);

export type UpdateCourtDateAttendanceMutationVariables = Exact<{
  id: Scalars['ID'];
  attendedId?: Maybe<Scalars['ID']>;
}>;


export type UpdateCourtDateAttendanceMutation = (
  { __typename?: 'Mutation' }
  & { updateCourtDate: (
    { __typename?: 'UpdateCourtDatePayload' }
    & { courtDate: (
      { __typename?: 'CourtDateEvent' }
      & Pick<CourtDateEvent, 'id'>
      & { attended?: Maybe<(
        { __typename?: 'Outcome' }
        & Pick<Outcome, 'id' | 'outcomeId'>
      )>, outcome: (
        { __typename?: 'Outcome' }
        & Pick<Outcome, 'outcomeUid' | 'status'>
      ) }
    ) }
  ) }
);

export type UpdateOfficeEventAttendanceMutationVariables = Exact<{
  id: Scalars['ID'];
  attendedId?: Maybe<Scalars['ID']>;
}>;


export type UpdateOfficeEventAttendanceMutation = (
  { __typename?: 'Mutation' }
  & { updateOfficeEvent: (
    { __typename?: 'UpdateOfficeEventPayload' }
    & { officeEvent: (
      { __typename?: 'OfficeEvent' }
      & Pick<OfficeEvent, 'id'>
      & { attended?: Maybe<(
        { __typename?: 'Outcome' }
        & Pick<Outcome, 'id' | 'outcomeId'>
      )>, outcome: (
        { __typename?: 'Outcome' }
        & Pick<Outcome, 'outcomeUid' | 'status'>
      ) }
    ) }
  ) }
);

export type UpdateLastOpenedTimestampMutationVariables = Exact<{
  input: UpdateClientMessageStatusInput;
}>;


export type UpdateLastOpenedTimestampMutation = (
  { __typename?: 'Mutation' }
  & { updateClientMessageStatus: (
    { __typename?: 'ClientPayload' }
    & { client: (
      { __typename?: 'Client' }
      & Pick<Client, 'id' | 'lastOpenedTimestamp'>
    ) }
  ) }
);

export type UpdateClientNotesMutationVariables = Exact<{
  input: UpdateClientInfoInput;
}>;


export type UpdateClientNotesMutation = (
  { __typename?: 'Mutation' }
  & { updateClientInfo: (
    { __typename?: 'ClientPayload' }
    & { client: (
      { __typename?: 'Client' }
      & Pick<Client, 'id' | 'notes'>
    ) }
  ) }
);

export type CheckAnnouncementSendStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CheckAnnouncementSendStatusQuery = (
  { __typename?: 'Query' }
  & { getAnnouncement?: Maybe<(
    { __typename?: 'Announcement' }
    & Pick<Announcement, 'id' | 'timestamp'>
    & { pendingMessages: (
      { __typename?: 'MessageConnection' }
      & Pick<MessageConnection, 'totalCount'>
    ), sentMessages: (
      { __typename?: 'MessageConnection' }
      & Pick<MessageConnection, 'totalCount'>
    ), errorMessages: (
      { __typename?: 'MessageConnection' }
      & Pick<MessageConnection, 'totalCount'>
    ) }
  )> }
);

export type GetAnnouncementDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetAnnouncementDetailsQuery = (
  { __typename?: 'Query' }
  & { getAnnouncement?: Maybe<(
    { __typename?: 'Announcement' }
    & Pick<Announcement, 'id' | 'timestamp' | 'body'>
    & { sentMessages: (
      { __typename?: 'MessageConnection' }
      & Pick<MessageConnection, 'totalCount'>
    ), errorMessages: (
      { __typename?: 'MessageConnection' }
      & Pick<MessageConnection, 'totalCount'>
      & { edges: Array<(
        { __typename?: 'MessageEdge' }
        & { node?: Maybe<(
          { __typename?: 'AutomatedAttendanceMessage' }
          & Pick<AutomatedAttendanceMessage, 'id' | 'clientId'>
        ) | (
          { __typename?: 'FromEndUserMessage' }
          & Pick<FromEndUserMessage, 'id' | 'clientId'>
        ) | (
          { __typename?: 'FromPlatformEventMessage' }
          & Pick<FromPlatformEventMessage, 'id' | 'clientId'>
        ) | (
          { __typename?: 'FromPlatformMessage' }
          & Pick<FromPlatformMessage, 'id' | 'clientId'>
        ) | (
          { __typename?: 'FromStaffMessage' }
          & Pick<FromStaffMessage, 'id' | 'clientId'>
        ) | (
          { __typename?: 'UndefinedMessage' }
          & Pick<UndefinedMessage, 'id' | 'clientId'>
        )> }
      )> }
    ) }
  )> }
);

export type GetApiClientsListQueryVariables = Exact<{
  filter: GetClientsFilter;
}>;


export type GetApiClientsListQuery = (
  { __typename?: 'Query' }
  & { getClients: (
    { __typename?: 'ClientConnection' }
    & { edges: Array<(
      { __typename?: 'ClientEdge' }
      & { node?: Maybe<(
        { __typename?: 'Client' }
        & Pick<Client, 'id'>
      )> }
    )> }
  ) }
);

export type GetAttendanceStatsEventsQueryVariables = Exact<{
  lastActiveTimestamp: Scalars['Float'];
  startDate: Scalars['Float'];
}>;


export type GetAttendanceStatsEventsQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'EndUser' }
    & Pick<EndUser, 'id' | 'type'>
  ) | (
    { __typename?: 'StaffUser' }
    & Pick<StaffUser, 'id' | 'type'>
    & { events: (
      { __typename?: 'EventConnection' }
      & { edges: Array<(
        { __typename?: 'EventEdge' }
        & { node?: Maybe<(
          { __typename?: 'CheckInEvent' }
          & Pick<CheckInEvent, 'id' | 'startTimestamp' | 'endTimestamp' | 'type' | 'title' | 'clientFirstName' | 'clientLastName' | 'staffId' | 'closed' | 'attendanceInfo'>
          & { outcome: (
            { __typename?: 'Outcome' }
            & Pick<Outcome, 'outcomeUid' | 'status'>
          ) }
        ) | (
          { __typename?: 'CourtDateEvent' }
          & Pick<CourtDateEvent, 'id' | 'startTimestamp' | 'endTimestamp' | 'type' | 'title' | 'clientFirstName' | 'clientLastName' | 'staffId' | 'closed' | 'attendanceInfo'>
          & { outcome: (
            { __typename?: 'Outcome' }
            & Pick<Outcome, 'outcomeUid' | 'status'>
          ) }
        ) | (
          { __typename?: 'OfficeEvent' }
          & Pick<OfficeEvent, 'id' | 'startTimestamp' | 'endTimestamp' | 'type' | 'title' | 'clientFirstName' | 'clientLastName' | 'staffId' | 'closed' | 'attendanceInfo'>
          & { outcome: (
            { __typename?: 'Outcome' }
            & Pick<Outcome, 'outcomeUid' | 'status'>
          ) }
        ) | (
          { __typename?: 'VirtualEvent' }
          & Pick<VirtualEvent, 'id' | 'startTimestamp' | 'endTimestamp' | 'type' | 'title' | 'clientFirstName' | 'clientLastName' | 'staffId' | 'closed' | 'attendanceInfo'>
          & { outcome: (
            { __typename?: 'Outcome' }
            & Pick<Outcome, 'outcomeUid' | 'status'>
          ) }
        )> }
      )> }
    ) }
  ) }
);

export type GetCheckInReportQueryVariables = Exact<{
  id: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
}>;


export type GetCheckInReportQuery = (
  { __typename?: 'Query' }
  & { getCheckInReport: (
    { __typename?: 'CheckInReport' }
    & Pick<CheckInReport, 'id' | 'timestamp' | 'name'>
    & { client: (
      { __typename?: 'Client' }
      & Pick<Client, 'firstName' | 'lastName'>
    ), pages: Array<(
      { __typename?: 'CheckInReportPage' }
      & Pick<CheckInReportPage, 'id' | 'pageNumber'>
      & { responses: Array<(
        { __typename?: 'CheckInReportResponse' }
        & Pick<CheckInReportResponse, 'id' | 'questionText' | 'answerText' | 'order' | 'sendAlert' | 'needsExplanation' | 'explanationText'>
      )> }
    )> }
  ) }
);

export type GetCheckInTemplatesQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  isActiveFilter?: Maybe<IsActiveFilter>;
}>;


export type GetCheckInTemplatesQuery = (
  { __typename?: 'Query' }
  & { getCheckInTemplates: (
    { __typename?: 'CheckInTemplateConnection' }
    & { edges: Array<(
      { __typename?: 'CheckInTemplateEdge' }
      & { node?: Maybe<(
        { __typename?: 'CheckInTemplate' }
        & Pick<CheckInTemplate, 'id' | 'staffDisplayName'>
      )> }
    )> }
  ) }
);

export type GetClientAssignedStaffQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetClientAssignedStaffQuery = (
  { __typename?: 'Query' }
  & { getClient?: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'staffId'>
    & { assignedStaff: Array<(
      { __typename?: 'StaffUser' }
      & Pick<StaffUser, 'id' | 'name'>
    )> }
  )> }
);

export type GetClientByCmsIdQueryVariables = Exact<{
  cmsId: Scalars['String'];
}>;


export type GetClientByCmsIdQuery = (
  { __typename?: 'Query' }
  & { getClientByCmsId?: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'cmsId'>
  )> }
);

export type GetClientDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetClientDetailsQuery = (
  { __typename?: 'Query' }
  & { getClient?: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'firstName' | 'lastName' | 'phone' | 'email' | 'gender' | 'language' | 'riskLevel' | 'cmsId' | 'noPhone' | 'noCar' | 'housingInsecure' | 'needsChildCare' | 'inCustody' | 'employer' | 'clientUpdatedPhone' | 'clientUpdatedAddress' | 'clientUpdatedEmployer' | 'docket' | 'upperDocket'>
    & { staff?: Maybe<(
      { __typename?: 'StaffUser' }
      & Pick<StaffUser, 'id' | 'name'>
    )>, cases: (
      { __typename?: 'CaseConnection' }
      & { edges: Array<(
        { __typename?: 'CaseEdge' }
        & { node?: Maybe<(
          { __typename?: 'Case' }
          & Pick<Case, 'id' | 'number' | 'open'>
        )> }
      )> }
    ), contacts: (
      { __typename?: 'ContactConnection' }
      & { edges: Array<(
        { __typename?: 'ContactEdge' }
        & { node?: Maybe<(
          { __typename?: 'Contact' }
          & Pick<Contact, 'id' | 'name' | 'phone' | 'notes' | 'optIn'>
        )> }
      )> }
    ), cohort?: Maybe<(
      { __typename?: 'Cohort' }
      & Pick<Cohort, 'id'>
      & { name: Array<(
        { __typename?: 'Text' }
        & Pick<Text, 'locale' | 'text'>
      )> }
    )>, terms?: Maybe<(
      { __typename?: 'Terms' }
      & Pick<Terms, 'id' | 'endDate'>
      & { restitutions: (
        { __typename?: 'RestitutionConnection' }
        & { edges: Array<(
          { __typename?: 'RestitutionEdge' }
          & { node?: Maybe<(
            { __typename?: 'Restitution' }
            & Pick<Restitution, 'id' | 'amount' | 'type'>
          )> }
        )> }
      ), conditions: (
        { __typename?: 'ConditionConnection' }
        & { edges: Array<(
          { __typename?: 'ConditionEdge' }
          & { node?: Maybe<(
            { __typename?: 'Condition' }
            & Pick<Condition, 'id' | 'description'>
          )> }
        )> }
      ) }
    )>, address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zipCode'>
    )> }
    & StatusBarInfoFragment
  )> }
);

export type GetClientMessagesQueryVariables = Exact<{
  id: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type GetClientMessagesQuery = (
  { __typename?: 'Query' }
  & { getClient?: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'id'>
    & { messages: (
      { __typename?: 'MessageConnection' }
      & Pick<MessageConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ), edges: Array<(
        { __typename?: 'MessageEdge' }
        & { node?: Maybe<(
          { __typename?: 'AutomatedAttendanceMessage' }
          & Pick<AutomatedAttendanceMessage, 'id' | 'timestamp' | 'status' | 'type'>
        ) | (
          { __typename?: 'FromEndUserMessage' }
          & Pick<FromEndUserMessage, 'body' | 'id' | 'timestamp' | 'status' | 'type'>
          & { media?: Maybe<Array<(
            { __typename?: 'Media' }
            & Pick<Media, 'url' | 'contentType'>
          )>> }
        ) | (
          { __typename?: 'FromPlatformEventMessage' }
          & Pick<FromPlatformEventMessage, 'body' | 'id' | 'timestamp' | 'status' | 'type'>
        ) | (
          { __typename?: 'FromPlatformMessage' }
          & Pick<FromPlatformMessage, 'body' | 'id' | 'timestamp' | 'status' | 'type'>
        ) | (
          { __typename?: 'FromStaffMessage' }
          & Pick<FromStaffMessage, 'senderName' | 'body' | 'id' | 'timestamp' | 'status' | 'type'>
          & { announcement?: Maybe<(
            { __typename?: 'Announcement' }
            & Pick<Announcement, 'id'>
          )> }
        ) | (
          { __typename?: 'UndefinedMessage' }
          & Pick<UndefinedMessage, 'id' | 'timestamp' | 'status' | 'type'>
        )> }
      )> }
    ) }
  )> }
);

export type GetClientNotesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetClientNotesQuery = (
  { __typename?: 'Query' }
  & { getClient?: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'notes'>
  )> }
);

export type GetClientsDashboardStatsQueryVariables = Exact<{
  attendanceDonutEventsFilter: EventStatsFilter;
  attendanceTidbitsEventsFilter: EventStatsFilter;
  engagementTripleStackMessagesFilter: MessageStatsFilter;
  engagementTidbitsMessagesFilter: MessageStatsFilter;
  engagementTidbitsTotalMessagesFilter: MessageStatsFilter;
  engagementTidbitsClientsFilter: ClientStatsFilter;
}>;


export type GetClientsDashboardStatsQuery = (
  { __typename?: 'Query' }
  & { getStats: (
    { __typename?: 'Stats' }
    & AttendanceDonutStatsFragment
    & AttendanceTidbitsStatsFragment
    & EngagementTripleStackStatsFragment
    & EngagementTidbitsStatsFragment
  ) }
);

export type GetCohortsQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;


export type GetCohortsQuery = (
  { __typename?: 'Query' }
  & { getOrganization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'cohortLabel'>
    & { cohorts: Array<(
      { __typename?: 'Cohort' }
      & Pick<Cohort, 'id'>
      & { name: Array<(
        { __typename?: 'Text' }
        & Pick<Text, 'locale' | 'text'>
      )> }
    )> }
  )> }
);

export type GetCombinedScheduleEventsQueryVariables = Exact<{
  lastActiveTimestamp: Scalars['Float'];
  startDate: Scalars['Float'];
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
}>;


export type GetCombinedScheduleEventsQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'EndUser' }
    & Pick<EndUser, 'id' | 'type'>
  ) | (
    { __typename?: 'StaffUser' }
    & Pick<StaffUser, 'id' | 'type'>
    & { events: (
      { __typename?: 'EventConnection' }
      & { edges: Array<(
        { __typename?: 'EventEdge' }
        & { node?: Maybe<(
          { __typename?: 'CheckInEvent' }
          & Pick<CheckInEvent, 'isCompleted' | 'submittedTimestamp' | 'id' | 'type' | 'startTimestamp' | 'endTimestamp' | 'title' | 'clientFirstName' | 'clientLastName' | 'clientId' | 'staffId' | 'closed' | 'groupEventId' | 'staffAttending'>
          & { checkInTemplate: (
            { __typename?: 'CheckInTemplate' }
            & Pick<CheckInTemplate, 'id' | 'staffDisplayName'>
          ), checkInReport?: Maybe<(
            { __typename?: 'CheckInReport' }
            & Pick<CheckInReport, 'id'>
          )> }
        ) | (
          { __typename?: 'CourtDateEvent' }
          & Pick<CourtDateEvent, 'attendanceInfo' | 'id' | 'type' | 'startTimestamp' | 'endTimestamp' | 'title' | 'clientFirstName' | 'clientLastName' | 'clientId' | 'staffId' | 'closed' | 'groupEventId' | 'staffAttending'>
          & { location: (
            { __typename?: 'Location' }
            & Pick<Location, 'id' | 'addressLine1' | 'city' | 'state' | 'zip'>
          ), room?: Maybe<(
            { __typename?: 'Room' }
            & Pick<Room, 'id' | 'description'>
          )>, attended?: Maybe<(
            { __typename?: 'Outcome' }
            & Pick<Outcome, 'id' | 'outcomeId' | 'name'>
          )>, outcome: (
            { __typename?: 'Outcome' }
            & Pick<Outcome, 'outcomeUid' | 'status'>
          ) }
        ) | (
          { __typename?: 'OfficeEvent' }
          & Pick<OfficeEvent, 'attendanceInfo' | 'id' | 'type' | 'startTimestamp' | 'endTimestamp' | 'title' | 'clientFirstName' | 'clientLastName' | 'clientId' | 'staffId' | 'closed' | 'groupEventId' | 'staffAttending'>
          & { location: (
            { __typename?: 'Location' }
            & Pick<Location, 'id' | 'addressLine1' | 'city' | 'state' | 'zip'>
          ), room?: Maybe<(
            { __typename?: 'Room' }
            & Pick<Room, 'id' | 'description'>
          )>, attended?: Maybe<(
            { __typename?: 'Outcome' }
            & Pick<Outcome, 'id' | 'outcomeId' | 'name'>
          )>, outcome: (
            { __typename?: 'Outcome' }
            & Pick<Outcome, 'outcomeUid' | 'status'>
          ) }
        ) | (
          { __typename?: 'VirtualEvent' }
          & Pick<VirtualEvent, 'id' | 'type' | 'startTimestamp' | 'endTimestamp' | 'title' | 'clientFirstName' | 'clientLastName' | 'clientId' | 'staffId' | 'closed' | 'groupEventId' | 'staffAttending'>
        )> }
      )> }
    ) }
  ) }
);

export type GetEventsQueryQueryVariables = Exact<{
  id: Scalars['ID'];
  timestamp: Scalars['Float'];
  timeFilter: EventFilter;
}>;


export type GetEventsQueryQuery = (
  { __typename?: 'Query' }
  & { getClient?: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'id'>
    & { events: (
      { __typename?: 'EventConnection' }
      & { edges: Array<(
        { __typename?: 'EventEdge' }
        & { node?: Maybe<(
          { __typename?: 'CheckInEvent' }
          & Pick<CheckInEvent, 'checkInReportId' | 'isCompleted' | 'submittedTimestamp' | 'endTimestamp' | 'id' | 'clientId' | 'staffId' | 'timestamp' | 'type' | 'title' | 'closed' | 'groupEventId' | 'startTimestamp'>
          & { checkInTemplate: (
            { __typename?: 'CheckInTemplate' }
            & Pick<CheckInTemplate, 'id' | 'staffDisplayName'>
          ), outcome: (
            { __typename?: 'Outcome' }
            & Pick<Outcome, 'id' | 'outcomeUid' | 'status'>
          ) }
        ) | (
          { __typename?: 'CourtDateEvent' }
          & Pick<CourtDateEvent, 'attendanceInfo' | 'id' | 'clientId' | 'staffId' | 'timestamp' | 'type' | 'title' | 'closed' | 'groupEventId' | 'startTimestamp'>
          & { location: (
            { __typename?: 'Location' }
            & Pick<Location, 'id' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zip'>
          ), attended?: Maybe<(
            { __typename?: 'Outcome' }
            & Pick<Outcome, 'id' | 'outcomeId' | 'name'>
          )>, room?: Maybe<(
            { __typename?: 'Room' }
            & Pick<Room, 'id' | 'roomId' | 'description'>
          )>, outcome: (
            { __typename?: 'Outcome' }
            & Pick<Outcome, 'id' | 'outcomeUid' | 'status'>
          ) }
        ) | (
          { __typename?: 'OfficeEvent' }
          & Pick<OfficeEvent, 'attendanceInfo' | 'id' | 'clientId' | 'staffId' | 'timestamp' | 'type' | 'title' | 'closed' | 'groupEventId' | 'startTimestamp'>
          & { location: (
            { __typename?: 'Location' }
            & Pick<Location, 'id' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zip'>
          ), room?: Maybe<(
            { __typename?: 'Room' }
            & Pick<Room, 'id' | 'roomId' | 'description'>
          )>, attended?: Maybe<(
            { __typename?: 'Outcome' }
            & Pick<Outcome, 'id' | 'outcomeId' | 'name'>
          )>, outcome: (
            { __typename?: 'Outcome' }
            & Pick<Outcome, 'id' | 'outcomeUid' | 'status'>
          ) }
        ) | (
          { __typename?: 'VirtualEvent' }
          & Pick<VirtualEvent, 'endTimestamp' | 'id' | 'clientId' | 'staffId' | 'timestamp' | 'type' | 'title' | 'closed' | 'groupEventId' | 'startTimestamp'>
          & { outcome: (
            { __typename?: 'Outcome' }
            & Pick<Outcome, 'id' | 'outcomeUid' | 'status'>
          ) }
        )> }
      )> }
    ) }
  )> }
);

export type GetMasqueradeOrganizationsListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMasqueradeOrganizationsListQuery = (
  { __typename?: 'Query' }
  & { getAllOrganizations: (
    { __typename?: 'OrganizationConnection' }
    & { edges: Array<(
      { __typename?: 'OrganizationEdge' }
      & { node?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'organizationUid' | 'name' | 'type'>
      )> }
    )> }
  ) }
);

export type GetMasqueradeStaffListQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type GetMasqueradeStaffListQuery = (
  { __typename?: 'Query' }
  & { getOrganization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
    & { staff: (
      { __typename?: 'StaffUserConnection' }
      & { edges: Array<(
        { __typename?: 'StaffUserEdge' }
        & { node?: Maybe<(
          { __typename?: 'StaffUser' }
          & Pick<StaffUser, 'id' | 'name' | 'email'>
        )> }
      )> }
    ) }
  )> }
);

export type GetMessagesUpdatesSubscriptionVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type GetMessagesUpdatesSubscription = (
  { __typename?: 'Subscription' }
  & { messagesForStaffUsers: (
    { __typename?: 'AutomatedAttendanceMessage' }
    & Pick<AutomatedAttendanceMessage, 'id' | 'clientId' | 'timestamp' | 'status' | 'type' | 'batchNotify'>
  ) | (
    { __typename?: 'FromEndUserMessage' }
    & Pick<FromEndUserMessage, 'body' | 'id' | 'clientId' | 'timestamp' | 'status' | 'type' | 'batchNotify'>
    & { media?: Maybe<Array<(
      { __typename?: 'Media' }
      & Pick<Media, 'url' | 'contentType'>
    )>> }
  ) | (
    { __typename?: 'FromPlatformEventMessage' }
    & Pick<FromPlatformEventMessage, 'body' | 'id' | 'clientId' | 'timestamp' | 'status' | 'type' | 'batchNotify'>
  ) | (
    { __typename?: 'FromPlatformMessage' }
    & Pick<FromPlatformMessage, 'body' | 'id' | 'clientId' | 'timestamp' | 'status' | 'type' | 'batchNotify'>
  ) | (
    { __typename?: 'FromStaffMessage' }
    & Pick<FromStaffMessage, 'senderName' | 'body' | 'id' | 'clientId' | 'timestamp' | 'status' | 'type' | 'batchNotify'>
    & { announcement?: Maybe<(
      { __typename?: 'Announcement' }
      & Pick<Announcement, 'id'>
    )> }
  ) | (
    { __typename?: 'UndefinedMessage' }
    & Pick<UndefinedMessage, 'id' | 'clientId' | 'timestamp' | 'status' | 'type' | 'batchNotify'>
  ) }
);

export type GetOrganizationLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationLocationsQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'EndUser' }
    & Pick<EndUser, 'id'>
  ) | (
    { __typename?: 'StaffUser' }
    & Pick<StaffUser, 'name' | 'id'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'id' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zip' | 'isExternal' | 'providesChildCare'>
        & { rooms: (
          { __typename?: 'RoomConnection' }
          & { edges: Array<(
            { __typename?: 'RoomEdge' }
            & { node?: Maybe<(
              { __typename?: 'Room' }
              & Pick<Room, 'id' | 'description'>
            )> }
          )> }
        ) }
      )> }
    ) }
  ) }
);

export type GetOrganizationOutcomesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationOutcomesQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'EndUser' }
    & Pick<EndUser, 'id' | 'type'>
  ) | (
    { __typename?: 'StaffUser' }
    & Pick<StaffUser, 'id' | 'type'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { outcomes: Array<(
        { __typename?: 'Outcome' }
        & Pick<Outcome, 'id' | 'outcomeUid' | 'name'>
      )> }
    ) }
  ) }
);

export type GetOrganizationStaffQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationStaffQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'EndUser' }
    & Pick<EndUser, 'id'>
  ) | (
    { __typename?: 'StaffUser' }
    & Pick<StaffUser, 'id'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'maxStaffPerClient'>
      & { staff: (
        { __typename?: 'StaffUserConnection' }
        & { edges: Array<(
          { __typename?: 'StaffUserEdge' }
          & { node?: Maybe<(
            { __typename?: 'StaffUser' }
            & Pick<StaffUser, 'id' | 'name' | 'lawyer'>
          )> }
        )> }
      ) }
    ) }
  ) }
);

export type GetQuietHoursQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuietHoursQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'EndUser' }
    & Pick<EndUser, 'id' | 'type'>
  ) | (
    { __typename?: 'StaffUser' }
    & Pick<StaffUser, 'id' | 'type'>
    & { quietHours?: Maybe<(
      { __typename?: 'QuietHours' }
      & Pick<QuietHours, 'autoreply' | 'autoreply_enabled' | 'enabled' | 'end' | 'id' | 'start' | 'weekends_off'>
    )> }
  ) }
);

export type GetStaffMemberListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStaffMemberListQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'EndUser' }
    & Pick<EndUser, 'id' | 'type'>
  ) | (
    { __typename?: 'StaffUser' }
    & Pick<StaffUser, 'visibleStaffIds' | 'id' | 'type'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
      & { staff: (
        { __typename?: 'StaffUserConnection' }
        & { edges: Array<(
          { __typename?: 'StaffUserEdge' }
          & { node?: Maybe<(
            { __typename?: 'StaffUser' }
            & Pick<StaffUser, 'id' | 'name' | 'lawyer'>
          )> }
        )> }
      ) }
    ) }
  ) }
);

export type GetStaffVirtualEventsQueryVariables = Exact<{
  filterTimestamp: Scalars['Float'];
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
}>;


export type GetStaffVirtualEventsQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'EndUser' }
    & Pick<EndUser, 'id' | 'type'>
  ) | (
    { __typename?: 'StaffUser' }
    & Pick<StaffUser, 'id' | 'type'>
    & StaffVirtualEventsFragment
  ) }
);

export type GetUserQueryVariables = Exact<{
  filterIncludeEventStaff: Scalars['Boolean'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'EndUser' }
    & Pick<EndUser, 'id' | 'type'>
  ) | (
    { __typename?: 'StaffUser' }
    & Pick<StaffUser, 'name' | 'supervisor' | 'lawyer' | 'visibleStaffIds' | 'id' | 'type'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'type' | 'maxStaffPerClient'>
      & { locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'id' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zip' | 'providesChildCare' | 'isExternal'>
        & { rooms: (
          { __typename?: 'RoomConnection' }
          & { edges: Array<(
            { __typename?: 'RoomEdge' }
            & { node?: Maybe<(
              { __typename?: 'Room' }
              & Pick<Room, 'id' | 'description'>
            )> }
          )> }
        ) }
      )>, outcomes: Array<(
        { __typename?: 'Outcome' }
        & Pick<Outcome, 'id' | 'outcomeUid' | 'name'>
      )> }
    ), clients?: Maybe<(
      { __typename?: 'ClientConnection' }
      & { edges: Array<(
        { __typename?: 'ClientEdge' }
        & { node?: Maybe<(
          { __typename?: 'Client' }
          & Pick<Client, 'id' | 'firstName' | 'lastName' | 'closed' | 'cmsId' | 'shouldReceiveMessages' | 'lastSend'>
          & { carrier?: Maybe<(
            { __typename?: 'Carrier' }
            & Pick<Carrier, 'type' | 'error'>
          )>, assignedStaff?: Maybe<Array<(
            { __typename?: 'StaffUser' }
            & Pick<StaffUser, 'id'>
          )>> }
          & FilterFieldsFragment
        )> }
      )> }
    )> }
  ) }
);

export type GetUserIdentificationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserIdentificationQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'EndUser' }
    & Pick<EndUser, 'id'>
  ) | (
    { __typename?: 'StaffUser' }
    & Pick<StaffUser, 'name' | 'email' | 'authUid' | 'supervisor' | 'lawyer' | 'visibleStaffIds' | 'localRole' | 'id'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'organizationId' | 'type'>
    ) }
  ) }
);

export type GetVisibleClientMessagesQueryVariables = Exact<{
  filter: MessagesFilter;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type GetVisibleClientMessagesQuery = (
  { __typename?: 'Query' }
  & { getStaffMessages: (
    { __typename?: 'MessageConnection' }
    & Pick<MessageConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ), edges: Array<(
      { __typename?: 'MessageEdge' }
      & { node?: Maybe<(
        { __typename?: 'AutomatedAttendanceMessage' }
        & Pick<AutomatedAttendanceMessage, 'id' | 'timestamp' | 'status' | 'type'>
      ) | (
        { __typename?: 'FromEndUserMessage' }
        & Pick<FromEndUserMessage, 'errorMessage' | 'body' | 'id' | 'timestamp' | 'status' | 'type'>
        & { media?: Maybe<Array<(
          { __typename?: 'Media' }
          & Pick<Media, 'url' | 'contentType'>
        )>> }
      ) | (
        { __typename?: 'FromPlatformEventMessage' }
        & Pick<FromPlatformEventMessage, 'errorMessage' | 'body' | 'id' | 'timestamp' | 'status' | 'type'>
      ) | (
        { __typename?: 'FromPlatformMessage' }
        & Pick<FromPlatformMessage, 'errorMessage' | 'body' | 'id' | 'timestamp' | 'status' | 'type'>
      ) | (
        { __typename?: 'FromStaffMessage' }
        & Pick<FromStaffMessage, 'errorMessage' | 'senderName' | 'body' | 'id' | 'timestamp' | 'status' | 'type'>
        & { announcement?: Maybe<(
          { __typename?: 'Announcement' }
          & Pick<Announcement, 'id'>
        )> }
      ) | (
        { __typename?: 'UndefinedMessage' }
        & Pick<UndefinedMessage, 'id' | 'timestamp' | 'status' | 'type'>
      )> }
    )> }
  ) }
);

export type RefetchQuietHoursQueryVariables = Exact<{ [key: string]: never; }>;


export type RefetchQuietHoursQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'EndUser' }
    & Pick<EndUser, 'id' | 'type'>
  ) | (
    { __typename?: 'StaffUser' }
    & Pick<StaffUser, 'id' | 'type'>
    & { quietHours?: Maybe<(
      { __typename?: 'QuietHours' }
      & Pick<QuietHours, 'id'>
    )> }
  ) }
);

export type RefetchClientsListQueryVariables = Exact<{ [key: string]: never; }>;


export type RefetchClientsListQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'EndUser' }
    & Pick<EndUser, 'id'>
  ) | (
    { __typename?: 'StaffUser' }
    & Pick<StaffUser, 'id'>
    & { clients?: Maybe<(
      { __typename?: 'ClientConnection' }
      & { edges: Array<(
        { __typename?: 'ClientEdge' }
        & { node?: Maybe<(
          { __typename?: 'Client' }
          & Pick<Client, 'id' | 'firstName' | 'lastName' | 'staffId'>
          & { messages: (
            { __typename?: 'MessageConnection' }
            & { edges: Array<(
              { __typename?: 'MessageEdge' }
              & { node?: Maybe<(
                { __typename?: 'AutomatedAttendanceMessage' }
                & Pick<AutomatedAttendanceMessage, 'id' | 'status'>
              ) | (
                { __typename?: 'FromEndUserMessage' }
                & Pick<FromEndUserMessage, 'id' | 'status'>
              ) | (
                { __typename?: 'FromPlatformEventMessage' }
                & Pick<FromPlatformEventMessage, 'errorMessage' | 'body' | 'id' | 'status'>
              ) | (
                { __typename?: 'FromPlatformMessage' }
                & Pick<FromPlatformMessage, 'errorMessage' | 'body' | 'id' | 'status'>
              ) | (
                { __typename?: 'FromStaffMessage' }
                & Pick<FromStaffMessage, 'errorMessage' | 'body' | 'id' | 'status'>
              ) | (
                { __typename?: 'UndefinedMessage' }
                & Pick<UndefinedMessage, 'id' | 'status'>
              )> }
            )> }
          ) }
        )> }
      )> }
    )> }
  ) }
);

export const AttendanceDonutStatsFragmentDoc = gql`
    fragment AttendanceDonutStats on Stats {
  attendanceDonutEvents: events(filter: $attendanceDonutEventsFilter) {
    totalEvents {
      attended
      missed
      unknown
    }
  }
}
    `;
export const AttendanceTidbitsStatsFragmentDoc = gql`
    fragment AttendanceTidbitsStats on Stats {
  attendanceTidbitsEvents: events(filter: $attendanceTidbitsEventsFilter) {
    courtDate {
      attended
      total
    }
    office {
      attended
      total
    }
    virtual {
      attended
      total
    }
    checkIn {
      attended
      total
    }
  }
}
    `;
export const ClientDetailsFragmentDoc = gql`
    fragment ClientDetails on Client {
  id
  firstName
  lastName
  phone
  email
  gender
  language
  staff {
    id
    name
  }
  riskLevel
  cmsId
  noPhone
  noCar
  housingInsecure
  needsChildCare
  inCustody
  employer
  clientUpdatedPhone
  clientUpdatedAddress
  clientUpdatedEmployer
  docket
  upperDocket
  cases {
    edges {
      node {
        id
        number
        open
      }
    }
  }
  cohort {
    id
    name {
      locale
      text
    }
  }
  terms {
    id
    endDate
    restitutions {
      edges {
        node {
          id
          amount
          type
        }
      }
    }
    conditions {
      edges {
        node {
          id
          description
        }
      }
    }
  }
  address {
    addressLine1
    addressLine2
    city
    state
    zipCode
  }
}
    `;
export const EngagementTidbitsStatsFragmentDoc = gql`
    fragment EngagementTidbitsStats on Stats {
  engagementTidbitDeliveredReminders: messages(
    filter: $engagementTidbitsMessagesFilter
  ) {
    fromPlatform
  }
  engagementTidbitAllReminders: messages(
    filter: $engagementTidbitsTotalMessagesFilter
  ) {
    fromPlatform
  }
  engagementTidbitClients: clients(filter: $engagementTidbitsClientsFilter) {
    hasMobileApp
    reachableBySms
    unreachable
    sentMessage
    total
  }
}
    `;
export const EngagementTripleStackStatsFragmentDoc = gql`
    fragment EngagementTripleStackStats on Stats {
  engagementTripleStackMessages: messages(
    filter: $engagementTripleStackMessagesFilter
  ) {
    fromStaff
    fromEndUser
    fromPlatform
  }
}
    `;
export const FilterFieldsFragmentDoc = gql`
    fragment FilterFields on Client {
  lastOpenedTimestamp
  lastReceivedTimestamp
  lastSendAutoTimestamp
  lastSendTimestamp
  activity @client
  alerts @client
  status @client
  riskLevel
  cohortId
  staffId
  hasMobileApp
  inCustody
  noPhone
  phone
}
    `;
export const GetClientDisplayNameFragmentDoc = gql`
    fragment getClientDisplayName on Client {
  displayName
}
    `;
export const GetClientInformationFragmentDoc = gql`
    fragment getClientInformation on Client {
  displayName
  phone
  staff {
    id
  }
}
    `;
export const IsSupportStaffFragmentDoc = gql`
    fragment IsSupportStaff on StaffUser {
  supervisor
  lawyer
  visibleStaffIds
  isSupportStaff
}
    `;
export const StaffVirtualEventsFragmentDoc = gql`
    fragment StaffVirtualEvents on StaffUser {
  events(
    fromLastActiveTimestamp: $filterTimestamp
    eventFilter: UPCOMING
    eventFilterTimestamp: $filterTimestamp
    type: VIRTUAL
    from: $from
    to: $to
  ) {
    edges {
      node {
        id
        type
        startTimestamp
        endTimestamp
        title
        clientFirstName
        clientLastName
        staffId
      }
    }
  }
}
    `;
export const StatusBarInfoFragmentDoc = gql`
    fragment StatusBarInfo on Client {
  closedAt
  hasMobileApp
  lastSend
  noPhone
  carrier {
    type
    error
  }
  closed
}
    `;
export const CreateClientDocument = gql`
    mutation createClient($input: CreateClientInput!) {
  createClient(input: $input) {
    client {
      id
      firstName
      lastName
    }
  }
}
    `;
export type CreateClientMutationFn = Apollo.MutationFunction<CreateClientMutation, CreateClientMutationVariables>;

/**
 * __useCreateClientMutation__
 *
 * To run a mutation, you first call `useCreateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientMutation, { data, loading, error }] = useCreateClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientMutation, CreateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientMutation, CreateClientMutationVariables>(CreateClientDocument, options);
      }
export type CreateClientMutationHookResult = ReturnType<typeof useCreateClientMutation>;
export type CreateClientMutationResult = Apollo.MutationResult<CreateClientMutation>;
export type CreateClientMutationOptions = Apollo.BaseMutationOptions<CreateClientMutation, CreateClientMutationVariables>;
export const CreateContactDocument = gql`
    mutation createContact($input: CreateContactInput!) {
  createContact(input: $input) {
    contact {
      id
      name
      notes
      phone
      optIn
    }
  }
}
    `;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const CreateCheckInEventDocument = gql`
    mutation createCheckInEvent($input: CreateCheckInEventInput!) {
  createCheckInEvent(input: $input) {
    checkInEvent {
      id
    }
  }
}
    `;
export type CreateCheckInEventMutationFn = Apollo.MutationFunction<CreateCheckInEventMutation, CreateCheckInEventMutationVariables>;

/**
 * __useCreateCheckInEventMutation__
 *
 * To run a mutation, you first call `useCreateCheckInEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckInEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckInEventMutation, { data, loading, error }] = useCreateCheckInEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCheckInEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateCheckInEventMutation, CreateCheckInEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCheckInEventMutation, CreateCheckInEventMutationVariables>(CreateCheckInEventDocument, options);
      }
export type CreateCheckInEventMutationHookResult = ReturnType<typeof useCreateCheckInEventMutation>;
export type CreateCheckInEventMutationResult = Apollo.MutationResult<CreateCheckInEventMutation>;
export type CreateCheckInEventMutationOptions = Apollo.BaseMutationOptions<CreateCheckInEventMutation, CreateCheckInEventMutationVariables>;
export const CreateCourtDateDocument = gql`
    mutation createCourtDate($input: CreateCourtDateInput!) {
  createCourtDate(input: $input) {
    courtDate {
      id
    }
  }
}
    `;
export type CreateCourtDateMutationFn = Apollo.MutationFunction<CreateCourtDateMutation, CreateCourtDateMutationVariables>;

/**
 * __useCreateCourtDateMutation__
 *
 * To run a mutation, you first call `useCreateCourtDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourtDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourtDateMutation, { data, loading, error }] = useCreateCourtDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCourtDateMutation(baseOptions?: Apollo.MutationHookOptions<CreateCourtDateMutation, CreateCourtDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCourtDateMutation, CreateCourtDateMutationVariables>(CreateCourtDateDocument, options);
      }
export type CreateCourtDateMutationHookResult = ReturnType<typeof useCreateCourtDateMutation>;
export type CreateCourtDateMutationResult = Apollo.MutationResult<CreateCourtDateMutation>;
export type CreateCourtDateMutationOptions = Apollo.BaseMutationOptions<CreateCourtDateMutation, CreateCourtDateMutationVariables>;
export const CreateOfficeEventDocument = gql`
    mutation createOfficeEvent($input: CreateOfficeEventInput!) {
  createOfficeEvent(input: $input) {
    officeEvent {
      id
    }
  }
}
    `;
export type CreateOfficeEventMutationFn = Apollo.MutationFunction<CreateOfficeEventMutation, CreateOfficeEventMutationVariables>;

/**
 * __useCreateOfficeEventMutation__
 *
 * To run a mutation, you first call `useCreateOfficeEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfficeEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfficeEventMutation, { data, loading, error }] = useCreateOfficeEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOfficeEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateOfficeEventMutation, CreateOfficeEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOfficeEventMutation, CreateOfficeEventMutationVariables>(CreateOfficeEventDocument, options);
      }
export type CreateOfficeEventMutationHookResult = ReturnType<typeof useCreateOfficeEventMutation>;
export type CreateOfficeEventMutationResult = Apollo.MutationResult<CreateOfficeEventMutation>;
export type CreateOfficeEventMutationOptions = Apollo.BaseMutationOptions<CreateOfficeEventMutation, CreateOfficeEventMutationVariables>;
export const CreateVirtualEventDocument = gql`
    mutation createVirtualEvent($input: CreateVirtualEventInput!) {
  createVirtualEvent(input: $input) {
    virtualEvent {
      id
    }
  }
}
    `;
export type CreateVirtualEventMutationFn = Apollo.MutationFunction<CreateVirtualEventMutation, CreateVirtualEventMutationVariables>;

/**
 * __useCreateVirtualEventMutation__
 *
 * To run a mutation, you first call `useCreateVirtualEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVirtualEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVirtualEventMutation, { data, loading, error }] = useCreateVirtualEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVirtualEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateVirtualEventMutation, CreateVirtualEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVirtualEventMutation, CreateVirtualEventMutationVariables>(CreateVirtualEventDocument, options);
      }
export type CreateVirtualEventMutationHookResult = ReturnType<typeof useCreateVirtualEventMutation>;
export type CreateVirtualEventMutationResult = Apollo.MutationResult<CreateVirtualEventMutation>;
export type CreateVirtualEventMutationOptions = Apollo.BaseMutationOptions<CreateVirtualEventMutation, CreateVirtualEventMutationVariables>;
export const CreateGroupEventDocument = gql`
    mutation createGroupEvent($input: CreateGroupEventInput!) {
  createGroupEvent(input: $input) {
    groupEvent {
      id
    }
  }
}
    `;
export type CreateGroupEventMutationFn = Apollo.MutationFunction<CreateGroupEventMutation, CreateGroupEventMutationVariables>;

/**
 * __useCreateGroupEventMutation__
 *
 * To run a mutation, you first call `useCreateGroupEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupEventMutation, { data, loading, error }] = useCreateGroupEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGroupEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupEventMutation, CreateGroupEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupEventMutation, CreateGroupEventMutationVariables>(CreateGroupEventDocument, options);
      }
export type CreateGroupEventMutationHookResult = ReturnType<typeof useCreateGroupEventMutation>;
export type CreateGroupEventMutationResult = Apollo.MutationResult<CreateGroupEventMutation>;
export type CreateGroupEventMutationOptions = Apollo.BaseMutationOptions<CreateGroupEventMutation, CreateGroupEventMutationVariables>;
export const DeleteCheckInEventDocument = gql`
    mutation deleteCheckInEvent($input: DeleteCheckInEventInput!) {
  deleteCheckInEvent(input: $input) {
    checkInEvent {
      id
    }
  }
}
    `;
export type DeleteCheckInEventMutationFn = Apollo.MutationFunction<DeleteCheckInEventMutation, DeleteCheckInEventMutationVariables>;

/**
 * __useDeleteCheckInEventMutation__
 *
 * To run a mutation, you first call `useDeleteCheckInEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCheckInEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCheckInEventMutation, { data, loading, error }] = useDeleteCheckInEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCheckInEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCheckInEventMutation, DeleteCheckInEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCheckInEventMutation, DeleteCheckInEventMutationVariables>(DeleteCheckInEventDocument, options);
      }
export type DeleteCheckInEventMutationHookResult = ReturnType<typeof useDeleteCheckInEventMutation>;
export type DeleteCheckInEventMutationResult = Apollo.MutationResult<DeleteCheckInEventMutation>;
export type DeleteCheckInEventMutationOptions = Apollo.BaseMutationOptions<DeleteCheckInEventMutation, DeleteCheckInEventMutationVariables>;
export const DeleteCourtDateDocument = gql`
    mutation deleteCourtDate($input: DeleteCourtDateInput!) {
  deleteCourtDate(input: $input) {
    courtDate {
      id
    }
  }
}
    `;
export type DeleteCourtDateMutationFn = Apollo.MutationFunction<DeleteCourtDateMutation, DeleteCourtDateMutationVariables>;

/**
 * __useDeleteCourtDateMutation__
 *
 * To run a mutation, you first call `useDeleteCourtDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourtDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourtDateMutation, { data, loading, error }] = useDeleteCourtDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCourtDateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCourtDateMutation, DeleteCourtDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCourtDateMutation, DeleteCourtDateMutationVariables>(DeleteCourtDateDocument, options);
      }
export type DeleteCourtDateMutationHookResult = ReturnType<typeof useDeleteCourtDateMutation>;
export type DeleteCourtDateMutationResult = Apollo.MutationResult<DeleteCourtDateMutation>;
export type DeleteCourtDateMutationOptions = Apollo.BaseMutationOptions<DeleteCourtDateMutation, DeleteCourtDateMutationVariables>;
export const DeleteOfficeEventDocument = gql`
    mutation deleteOfficeEvent($input: DeleteOfficeEventInput!) {
  deleteOfficeEvent(input: $input) {
    officeEvent {
      id
    }
  }
}
    `;
export type DeleteOfficeEventMutationFn = Apollo.MutationFunction<DeleteOfficeEventMutation, DeleteOfficeEventMutationVariables>;

/**
 * __useDeleteOfficeEventMutation__
 *
 * To run a mutation, you first call `useDeleteOfficeEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfficeEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfficeEventMutation, { data, loading, error }] = useDeleteOfficeEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOfficeEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOfficeEventMutation, DeleteOfficeEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOfficeEventMutation, DeleteOfficeEventMutationVariables>(DeleteOfficeEventDocument, options);
      }
export type DeleteOfficeEventMutationHookResult = ReturnType<typeof useDeleteOfficeEventMutation>;
export type DeleteOfficeEventMutationResult = Apollo.MutationResult<DeleteOfficeEventMutation>;
export type DeleteOfficeEventMutationOptions = Apollo.BaseMutationOptions<DeleteOfficeEventMutation, DeleteOfficeEventMutationVariables>;
export const DeleteVirtualEventDocument = gql`
    mutation deleteVirtualEvent($input: DeleteVirtualEventInput!) {
  deleteVirtualEvent(input: $input) {
    virtualEvent {
      id
    }
  }
}
    `;
export type DeleteVirtualEventMutationFn = Apollo.MutationFunction<DeleteVirtualEventMutation, DeleteVirtualEventMutationVariables>;

/**
 * __useDeleteVirtualEventMutation__
 *
 * To run a mutation, you first call `useDeleteVirtualEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVirtualEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVirtualEventMutation, { data, loading, error }] = useDeleteVirtualEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVirtualEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVirtualEventMutation, DeleteVirtualEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVirtualEventMutation, DeleteVirtualEventMutationVariables>(DeleteVirtualEventDocument, options);
      }
export type DeleteVirtualEventMutationHookResult = ReturnType<typeof useDeleteVirtualEventMutation>;
export type DeleteVirtualEventMutationResult = Apollo.MutationResult<DeleteVirtualEventMutation>;
export type DeleteVirtualEventMutationOptions = Apollo.BaseMutationOptions<DeleteVirtualEventMutation, DeleteVirtualEventMutationVariables>;
export const DeleteGroupEventDocument = gql`
    mutation deleteGroupEvent($id: ID!) {
  deleteGroupEvent(id: $id)
}
    `;
export type DeleteGroupEventMutationFn = Apollo.MutationFunction<DeleteGroupEventMutation, DeleteGroupEventMutationVariables>;

/**
 * __useDeleteGroupEventMutation__
 *
 * To run a mutation, you first call `useDeleteGroupEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupEventMutation, { data, loading, error }] = useDeleteGroupEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGroupEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupEventMutation, DeleteGroupEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGroupEventMutation, DeleteGroupEventMutationVariables>(DeleteGroupEventDocument, options);
      }
export type DeleteGroupEventMutationHookResult = ReturnType<typeof useDeleteGroupEventMutation>;
export type DeleteGroupEventMutationResult = Apollo.MutationResult<DeleteGroupEventMutation>;
export type DeleteGroupEventMutationOptions = Apollo.BaseMutationOptions<DeleteGroupEventMutation, DeleteGroupEventMutationVariables>;
export const IssueVideoChatTokenDocument = gql`
    mutation issueVideoChatToken($input: IssueVideoChatTokenInput!) {
  issueVideoChatToken(input: $input) {
    accessToken
  }
}
    `;
export type IssueVideoChatTokenMutationFn = Apollo.MutationFunction<IssueVideoChatTokenMutation, IssueVideoChatTokenMutationVariables>;

/**
 * __useIssueVideoChatTokenMutation__
 *
 * To run a mutation, you first call `useIssueVideoChatTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueVideoChatTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueVideoChatTokenMutation, { data, loading, error }] = useIssueVideoChatTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIssueVideoChatTokenMutation(baseOptions?: Apollo.MutationHookOptions<IssueVideoChatTokenMutation, IssueVideoChatTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueVideoChatTokenMutation, IssueVideoChatTokenMutationVariables>(IssueVideoChatTokenDocument, options);
      }
export type IssueVideoChatTokenMutationHookResult = ReturnType<typeof useIssueVideoChatTokenMutation>;
export type IssueVideoChatTokenMutationResult = Apollo.MutationResult<IssueVideoChatTokenMutation>;
export type IssueVideoChatTokenMutationOptions = Apollo.BaseMutationOptions<IssueVideoChatTokenMutation, IssueVideoChatTokenMutationVariables>;
export const IssueMasqueradeTokenDocument = gql`
    mutation issueMasqueradeToken($input: IssueMasqueradeTokenInput!) {
  issueMasqueradeToken(input: $input) {
    token
  }
}
    `;
export type IssueMasqueradeTokenMutationFn = Apollo.MutationFunction<IssueMasqueradeTokenMutation, IssueMasqueradeTokenMutationVariables>;

/**
 * __useIssueMasqueradeTokenMutation__
 *
 * To run a mutation, you first call `useIssueMasqueradeTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueMasqueradeTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueMasqueradeTokenMutation, { data, loading, error }] = useIssueMasqueradeTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIssueMasqueradeTokenMutation(baseOptions?: Apollo.MutationHookOptions<IssueMasqueradeTokenMutation, IssueMasqueradeTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueMasqueradeTokenMutation, IssueMasqueradeTokenMutationVariables>(IssueMasqueradeTokenDocument, options);
      }
export type IssueMasqueradeTokenMutationHookResult = ReturnType<typeof useIssueMasqueradeTokenMutation>;
export type IssueMasqueradeTokenMutationResult = Apollo.MutationResult<IssueMasqueradeTokenMutation>;
export type IssueMasqueradeTokenMutationOptions = Apollo.BaseMutationOptions<IssueMasqueradeTokenMutation, IssueMasqueradeTokenMutationVariables>;
export const CreateQuietHoursDocument = gql`
    mutation createQuietHours($quietHours: CreateQuietHoursInput!) {
  createQuietHours(input: $quietHours) {
    quietHours {
      enabled
      end
      start
      autoreply_enabled
      autoreply
      weekends_off
      id
    }
  }
}
    `;
export type CreateQuietHoursMutationFn = Apollo.MutationFunction<CreateQuietHoursMutation, CreateQuietHoursMutationVariables>;

/**
 * __useCreateQuietHoursMutation__
 *
 * To run a mutation, you first call `useCreateQuietHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuietHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuietHoursMutation, { data, loading, error }] = useCreateQuietHoursMutation({
 *   variables: {
 *      quietHours: // value for 'quietHours'
 *   },
 * });
 */
export function useCreateQuietHoursMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuietHoursMutation, CreateQuietHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuietHoursMutation, CreateQuietHoursMutationVariables>(CreateQuietHoursDocument, options);
      }
export type CreateQuietHoursMutationHookResult = ReturnType<typeof useCreateQuietHoursMutation>;
export type CreateQuietHoursMutationResult = Apollo.MutationResult<CreateQuietHoursMutation>;
export type CreateQuietHoursMutationOptions = Apollo.BaseMutationOptions<CreateQuietHoursMutation, CreateQuietHoursMutationVariables>;
export const UpdateQuietHoursDocument = gql`
    mutation updateQuietHours($quietHours: UpdateQuietHoursInput!) {
  updateQuietHours(input: $quietHours) {
    quietHours {
      enabled
      end
      start
      autoreply_enabled
      autoreply
      weekends_off
      id
    }
  }
}
    `;
export type UpdateQuietHoursMutationFn = Apollo.MutationFunction<UpdateQuietHoursMutation, UpdateQuietHoursMutationVariables>;

/**
 * __useUpdateQuietHoursMutation__
 *
 * To run a mutation, you first call `useUpdateQuietHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuietHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuietHoursMutation, { data, loading, error }] = useUpdateQuietHoursMutation({
 *   variables: {
 *      quietHours: // value for 'quietHours'
 *   },
 * });
 */
export function useUpdateQuietHoursMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuietHoursMutation, UpdateQuietHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuietHoursMutation, UpdateQuietHoursMutationVariables>(UpdateQuietHoursDocument, options);
      }
export type UpdateQuietHoursMutationHookResult = ReturnType<typeof useUpdateQuietHoursMutation>;
export type UpdateQuietHoursMutationResult = Apollo.MutationResult<UpdateQuietHoursMutation>;
export type UpdateQuietHoursMutationOptions = Apollo.BaseMutationOptions<UpdateQuietHoursMutation, UpdateQuietHoursMutationVariables>;
export const SendAnnouncementDocument = gql`
    mutation sendAnnouncement($input: SendAnnouncementInput!) {
  sendAnnouncement(input: $input) {
    announcement {
      id
    }
  }
}
    `;
export type SendAnnouncementMutationFn = Apollo.MutationFunction<SendAnnouncementMutation, SendAnnouncementMutationVariables>;

/**
 * __useSendAnnouncementMutation__
 *
 * To run a mutation, you first call `useSendAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAnnouncementMutation, { data, loading, error }] = useSendAnnouncementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendAnnouncementMutation(baseOptions?: Apollo.MutationHookOptions<SendAnnouncementMutation, SendAnnouncementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAnnouncementMutation, SendAnnouncementMutationVariables>(SendAnnouncementDocument, options);
      }
export type SendAnnouncementMutationHookResult = ReturnType<typeof useSendAnnouncementMutation>;
export type SendAnnouncementMutationResult = Apollo.MutationResult<SendAnnouncementMutation>;
export type SendAnnouncementMutationOptions = Apollo.BaseMutationOptions<SendAnnouncementMutation, SendAnnouncementMutationVariables>;
export const SendFromStaffMessageDocument = gql`
    mutation sendFromStaffMessage($input: SendFromStaffMessageInput!) {
  sendFromStaffMessage(input: $input) {
    message {
      id
      body
    }
  }
}
    `;
export type SendFromStaffMessageMutationFn = Apollo.MutationFunction<SendFromStaffMessageMutation, SendFromStaffMessageMutationVariables>;

/**
 * __useSendFromStaffMessageMutation__
 *
 * To run a mutation, you first call `useSendFromStaffMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFromStaffMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFromStaffMessageMutation, { data, loading, error }] = useSendFromStaffMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendFromStaffMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendFromStaffMessageMutation, SendFromStaffMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendFromStaffMessageMutation, SendFromStaffMessageMutationVariables>(SendFromStaffMessageDocument, options);
      }
export type SendFromStaffMessageMutationHookResult = ReturnType<typeof useSendFromStaffMessageMutation>;
export type SendFromStaffMessageMutationResult = Apollo.MutationResult<SendFromStaffMessageMutation>;
export type SendFromStaffMessageMutationOptions = Apollo.BaseMutationOptions<SendFromStaffMessageMutation, SendFromStaffMessageMutationVariables>;
export const UpdateClientDocument = gql`
    mutation updateClient($input: UpdateClientInfoInput!) {
  updateClientInfo(input: $input) {
    client {
      ...ClientDetails
    }
  }
}
    ${ClientDetailsFragmentDoc}`;
export type UpdateClientMutationFn = Apollo.MutationFunction<UpdateClientMutation, UpdateClientMutationVariables>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientMutation, UpdateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientMutation, UpdateClientMutationVariables>(UpdateClientDocument, options);
      }
export type UpdateClientMutationHookResult = ReturnType<typeof useUpdateClientMutation>;
export type UpdateClientMutationResult = Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<UpdateClientMutation, UpdateClientMutationVariables>;
export const UpdateClientClosedDocument = gql`
    mutation updateClientClosed($input: UpdateClientInfoInput!) {
  updateClientInfo(input: $input) {
    client {
      id
      closed
    }
  }
}
    `;
export type UpdateClientClosedMutationFn = Apollo.MutationFunction<UpdateClientClosedMutation, UpdateClientClosedMutationVariables>;

/**
 * __useUpdateClientClosedMutation__
 *
 * To run a mutation, you first call `useUpdateClientClosedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientClosedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientClosedMutation, { data, loading, error }] = useUpdateClientClosedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientClosedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientClosedMutation, UpdateClientClosedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientClosedMutation, UpdateClientClosedMutationVariables>(UpdateClientClosedDocument, options);
      }
export type UpdateClientClosedMutationHookResult = ReturnType<typeof useUpdateClientClosedMutation>;
export type UpdateClientClosedMutationResult = Apollo.MutationResult<UpdateClientClosedMutation>;
export type UpdateClientClosedMutationOptions = Apollo.BaseMutationOptions<UpdateClientClosedMutation, UpdateClientClosedMutationVariables>;
export const UpdateContactDocument = gql`
    mutation updateContact($input: UpdateContactInput!) {
  updateContact(input: $input) {
    contact {
      id
      name
      notes
      phone
      optIn
    }
  }
}
    `;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const UpdateCheckInEventDocument = gql`
    mutation updateCheckInEvent($input: UpdateCheckInEventInput!) {
  updateCheckInEvent(input: $input) {
    checkInEvent {
      id
    }
  }
}
    `;
export type UpdateCheckInEventMutationFn = Apollo.MutationFunction<UpdateCheckInEventMutation, UpdateCheckInEventMutationVariables>;

/**
 * __useUpdateCheckInEventMutation__
 *
 * To run a mutation, you first call `useUpdateCheckInEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCheckInEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCheckInEventMutation, { data, loading, error }] = useUpdateCheckInEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCheckInEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCheckInEventMutation, UpdateCheckInEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCheckInEventMutation, UpdateCheckInEventMutationVariables>(UpdateCheckInEventDocument, options);
      }
export type UpdateCheckInEventMutationHookResult = ReturnType<typeof useUpdateCheckInEventMutation>;
export type UpdateCheckInEventMutationResult = Apollo.MutationResult<UpdateCheckInEventMutation>;
export type UpdateCheckInEventMutationOptions = Apollo.BaseMutationOptions<UpdateCheckInEventMutation, UpdateCheckInEventMutationVariables>;
export const UpdateCourtDateDocument = gql`
    mutation updateCourtDate($input: UpdateCourtDateInput!) {
  updateCourtDate(input: $input) {
    courtDate {
      id
    }
  }
}
    `;
export type UpdateCourtDateMutationFn = Apollo.MutationFunction<UpdateCourtDateMutation, UpdateCourtDateMutationVariables>;

/**
 * __useUpdateCourtDateMutation__
 *
 * To run a mutation, you first call `useUpdateCourtDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourtDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourtDateMutation, { data, loading, error }] = useUpdateCourtDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCourtDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCourtDateMutation, UpdateCourtDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCourtDateMutation, UpdateCourtDateMutationVariables>(UpdateCourtDateDocument, options);
      }
export type UpdateCourtDateMutationHookResult = ReturnType<typeof useUpdateCourtDateMutation>;
export type UpdateCourtDateMutationResult = Apollo.MutationResult<UpdateCourtDateMutation>;
export type UpdateCourtDateMutationOptions = Apollo.BaseMutationOptions<UpdateCourtDateMutation, UpdateCourtDateMutationVariables>;
export const UpdateOfficeEventDocument = gql`
    mutation updateOfficeEvent($input: UpdateOfficeEventInput!) {
  updateOfficeEvent(input: $input) {
    officeEvent {
      id
    }
  }
}
    `;
export type UpdateOfficeEventMutationFn = Apollo.MutationFunction<UpdateOfficeEventMutation, UpdateOfficeEventMutationVariables>;

/**
 * __useUpdateOfficeEventMutation__
 *
 * To run a mutation, you first call `useUpdateOfficeEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfficeEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfficeEventMutation, { data, loading, error }] = useUpdateOfficeEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOfficeEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOfficeEventMutation, UpdateOfficeEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOfficeEventMutation, UpdateOfficeEventMutationVariables>(UpdateOfficeEventDocument, options);
      }
export type UpdateOfficeEventMutationHookResult = ReturnType<typeof useUpdateOfficeEventMutation>;
export type UpdateOfficeEventMutationResult = Apollo.MutationResult<UpdateOfficeEventMutation>;
export type UpdateOfficeEventMutationOptions = Apollo.BaseMutationOptions<UpdateOfficeEventMutation, UpdateOfficeEventMutationVariables>;
export const UpdateVirtualEventDocument = gql`
    mutation updateVirtualEvent($input: UpdateVirtualEventInput!) {
  updateVirtualEvent(input: $input) {
    virtualEvent {
      id
    }
  }
}
    `;
export type UpdateVirtualEventMutationFn = Apollo.MutationFunction<UpdateVirtualEventMutation, UpdateVirtualEventMutationVariables>;

/**
 * __useUpdateVirtualEventMutation__
 *
 * To run a mutation, you first call `useUpdateVirtualEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVirtualEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVirtualEventMutation, { data, loading, error }] = useUpdateVirtualEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVirtualEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVirtualEventMutation, UpdateVirtualEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVirtualEventMutation, UpdateVirtualEventMutationVariables>(UpdateVirtualEventDocument, options);
      }
export type UpdateVirtualEventMutationHookResult = ReturnType<typeof useUpdateVirtualEventMutation>;
export type UpdateVirtualEventMutationResult = Apollo.MutationResult<UpdateVirtualEventMutation>;
export type UpdateVirtualEventMutationOptions = Apollo.BaseMutationOptions<UpdateVirtualEventMutation, UpdateVirtualEventMutationVariables>;
export const UpdateGroupEventDocument = gql`
    mutation updateGroupEvent($input: UpdateGroupEventInput!) {
  updateGroupEvent(input: $input) {
    groupEvent {
      id
    }
  }
}
    `;
export type UpdateGroupEventMutationFn = Apollo.MutationFunction<UpdateGroupEventMutation, UpdateGroupEventMutationVariables>;

/**
 * __useUpdateGroupEventMutation__
 *
 * To run a mutation, you first call `useUpdateGroupEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupEventMutation, { data, loading, error }] = useUpdateGroupEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGroupEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupEventMutation, UpdateGroupEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupEventMutation, UpdateGroupEventMutationVariables>(UpdateGroupEventDocument, options);
      }
export type UpdateGroupEventMutationHookResult = ReturnType<typeof useUpdateGroupEventMutation>;
export type UpdateGroupEventMutationResult = Apollo.MutationResult<UpdateGroupEventMutation>;
export type UpdateGroupEventMutationOptions = Apollo.BaseMutationOptions<UpdateGroupEventMutation, UpdateGroupEventMutationVariables>;
export const UpdateCourtDateAttendanceDocument = gql`
    mutation updateCourtDateAttendance($id: ID!, $attendedId: ID) {
  updateCourtDate(input: {id: $id, attendedId: $attendedId}) {
    courtDate {
      id
      attended {
        id
        outcomeId
      }
      outcome {
        outcomeUid
        status
      }
    }
  }
}
    `;
export type UpdateCourtDateAttendanceMutationFn = Apollo.MutationFunction<UpdateCourtDateAttendanceMutation, UpdateCourtDateAttendanceMutationVariables>;

/**
 * __useUpdateCourtDateAttendanceMutation__
 *
 * To run a mutation, you first call `useUpdateCourtDateAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourtDateAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourtDateAttendanceMutation, { data, loading, error }] = useUpdateCourtDateAttendanceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attendedId: // value for 'attendedId'
 *   },
 * });
 */
export function useUpdateCourtDateAttendanceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCourtDateAttendanceMutation, UpdateCourtDateAttendanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCourtDateAttendanceMutation, UpdateCourtDateAttendanceMutationVariables>(UpdateCourtDateAttendanceDocument, options);
      }
export type UpdateCourtDateAttendanceMutationHookResult = ReturnType<typeof useUpdateCourtDateAttendanceMutation>;
export type UpdateCourtDateAttendanceMutationResult = Apollo.MutationResult<UpdateCourtDateAttendanceMutation>;
export type UpdateCourtDateAttendanceMutationOptions = Apollo.BaseMutationOptions<UpdateCourtDateAttendanceMutation, UpdateCourtDateAttendanceMutationVariables>;
export const UpdateOfficeEventAttendanceDocument = gql`
    mutation updateOfficeEventAttendance($id: ID!, $attendedId: ID) {
  updateOfficeEvent(input: {id: $id, attendedId: $attendedId}) {
    officeEvent {
      id
      attended {
        id
        outcomeId
      }
      outcome {
        outcomeUid
        status
      }
    }
  }
}
    `;
export type UpdateOfficeEventAttendanceMutationFn = Apollo.MutationFunction<UpdateOfficeEventAttendanceMutation, UpdateOfficeEventAttendanceMutationVariables>;

/**
 * __useUpdateOfficeEventAttendanceMutation__
 *
 * To run a mutation, you first call `useUpdateOfficeEventAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfficeEventAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfficeEventAttendanceMutation, { data, loading, error }] = useUpdateOfficeEventAttendanceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attendedId: // value for 'attendedId'
 *   },
 * });
 */
export function useUpdateOfficeEventAttendanceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOfficeEventAttendanceMutation, UpdateOfficeEventAttendanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOfficeEventAttendanceMutation, UpdateOfficeEventAttendanceMutationVariables>(UpdateOfficeEventAttendanceDocument, options);
      }
export type UpdateOfficeEventAttendanceMutationHookResult = ReturnType<typeof useUpdateOfficeEventAttendanceMutation>;
export type UpdateOfficeEventAttendanceMutationResult = Apollo.MutationResult<UpdateOfficeEventAttendanceMutation>;
export type UpdateOfficeEventAttendanceMutationOptions = Apollo.BaseMutationOptions<UpdateOfficeEventAttendanceMutation, UpdateOfficeEventAttendanceMutationVariables>;
export const UpdateLastOpenedTimestampDocument = gql`
    mutation updateLastOpenedTimestamp($input: UpdateClientMessageStatusInput!) {
  updateClientMessageStatus(input: $input) {
    client {
      id
      lastOpenedTimestamp
    }
  }
}
    `;
export type UpdateLastOpenedTimestampMutationFn = Apollo.MutationFunction<UpdateLastOpenedTimestampMutation, UpdateLastOpenedTimestampMutationVariables>;

/**
 * __useUpdateLastOpenedTimestampMutation__
 *
 * To run a mutation, you first call `useUpdateLastOpenedTimestampMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLastOpenedTimestampMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLastOpenedTimestampMutation, { data, loading, error }] = useUpdateLastOpenedTimestampMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLastOpenedTimestampMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLastOpenedTimestampMutation, UpdateLastOpenedTimestampMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLastOpenedTimestampMutation, UpdateLastOpenedTimestampMutationVariables>(UpdateLastOpenedTimestampDocument, options);
      }
export type UpdateLastOpenedTimestampMutationHookResult = ReturnType<typeof useUpdateLastOpenedTimestampMutation>;
export type UpdateLastOpenedTimestampMutationResult = Apollo.MutationResult<UpdateLastOpenedTimestampMutation>;
export type UpdateLastOpenedTimestampMutationOptions = Apollo.BaseMutationOptions<UpdateLastOpenedTimestampMutation, UpdateLastOpenedTimestampMutationVariables>;
export const UpdateClientNotesDocument = gql`
    mutation updateClientNotes($input: UpdateClientInfoInput!) {
  updateClientInfo(input: $input) {
    client {
      id
      notes
    }
  }
}
    `;
export type UpdateClientNotesMutationFn = Apollo.MutationFunction<UpdateClientNotesMutation, UpdateClientNotesMutationVariables>;

/**
 * __useUpdateClientNotesMutation__
 *
 * To run a mutation, you first call `useUpdateClientNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientNotesMutation, { data, loading, error }] = useUpdateClientNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientNotesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientNotesMutation, UpdateClientNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientNotesMutation, UpdateClientNotesMutationVariables>(UpdateClientNotesDocument, options);
      }
export type UpdateClientNotesMutationHookResult = ReturnType<typeof useUpdateClientNotesMutation>;
export type UpdateClientNotesMutationResult = Apollo.MutationResult<UpdateClientNotesMutation>;
export type UpdateClientNotesMutationOptions = Apollo.BaseMutationOptions<UpdateClientNotesMutation, UpdateClientNotesMutationVariables>;
export const CheckAnnouncementSendStatusDocument = gql`
    query checkAnnouncementSendStatus($id: ID!) {
  getAnnouncement(id: $id) {
    id
    timestamp
    pendingMessages: messages(filterStatusTypes: [PENDING, QUEUED, READY]) {
      totalCount
    }
    sentMessages: messages(filterStatusTypes: [SENT, DELIVERED]) {
      totalCount
    }
    errorMessages: messages(filterStatusTypes: [UNDELIVERED, ERROR, SKIPPED]) {
      totalCount
    }
  }
}
    `;

/**
 * __useCheckAnnouncementSendStatusQuery__
 *
 * To run a query within a React component, call `useCheckAnnouncementSendStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAnnouncementSendStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAnnouncementSendStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckAnnouncementSendStatusQuery(baseOptions: Apollo.QueryHookOptions<CheckAnnouncementSendStatusQuery, CheckAnnouncementSendStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckAnnouncementSendStatusQuery, CheckAnnouncementSendStatusQueryVariables>(CheckAnnouncementSendStatusDocument, options);
      }
export function useCheckAnnouncementSendStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckAnnouncementSendStatusQuery, CheckAnnouncementSendStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckAnnouncementSendStatusQuery, CheckAnnouncementSendStatusQueryVariables>(CheckAnnouncementSendStatusDocument, options);
        }
export type CheckAnnouncementSendStatusQueryHookResult = ReturnType<typeof useCheckAnnouncementSendStatusQuery>;
export type CheckAnnouncementSendStatusLazyQueryHookResult = ReturnType<typeof useCheckAnnouncementSendStatusLazyQuery>;
export type CheckAnnouncementSendStatusQueryResult = Apollo.QueryResult<CheckAnnouncementSendStatusQuery, CheckAnnouncementSendStatusQueryVariables>;
export const GetAnnouncementDetailsDocument = gql`
    query getAnnouncementDetails($id: ID!) {
  getAnnouncement(id: $id) {
    id
    timestamp
    body
    sentMessages: messages(filterStatusTypes: [SENT, DELIVERED]) {
      totalCount
    }
    errorMessages: messages(filterStatusTypes: [UNDELIVERED, ERROR, SKIPPED]) {
      totalCount
      edges {
        node {
          id
          clientId
        }
      }
    }
  }
}
    `;

/**
 * __useGetAnnouncementDetailsQuery__
 *
 * To run a query within a React component, call `useGetAnnouncementDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncementDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncementDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAnnouncementDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetAnnouncementDetailsQuery, GetAnnouncementDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnnouncementDetailsQuery, GetAnnouncementDetailsQueryVariables>(GetAnnouncementDetailsDocument, options);
      }
export function useGetAnnouncementDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnnouncementDetailsQuery, GetAnnouncementDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnnouncementDetailsQuery, GetAnnouncementDetailsQueryVariables>(GetAnnouncementDetailsDocument, options);
        }
export type GetAnnouncementDetailsQueryHookResult = ReturnType<typeof useGetAnnouncementDetailsQuery>;
export type GetAnnouncementDetailsLazyQueryHookResult = ReturnType<typeof useGetAnnouncementDetailsLazyQuery>;
export type GetAnnouncementDetailsQueryResult = Apollo.QueryResult<GetAnnouncementDetailsQuery, GetAnnouncementDetailsQueryVariables>;
export const GetApiClientsListDocument = gql`
    query getApiClientsList($filter: GetClientsFilter!) {
  getClients(filter: $filter) {
    edges {
      node {
        id
      }
    }
  }
}
    `;

/**
 * __useGetApiClientsListQuery__
 *
 * To run a query within a React component, call `useGetApiClientsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiClientsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiClientsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetApiClientsListQuery(baseOptions: Apollo.QueryHookOptions<GetApiClientsListQuery, GetApiClientsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApiClientsListQuery, GetApiClientsListQueryVariables>(GetApiClientsListDocument, options);
      }
export function useGetApiClientsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApiClientsListQuery, GetApiClientsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApiClientsListQuery, GetApiClientsListQueryVariables>(GetApiClientsListDocument, options);
        }
export type GetApiClientsListQueryHookResult = ReturnType<typeof useGetApiClientsListQuery>;
export type GetApiClientsListLazyQueryHookResult = ReturnType<typeof useGetApiClientsListLazyQuery>;
export type GetApiClientsListQueryResult = Apollo.QueryResult<GetApiClientsListQuery, GetApiClientsListQueryVariables>;
export const GetAttendanceStatsEventsDocument = gql`
    query getAttendanceStatsEvents($lastActiveTimestamp: Float!, $startDate: Float!) {
  getUser {
    id
    type
    ... on StaffUser {
      events(
        fromLastActiveTimestamp: $lastActiveTimestamp
        eventFilter: UPCOMING
        eventFilterTimestamp: $startDate
      ) {
        edges {
          node {
            id
            startTimestamp
            endTimestamp
            type
            title
            clientFirstName
            clientLastName
            staffId
            closed
            outcome {
              outcomeUid
              status
            }
            attendanceInfo @client
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAttendanceStatsEventsQuery__
 *
 * To run a query within a React component, call `useGetAttendanceStatsEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendanceStatsEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendanceStatsEventsQuery({
 *   variables: {
 *      lastActiveTimestamp: // value for 'lastActiveTimestamp'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useGetAttendanceStatsEventsQuery(baseOptions: Apollo.QueryHookOptions<GetAttendanceStatsEventsQuery, GetAttendanceStatsEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttendanceStatsEventsQuery, GetAttendanceStatsEventsQueryVariables>(GetAttendanceStatsEventsDocument, options);
      }
export function useGetAttendanceStatsEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttendanceStatsEventsQuery, GetAttendanceStatsEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttendanceStatsEventsQuery, GetAttendanceStatsEventsQueryVariables>(GetAttendanceStatsEventsDocument, options);
        }
export type GetAttendanceStatsEventsQueryHookResult = ReturnType<typeof useGetAttendanceStatsEventsQuery>;
export type GetAttendanceStatsEventsLazyQueryHookResult = ReturnType<typeof useGetAttendanceStatsEventsLazyQuery>;
export type GetAttendanceStatsEventsQueryResult = Apollo.QueryResult<GetAttendanceStatsEventsQuery, GetAttendanceStatsEventsQueryVariables>;
export const GetCheckInReportDocument = gql`
    query getCheckInReport($id: ID!, $locale: String) {
  getCheckInReport(id: $id, locale: $locale) {
    id
    timestamp
    name
    client {
      firstName
      lastName
    }
    pages {
      id
      pageNumber
      responses {
        id
        questionText
        answerText
        order
        sendAlert
        needsExplanation
        explanationText
      }
    }
  }
}
    `;

/**
 * __useGetCheckInReportQuery__
 *
 * To run a query within a React component, call `useGetCheckInReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckInReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckInReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetCheckInReportQuery(baseOptions: Apollo.QueryHookOptions<GetCheckInReportQuery, GetCheckInReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCheckInReportQuery, GetCheckInReportQueryVariables>(GetCheckInReportDocument, options);
      }
export function useGetCheckInReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckInReportQuery, GetCheckInReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCheckInReportQuery, GetCheckInReportQueryVariables>(GetCheckInReportDocument, options);
        }
export type GetCheckInReportQueryHookResult = ReturnType<typeof useGetCheckInReportQuery>;
export type GetCheckInReportLazyQueryHookResult = ReturnType<typeof useGetCheckInReportLazyQuery>;
export type GetCheckInReportQueryResult = Apollo.QueryResult<GetCheckInReportQuery, GetCheckInReportQueryVariables>;
export const GetCheckInTemplatesDocument = gql`
    query getCheckInTemplates($organizationId: ID!, $isActiveFilter: IsActiveFilter) {
  getCheckInTemplates(
    organizationId: $organizationId
    isActiveFilter: $isActiveFilter
  ) {
    edges {
      node {
        id
        staffDisplayName
      }
    }
  }
}
    `;

/**
 * __useGetCheckInTemplatesQuery__
 *
 * To run a query within a React component, call `useGetCheckInTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckInTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckInTemplatesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      isActiveFilter: // value for 'isActiveFilter'
 *   },
 * });
 */
export function useGetCheckInTemplatesQuery(baseOptions: Apollo.QueryHookOptions<GetCheckInTemplatesQuery, GetCheckInTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCheckInTemplatesQuery, GetCheckInTemplatesQueryVariables>(GetCheckInTemplatesDocument, options);
      }
export function useGetCheckInTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckInTemplatesQuery, GetCheckInTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCheckInTemplatesQuery, GetCheckInTemplatesQueryVariables>(GetCheckInTemplatesDocument, options);
        }
export type GetCheckInTemplatesQueryHookResult = ReturnType<typeof useGetCheckInTemplatesQuery>;
export type GetCheckInTemplatesLazyQueryHookResult = ReturnType<typeof useGetCheckInTemplatesLazyQuery>;
export type GetCheckInTemplatesQueryResult = Apollo.QueryResult<GetCheckInTemplatesQuery, GetCheckInTemplatesQueryVariables>;
export const GetClientAssignedStaffDocument = gql`
    query getClientAssignedStaff($id: ID!) {
  getClient(id: $id) {
    id
    assignedStaff {
      id
      name
    }
    staffId
  }
}
    `;

/**
 * __useGetClientAssignedStaffQuery__
 *
 * To run a query within a React component, call `useGetClientAssignedStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientAssignedStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientAssignedStaffQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientAssignedStaffQuery(baseOptions: Apollo.QueryHookOptions<GetClientAssignedStaffQuery, GetClientAssignedStaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientAssignedStaffQuery, GetClientAssignedStaffQueryVariables>(GetClientAssignedStaffDocument, options);
      }
export function useGetClientAssignedStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientAssignedStaffQuery, GetClientAssignedStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientAssignedStaffQuery, GetClientAssignedStaffQueryVariables>(GetClientAssignedStaffDocument, options);
        }
export type GetClientAssignedStaffQueryHookResult = ReturnType<typeof useGetClientAssignedStaffQuery>;
export type GetClientAssignedStaffLazyQueryHookResult = ReturnType<typeof useGetClientAssignedStaffLazyQuery>;
export type GetClientAssignedStaffQueryResult = Apollo.QueryResult<GetClientAssignedStaffQuery, GetClientAssignedStaffQueryVariables>;
export const GetClientByCmsIdDocument = gql`
    query getClientByCmsId($cmsId: String!) {
  getClientByCmsId(cmsId: $cmsId) {
    cmsId
  }
}
    `;

/**
 * __useGetClientByCmsIdQuery__
 *
 * To run a query within a React component, call `useGetClientByCmsIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientByCmsIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientByCmsIdQuery({
 *   variables: {
 *      cmsId: // value for 'cmsId'
 *   },
 * });
 */
export function useGetClientByCmsIdQuery(baseOptions: Apollo.QueryHookOptions<GetClientByCmsIdQuery, GetClientByCmsIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientByCmsIdQuery, GetClientByCmsIdQueryVariables>(GetClientByCmsIdDocument, options);
      }
export function useGetClientByCmsIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientByCmsIdQuery, GetClientByCmsIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientByCmsIdQuery, GetClientByCmsIdQueryVariables>(GetClientByCmsIdDocument, options);
        }
export type GetClientByCmsIdQueryHookResult = ReturnType<typeof useGetClientByCmsIdQuery>;
export type GetClientByCmsIdLazyQueryHookResult = ReturnType<typeof useGetClientByCmsIdLazyQuery>;
export type GetClientByCmsIdQueryResult = Apollo.QueryResult<GetClientByCmsIdQuery, GetClientByCmsIdQueryVariables>;
export const GetClientDetailsDocument = gql`
    query getClientDetails($id: ID!) {
  getClient(id: $id) {
    id
    ...StatusBarInfo
    firstName
    lastName
    phone
    email
    gender
    language
    staff {
      id
      name
    }
    riskLevel
    cmsId
    noPhone
    noCar
    housingInsecure
    needsChildCare
    inCustody
    employer
    clientUpdatedPhone
    clientUpdatedAddress
    clientUpdatedEmployer
    docket
    upperDocket
    cases {
      edges {
        node {
          id
          number
          open
        }
      }
    }
    contacts {
      edges {
        node {
          id
          name
          phone
          notes
          optIn
        }
      }
    }
    cohort {
      id
      name {
        locale
        text
      }
    }
    terms {
      id
      endDate
      restitutions {
        edges {
          node {
            id
            amount
            type
          }
        }
      }
      conditions {
        edges {
          node {
            id
            description
          }
        }
      }
    }
    address {
      addressLine1
      addressLine2
      city
      state
      zipCode
    }
  }
}
    ${StatusBarInfoFragmentDoc}`;

/**
 * __useGetClientDetailsQuery__
 *
 * To run a query within a React component, call `useGetClientDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetClientDetailsQuery, GetClientDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientDetailsQuery, GetClientDetailsQueryVariables>(GetClientDetailsDocument, options);
      }
export function useGetClientDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientDetailsQuery, GetClientDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientDetailsQuery, GetClientDetailsQueryVariables>(GetClientDetailsDocument, options);
        }
export type GetClientDetailsQueryHookResult = ReturnType<typeof useGetClientDetailsQuery>;
export type GetClientDetailsLazyQueryHookResult = ReturnType<typeof useGetClientDetailsLazyQuery>;
export type GetClientDetailsQueryResult = Apollo.QueryResult<GetClientDetailsQuery, GetClientDetailsQueryVariables>;
export const GetClientMessagesDocument = gql`
    query getClientMessages($id: ID!, $after: String, $first: Int) {
  getClient(id: $id) {
    id
    messages(first: $first, after: $after) @connection(key: "messages") {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node {
          id
          timestamp
          status
          type
          ... on FromEndUserMessage {
            body
            media {
              url
              contentType
            }
          }
          ... on FromPlatformMessage {
            body
          }
          ... on FromPlatformEventMessage {
            body
          }
          ... on FromStaffMessage {
            senderName
            body
            announcement {
              id
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetClientMessagesQuery__
 *
 * To run a query within a React component, call `useGetClientMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetClientMessagesQuery(baseOptions: Apollo.QueryHookOptions<GetClientMessagesQuery, GetClientMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientMessagesQuery, GetClientMessagesQueryVariables>(GetClientMessagesDocument, options);
      }
export function useGetClientMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientMessagesQuery, GetClientMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientMessagesQuery, GetClientMessagesQueryVariables>(GetClientMessagesDocument, options);
        }
export type GetClientMessagesQueryHookResult = ReturnType<typeof useGetClientMessagesQuery>;
export type GetClientMessagesLazyQueryHookResult = ReturnType<typeof useGetClientMessagesLazyQuery>;
export type GetClientMessagesQueryResult = Apollo.QueryResult<GetClientMessagesQuery, GetClientMessagesQueryVariables>;
export const GetClientNotesDocument = gql`
    query getClientNotes($id: ID!) {
  getClient(id: $id) {
    id
    notes
  }
}
    `;

/**
 * __useGetClientNotesQuery__
 *
 * To run a query within a React component, call `useGetClientNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientNotesQuery(baseOptions: Apollo.QueryHookOptions<GetClientNotesQuery, GetClientNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientNotesQuery, GetClientNotesQueryVariables>(GetClientNotesDocument, options);
      }
export function useGetClientNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientNotesQuery, GetClientNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientNotesQuery, GetClientNotesQueryVariables>(GetClientNotesDocument, options);
        }
export type GetClientNotesQueryHookResult = ReturnType<typeof useGetClientNotesQuery>;
export type GetClientNotesLazyQueryHookResult = ReturnType<typeof useGetClientNotesLazyQuery>;
export type GetClientNotesQueryResult = Apollo.QueryResult<GetClientNotesQuery, GetClientNotesQueryVariables>;
export const GetClientsDashboardStatsDocument = gql`
    query getClientsDashboardStats($attendanceDonutEventsFilter: EventStatsFilter!, $attendanceTidbitsEventsFilter: EventStatsFilter!, $engagementTripleStackMessagesFilter: MessageStatsFilter!, $engagementTidbitsMessagesFilter: MessageStatsFilter!, $engagementTidbitsTotalMessagesFilter: MessageStatsFilter!, $engagementTidbitsClientsFilter: ClientStatsFilter!) {
  getStats {
    ...AttendanceDonutStats
    ...AttendanceTidbitsStats
    ...EngagementTripleStackStats
    ...EngagementTidbitsStats
  }
}
    ${AttendanceDonutStatsFragmentDoc}
${AttendanceTidbitsStatsFragmentDoc}
${EngagementTripleStackStatsFragmentDoc}
${EngagementTidbitsStatsFragmentDoc}`;

/**
 * __useGetClientsDashboardStatsQuery__
 *
 * To run a query within a React component, call `useGetClientsDashboardStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsDashboardStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsDashboardStatsQuery({
 *   variables: {
 *      attendanceDonutEventsFilter: // value for 'attendanceDonutEventsFilter'
 *      attendanceTidbitsEventsFilter: // value for 'attendanceTidbitsEventsFilter'
 *      engagementTripleStackMessagesFilter: // value for 'engagementTripleStackMessagesFilter'
 *      engagementTidbitsMessagesFilter: // value for 'engagementTidbitsMessagesFilter'
 *      engagementTidbitsTotalMessagesFilter: // value for 'engagementTidbitsTotalMessagesFilter'
 *      engagementTidbitsClientsFilter: // value for 'engagementTidbitsClientsFilter'
 *   },
 * });
 */
export function useGetClientsDashboardStatsQuery(baseOptions: Apollo.QueryHookOptions<GetClientsDashboardStatsQuery, GetClientsDashboardStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientsDashboardStatsQuery, GetClientsDashboardStatsQueryVariables>(GetClientsDashboardStatsDocument, options);
      }
export function useGetClientsDashboardStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientsDashboardStatsQuery, GetClientsDashboardStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientsDashboardStatsQuery, GetClientsDashboardStatsQueryVariables>(GetClientsDashboardStatsDocument, options);
        }
export type GetClientsDashboardStatsQueryHookResult = ReturnType<typeof useGetClientsDashboardStatsQuery>;
export type GetClientsDashboardStatsLazyQueryHookResult = ReturnType<typeof useGetClientsDashboardStatsLazyQuery>;
export type GetClientsDashboardStatsQueryResult = Apollo.QueryResult<GetClientsDashboardStatsQuery, GetClientsDashboardStatsQueryVariables>;
export const GetCohortsDocument = gql`
    query getCohorts($organizationId: String!) {
  getOrganization(organizationId: $organizationId) {
    cohortLabel
    cohorts {
      id
      name {
        locale
        text
      }
    }
  }
}
    `;

/**
 * __useGetCohortsQuery__
 *
 * To run a query within a React component, call `useGetCohortsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCohortsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCohortsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetCohortsQuery(baseOptions: Apollo.QueryHookOptions<GetCohortsQuery, GetCohortsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCohortsQuery, GetCohortsQueryVariables>(GetCohortsDocument, options);
      }
export function useGetCohortsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCohortsQuery, GetCohortsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCohortsQuery, GetCohortsQueryVariables>(GetCohortsDocument, options);
        }
export type GetCohortsQueryHookResult = ReturnType<typeof useGetCohortsQuery>;
export type GetCohortsLazyQueryHookResult = ReturnType<typeof useGetCohortsLazyQuery>;
export type GetCohortsQueryResult = Apollo.QueryResult<GetCohortsQuery, GetCohortsQueryVariables>;
export const GetCombinedScheduleEventsDocument = gql`
    query getCombinedScheduleEvents($lastActiveTimestamp: Float!, $startDate: Float!, $from: Float, $to: Float) {
  getUser {
    id
    type
    ... on StaffUser {
      events(
        fromLastActiveTimestamp: $lastActiveTimestamp
        eventFilter: UPCOMING
        eventFilterTimestamp: $startDate
        from: $from
        to: $to
      ) {
        edges {
          node {
            id
            type
            startTimestamp
            endTimestamp
            title
            clientFirstName
            clientLastName
            clientId
            staffId
            closed
            groupEventId
            staffAttending
            ... on CourtDateEvent {
              attendanceInfo @client
              location {
                id
                addressLine1
                city
                state
                zip
              }
              room {
                id
                description
              }
              attended {
                id
                outcomeId
                name
              }
              outcome {
                outcomeUid
                status
              }
            }
            ... on OfficeEvent {
              attendanceInfo @client
              location {
                id
                addressLine1
                city
                state
                zip
              }
              room {
                id
                description
              }
              attended {
                id
                outcomeId
                name
              }
              outcome {
                outcomeUid
                status
              }
            }
            ... on CheckInEvent {
              isCompleted
              submittedTimestamp
              checkInTemplate {
                id
                staffDisplayName
              }
              checkInReport {
                id
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCombinedScheduleEventsQuery__
 *
 * To run a query within a React component, call `useGetCombinedScheduleEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCombinedScheduleEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCombinedScheduleEventsQuery({
 *   variables: {
 *      lastActiveTimestamp: // value for 'lastActiveTimestamp'
 *      startDate: // value for 'startDate'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetCombinedScheduleEventsQuery(baseOptions: Apollo.QueryHookOptions<GetCombinedScheduleEventsQuery, GetCombinedScheduleEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCombinedScheduleEventsQuery, GetCombinedScheduleEventsQueryVariables>(GetCombinedScheduleEventsDocument, options);
      }
export function useGetCombinedScheduleEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCombinedScheduleEventsQuery, GetCombinedScheduleEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCombinedScheduleEventsQuery, GetCombinedScheduleEventsQueryVariables>(GetCombinedScheduleEventsDocument, options);
        }
export type GetCombinedScheduleEventsQueryHookResult = ReturnType<typeof useGetCombinedScheduleEventsQuery>;
export type GetCombinedScheduleEventsLazyQueryHookResult = ReturnType<typeof useGetCombinedScheduleEventsLazyQuery>;
export type GetCombinedScheduleEventsQueryResult = Apollo.QueryResult<GetCombinedScheduleEventsQuery, GetCombinedScheduleEventsQueryVariables>;
export const GetEventsQueryDocument = gql`
    query getEventsQuery($id: ID!, $timestamp: Float!, $timeFilter: EventFilter!) {
  getClient(id: $id) {
    id
    events(eventFilter: $timeFilter, eventFilterTimestamp: $timestamp) @connection(key: "events", filter: ["eventFilter"]) {
      edges {
        node {
          id
          clientId
          staffId
          timestamp
          type
          title
          closed
          groupEventId
          startTimestamp
          outcome {
            id
            outcomeUid
            status
          }
          ... on CheckInEvent {
            checkInTemplate {
              id
              staffDisplayName
            }
            checkInReportId
            isCompleted
            submittedTimestamp
            endTimestamp
          }
          ... on CourtDateEvent {
            attendanceInfo @client
            location {
              id
              addressLine1
              addressLine2
              city
              state
              zip
            }
            attended {
              id
              outcomeId
              name
            }
            room {
              id
              roomId
              description
            }
          }
          ... on OfficeEvent {
            attendanceInfo @client
            location {
              id
              addressLine1
              addressLine2
              city
              state
              zip
            }
            room {
              id
              roomId
              description
            }
            attended {
              id
              outcomeId
              name
            }
          }
          ... on VirtualEvent {
            endTimestamp
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetEventsQueryQuery__
 *
 * To run a query within a React component, call `useGetEventsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      timestamp: // value for 'timestamp'
 *      timeFilter: // value for 'timeFilter'
 *   },
 * });
 */
export function useGetEventsQueryQuery(baseOptions: Apollo.QueryHookOptions<GetEventsQueryQuery, GetEventsQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsQueryQuery, GetEventsQueryQueryVariables>(GetEventsQueryDocument, options);
      }
export function useGetEventsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsQueryQuery, GetEventsQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsQueryQuery, GetEventsQueryQueryVariables>(GetEventsQueryDocument, options);
        }
export type GetEventsQueryQueryHookResult = ReturnType<typeof useGetEventsQueryQuery>;
export type GetEventsQueryLazyQueryHookResult = ReturnType<typeof useGetEventsQueryLazyQuery>;
export type GetEventsQueryQueryResult = Apollo.QueryResult<GetEventsQueryQuery, GetEventsQueryQueryVariables>;
export const GetMasqueradeOrganizationsListDocument = gql`
    query getMasqueradeOrganizationsList {
  getAllOrganizations {
    edges {
      node {
        id
        organizationUid
        name
        type
      }
    }
  }
}
    `;

/**
 * __useGetMasqueradeOrganizationsListQuery__
 *
 * To run a query within a React component, call `useGetMasqueradeOrganizationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasqueradeOrganizationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasqueradeOrganizationsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMasqueradeOrganizationsListQuery(baseOptions?: Apollo.QueryHookOptions<GetMasqueradeOrganizationsListQuery, GetMasqueradeOrganizationsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMasqueradeOrganizationsListQuery, GetMasqueradeOrganizationsListQueryVariables>(GetMasqueradeOrganizationsListDocument, options);
      }
export function useGetMasqueradeOrganizationsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMasqueradeOrganizationsListQuery, GetMasqueradeOrganizationsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMasqueradeOrganizationsListQuery, GetMasqueradeOrganizationsListQueryVariables>(GetMasqueradeOrganizationsListDocument, options);
        }
export type GetMasqueradeOrganizationsListQueryHookResult = ReturnType<typeof useGetMasqueradeOrganizationsListQuery>;
export type GetMasqueradeOrganizationsListLazyQueryHookResult = ReturnType<typeof useGetMasqueradeOrganizationsListLazyQuery>;
export type GetMasqueradeOrganizationsListQueryResult = Apollo.QueryResult<GetMasqueradeOrganizationsListQuery, GetMasqueradeOrganizationsListQueryVariables>;
export const GetMasqueradeStaffListDocument = gql`
    query getMasqueradeStaffList($id: String) {
  getOrganization(organizationId: $id) {
    id
    staff {
      edges {
        node {
          id
          name
          email
        }
      }
    }
  }
}
    `;

/**
 * __useGetMasqueradeStaffListQuery__
 *
 * To run a query within a React component, call `useGetMasqueradeStaffListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasqueradeStaffListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasqueradeStaffListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMasqueradeStaffListQuery(baseOptions?: Apollo.QueryHookOptions<GetMasqueradeStaffListQuery, GetMasqueradeStaffListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMasqueradeStaffListQuery, GetMasqueradeStaffListQueryVariables>(GetMasqueradeStaffListDocument, options);
      }
export function useGetMasqueradeStaffListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMasqueradeStaffListQuery, GetMasqueradeStaffListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMasqueradeStaffListQuery, GetMasqueradeStaffListQueryVariables>(GetMasqueradeStaffListDocument, options);
        }
export type GetMasqueradeStaffListQueryHookResult = ReturnType<typeof useGetMasqueradeStaffListQuery>;
export type GetMasqueradeStaffListLazyQueryHookResult = ReturnType<typeof useGetMasqueradeStaffListLazyQuery>;
export type GetMasqueradeStaffListQueryResult = Apollo.QueryResult<GetMasqueradeStaffListQuery, GetMasqueradeStaffListQueryVariables>;
export const GetMessagesUpdatesDocument = gql`
    subscription getMessagesUpdates($ids: [ID!]!) {
  messagesForStaffUsers(clientIds: $ids) {
    id
    clientId
    timestamp
    status
    type
    batchNotify
    ... on FromEndUserMessage {
      body
      media {
        url
        contentType
      }
    }
    ... on FromPlatformMessage {
      body
    }
    ... on FromPlatformEventMessage {
      body
    }
    ... on FromStaffMessage {
      senderName
      body
      announcement {
        id
      }
    }
  }
}
    `;

/**
 * __useGetMessagesUpdatesSubscription__
 *
 * To run a query within a React component, call `useGetMessagesUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagesUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagesUpdatesSubscription({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetMessagesUpdatesSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetMessagesUpdatesSubscription, GetMessagesUpdatesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetMessagesUpdatesSubscription, GetMessagesUpdatesSubscriptionVariables>(GetMessagesUpdatesDocument, options);
      }
export type GetMessagesUpdatesSubscriptionHookResult = ReturnType<typeof useGetMessagesUpdatesSubscription>;
export type GetMessagesUpdatesSubscriptionResult = Apollo.SubscriptionResult<GetMessagesUpdatesSubscription>;
export const GetOrganizationLocationsDocument = gql`
    query getOrganizationLocations {
  getUser {
    id
    ... on StaffUser {
      name
      organization {
        id
        locations {
          id
          addressLine1
          addressLine2
          city
          state
          zip
          isExternal
          providesChildCare
          rooms {
            edges {
              node {
                id
                description
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationLocationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationLocationsQuery, GetOrganizationLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationLocationsQuery, GetOrganizationLocationsQueryVariables>(GetOrganizationLocationsDocument, options);
      }
export function useGetOrganizationLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationLocationsQuery, GetOrganizationLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationLocationsQuery, GetOrganizationLocationsQueryVariables>(GetOrganizationLocationsDocument, options);
        }
export type GetOrganizationLocationsQueryHookResult = ReturnType<typeof useGetOrganizationLocationsQuery>;
export type GetOrganizationLocationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationLocationsLazyQuery>;
export type GetOrganizationLocationsQueryResult = Apollo.QueryResult<GetOrganizationLocationsQuery, GetOrganizationLocationsQueryVariables>;
export const GetOrganizationOutcomesDocument = gql`
    query getOrganizationOutcomes {
  getUser {
    id
    type
    ... on StaffUser {
      organization {
        id
        outcomes {
          id
          outcomeUid
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationOutcomesQuery__
 *
 * To run a query within a React component, call `useGetOrganizationOutcomesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationOutcomesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationOutcomesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationOutcomesQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationOutcomesQuery, GetOrganizationOutcomesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationOutcomesQuery, GetOrganizationOutcomesQueryVariables>(GetOrganizationOutcomesDocument, options);
      }
export function useGetOrganizationOutcomesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationOutcomesQuery, GetOrganizationOutcomesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationOutcomesQuery, GetOrganizationOutcomesQueryVariables>(GetOrganizationOutcomesDocument, options);
        }
export type GetOrganizationOutcomesQueryHookResult = ReturnType<typeof useGetOrganizationOutcomesQuery>;
export type GetOrganizationOutcomesLazyQueryHookResult = ReturnType<typeof useGetOrganizationOutcomesLazyQuery>;
export type GetOrganizationOutcomesQueryResult = Apollo.QueryResult<GetOrganizationOutcomesQuery, GetOrganizationOutcomesQueryVariables>;
export const GetOrganizationStaffDocument = gql`
    query getOrganizationStaff {
  getUser {
    id
    ... on StaffUser {
      organization {
        id
        maxStaffPerClient
        staff {
          edges {
            node {
              id
              name
              lawyer
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationStaffQuery__
 *
 * To run a query within a React component, call `useGetOrganizationStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationStaffQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationStaffQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationStaffQuery, GetOrganizationStaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationStaffQuery, GetOrganizationStaffQueryVariables>(GetOrganizationStaffDocument, options);
      }
export function useGetOrganizationStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationStaffQuery, GetOrganizationStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationStaffQuery, GetOrganizationStaffQueryVariables>(GetOrganizationStaffDocument, options);
        }
export type GetOrganizationStaffQueryHookResult = ReturnType<typeof useGetOrganizationStaffQuery>;
export type GetOrganizationStaffLazyQueryHookResult = ReturnType<typeof useGetOrganizationStaffLazyQuery>;
export type GetOrganizationStaffQueryResult = Apollo.QueryResult<GetOrganizationStaffQuery, GetOrganizationStaffQueryVariables>;
export const GetQuietHoursDocument = gql`
    query getQuietHours {
  getUser {
    id
    type
    ... on StaffUser {
      quietHours {
        autoreply
        autoreply_enabled
        enabled
        end
        id
        start
        weekends_off
      }
    }
  }
}
    `;

/**
 * __useGetQuietHoursQuery__
 *
 * To run a query within a React component, call `useGetQuietHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuietHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuietHoursQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuietHoursQuery(baseOptions?: Apollo.QueryHookOptions<GetQuietHoursQuery, GetQuietHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuietHoursQuery, GetQuietHoursQueryVariables>(GetQuietHoursDocument, options);
      }
export function useGetQuietHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuietHoursQuery, GetQuietHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuietHoursQuery, GetQuietHoursQueryVariables>(GetQuietHoursDocument, options);
        }
export type GetQuietHoursQueryHookResult = ReturnType<typeof useGetQuietHoursQuery>;
export type GetQuietHoursLazyQueryHookResult = ReturnType<typeof useGetQuietHoursLazyQuery>;
export type GetQuietHoursQueryResult = Apollo.QueryResult<GetQuietHoursQuery, GetQuietHoursQueryVariables>;
export const GetStaffMemberListDocument = gql`
    query getStaffMemberList {
  getUser {
    id
    type
    ... on StaffUser {
      visibleStaffIds
      organization {
        id
        staff {
          edges {
            node {
              id
              name
              lawyer
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetStaffMemberListQuery__
 *
 * To run a query within a React component, call `useGetStaffMemberListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaffMemberListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaffMemberListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStaffMemberListQuery(baseOptions?: Apollo.QueryHookOptions<GetStaffMemberListQuery, GetStaffMemberListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStaffMemberListQuery, GetStaffMemberListQueryVariables>(GetStaffMemberListDocument, options);
      }
export function useGetStaffMemberListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStaffMemberListQuery, GetStaffMemberListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStaffMemberListQuery, GetStaffMemberListQueryVariables>(GetStaffMemberListDocument, options);
        }
export type GetStaffMemberListQueryHookResult = ReturnType<typeof useGetStaffMemberListQuery>;
export type GetStaffMemberListLazyQueryHookResult = ReturnType<typeof useGetStaffMemberListLazyQuery>;
export type GetStaffMemberListQueryResult = Apollo.QueryResult<GetStaffMemberListQuery, GetStaffMemberListQueryVariables>;
export const GetStaffVirtualEventsDocument = gql`
    query getStaffVirtualEvents($filterTimestamp: Float!, $from: Float, $to: Float) {
  getUser {
    id
    type
    ... on StaffUser {
      ...StaffVirtualEvents
    }
  }
}
    ${StaffVirtualEventsFragmentDoc}`;

/**
 * __useGetStaffVirtualEventsQuery__
 *
 * To run a query within a React component, call `useGetStaffVirtualEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaffVirtualEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaffVirtualEventsQuery({
 *   variables: {
 *      filterTimestamp: // value for 'filterTimestamp'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetStaffVirtualEventsQuery(baseOptions: Apollo.QueryHookOptions<GetStaffVirtualEventsQuery, GetStaffVirtualEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStaffVirtualEventsQuery, GetStaffVirtualEventsQueryVariables>(GetStaffVirtualEventsDocument, options);
      }
export function useGetStaffVirtualEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStaffVirtualEventsQuery, GetStaffVirtualEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStaffVirtualEventsQuery, GetStaffVirtualEventsQueryVariables>(GetStaffVirtualEventsDocument, options);
        }
export type GetStaffVirtualEventsQueryHookResult = ReturnType<typeof useGetStaffVirtualEventsQuery>;
export type GetStaffVirtualEventsLazyQueryHookResult = ReturnType<typeof useGetStaffVirtualEventsLazyQuery>;
export type GetStaffVirtualEventsQueryResult = Apollo.QueryResult<GetStaffVirtualEventsQuery, GetStaffVirtualEventsQueryVariables>;
export const GetUserDocument = gql`
    query getUser($filterIncludeEventStaff: Boolean!) {
  getUser {
    id
    type
    ... on StaffUser {
      name
      supervisor
      lawyer
      visibleStaffIds
      organization {
        id
        type
        maxStaffPerClient
        locations {
          id
          addressLine1
          addressLine2
          city
          state
          zip
          providesChildCare
          isExternal
          rooms {
            edges {
              node {
                id
                description
              }
            }
          }
        }
        outcomes {
          id
          outcomeUid
          name
        }
      }
      clients {
        edges {
          node {
            id
            firstName
            lastName
            closed
            cmsId
            shouldReceiveMessages
            lastSend
            carrier {
              type
              error
            }
            assignedStaff @include(if: $filterIncludeEventStaff) {
              id
            }
            ...FilterFields
          }
        }
      }
    }
  }
}
    ${FilterFieldsFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      filterIncludeEventStaff: // value for 'filterIncludeEventStaff'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserIdentificationDocument = gql`
    query getUserIdentification {
  getUser {
    id
    ... on StaffUser {
      name
      email
      authUid
      organization {
        id
        organizationId
        type
      }
      supervisor
      lawyer
      visibleStaffIds
      localRole @client
    }
  }
}
    `;

/**
 * __useGetUserIdentificationQuery__
 *
 * To run a query within a React component, call `useGetUserIdentificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserIdentificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserIdentificationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserIdentificationQuery(baseOptions?: Apollo.QueryHookOptions<GetUserIdentificationQuery, GetUserIdentificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserIdentificationQuery, GetUserIdentificationQueryVariables>(GetUserIdentificationDocument, options);
      }
export function useGetUserIdentificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserIdentificationQuery, GetUserIdentificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserIdentificationQuery, GetUserIdentificationQueryVariables>(GetUserIdentificationDocument, options);
        }
export type GetUserIdentificationQueryHookResult = ReturnType<typeof useGetUserIdentificationQuery>;
export type GetUserIdentificationLazyQueryHookResult = ReturnType<typeof useGetUserIdentificationLazyQuery>;
export type GetUserIdentificationQueryResult = Apollo.QueryResult<GetUserIdentificationQuery, GetUserIdentificationQueryVariables>;
export const GetVisibleClientMessagesDocument = gql`
    query getVisibleClientMessages($filter: MessagesFilter!, $after: String, $first: Int) {
  getStaffMessages(filter: $filter, after: $after, first: $first) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edges {
      node {
        id
        timestamp
        status
        type
        ... on FromEndUserMessage {
          errorMessage
          body
          media {
            url
            contentType
          }
        }
        ... on FromPlatformMessage {
          errorMessage
          body
        }
        ... on FromPlatformEventMessage {
          errorMessage
          body
        }
        ... on FromStaffMessage {
          errorMessage
          senderName
          body
          announcement {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetVisibleClientMessagesQuery__
 *
 * To run a query within a React component, call `useGetVisibleClientMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisibleClientMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisibleClientMessagesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetVisibleClientMessagesQuery(baseOptions: Apollo.QueryHookOptions<GetVisibleClientMessagesQuery, GetVisibleClientMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVisibleClientMessagesQuery, GetVisibleClientMessagesQueryVariables>(GetVisibleClientMessagesDocument, options);
      }
export function useGetVisibleClientMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVisibleClientMessagesQuery, GetVisibleClientMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVisibleClientMessagesQuery, GetVisibleClientMessagesQueryVariables>(GetVisibleClientMessagesDocument, options);
        }
export type GetVisibleClientMessagesQueryHookResult = ReturnType<typeof useGetVisibleClientMessagesQuery>;
export type GetVisibleClientMessagesLazyQueryHookResult = ReturnType<typeof useGetVisibleClientMessagesLazyQuery>;
export type GetVisibleClientMessagesQueryResult = Apollo.QueryResult<GetVisibleClientMessagesQuery, GetVisibleClientMessagesQueryVariables>;
export const RefetchQuietHoursDocument = gql`
    query refetchQuietHours {
  getUser {
    id
    type
    ... on StaffUser {
      quietHours {
        id
      }
    }
  }
}
    `;

/**
 * __useRefetchQuietHoursQuery__
 *
 * To run a query within a React component, call `useRefetchQuietHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefetchQuietHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefetchQuietHoursQuery({
 *   variables: {
 *   },
 * });
 */
export function useRefetchQuietHoursQuery(baseOptions?: Apollo.QueryHookOptions<RefetchQuietHoursQuery, RefetchQuietHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RefetchQuietHoursQuery, RefetchQuietHoursQueryVariables>(RefetchQuietHoursDocument, options);
      }
export function useRefetchQuietHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefetchQuietHoursQuery, RefetchQuietHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RefetchQuietHoursQuery, RefetchQuietHoursQueryVariables>(RefetchQuietHoursDocument, options);
        }
export type RefetchQuietHoursQueryHookResult = ReturnType<typeof useRefetchQuietHoursQuery>;
export type RefetchQuietHoursLazyQueryHookResult = ReturnType<typeof useRefetchQuietHoursLazyQuery>;
export type RefetchQuietHoursQueryResult = Apollo.QueryResult<RefetchQuietHoursQuery, RefetchQuietHoursQueryVariables>;
export const RefetchClientsListDocument = gql`
    query refetchClientsList {
  getUser {
    id
    ... on StaffUser {
      clients {
        edges {
          node {
            id
            firstName
            lastName
            staffId
            messages(last: 1) @connection(key: "messages") {
              edges {
                node {
                  id
                  status
                  ... on FromStaffMessage {
                    errorMessage
                    body
                  }
                  ... on FromPlatformMessage {
                    errorMessage
                    body
                  }
                  ... on FromPlatformEventMessage {
                    errorMessage
                    body
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useRefetchClientsListQuery__
 *
 * To run a query within a React component, call `useRefetchClientsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefetchClientsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefetchClientsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useRefetchClientsListQuery(baseOptions?: Apollo.QueryHookOptions<RefetchClientsListQuery, RefetchClientsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RefetchClientsListQuery, RefetchClientsListQueryVariables>(RefetchClientsListDocument, options);
      }
export function useRefetchClientsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefetchClientsListQuery, RefetchClientsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RefetchClientsListQuery, RefetchClientsListQueryVariables>(RefetchClientsListDocument, options);
        }
export type RefetchClientsListQueryHookResult = ReturnType<typeof useRefetchClientsListQuery>;
export type RefetchClientsListLazyQueryHookResult = ReturnType<typeof useRefetchClientsListLazyQuery>;
export type RefetchClientsListQueryResult = Apollo.QueryResult<RefetchClientsListQuery, RefetchClientsListQueryVariables>;